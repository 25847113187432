import React from 'react';

import { max } from 'lodash';

import { Grid, MenuItem, TextField, colors } from '@mui/material';

import ComboBar from '@/components/BI/ComboBar';
import HeaderTotalGraphic from '@/components/organisms/headers/HeaderTotalGraphic';
import LoadingComponent from '@/components/templates/LoadingComponent';

import { GraphicLegend } from './components/GraphicLegend';
import { GraphicPerMonth } from './components/GraphicPerMonth';
import { TableConsultantQuantity } from './components/TableConsultantQuantity';
import { useVisits } from './hooks';
import { Container, Div, FilterHeader, FilterTitle, PaperTitle, Section, StatsWrapper, Text, TotalItemCard } from './styles';

const optionsAllMonths = {
    chartArea: { width: '95%', height: '80%' },
    colors: ['#B8D873', '#55A559', '#3A6647', '#294732'],
    explorer: { axis: 'horizontal', keepInBounds: false },
    orientation: 'horizontal',
    legend: { position: 'none' },
    fontSize: 12,
    tooltip: {
        // isHtml: true,
        ignoreBounds: false,
        textStyle: {
            bold: true
        }
    },

    annotations: {
        textStyle: {
            fontSize: 12,
            bold: true,
            color: '#FFF',
            auraColor: 'none'
        },
        colors: ['#7c8187'],
        boxStyle: {
            // Color of the box outline.
            stroke: '#7c8187',
            // Thickness of the box outline.
            strokeWidth: 1,
            // x-radius of the corner curvature.
            rx: 4,
            // y-radius of the corner curvature.
            ry: 4,
            fill: '#7c8187'
        }
    },

    vAxis: {
        titleTextStyle: {
            italic: false,
            color: '#9BA1A9'
        },
        baseline: 0,
        baselineColor: '#ebebeb'
    },
    hAxis: {
        textStyle: {
            fontSize: 14, // Example of setting font size
            bold: true
        },
        baseline: 0,
        baselineColor: '#ebebeb',
        gridlines: {
            color: '#ebebeb'
        }
    },
    vAxes: {
        0: {}, // Left y-axis
        1: {
            viewWindow: {
                min: 0,
                max: 100
            },
            gridlines: {
                color: 'transparent' // Removes the gridlines from the secondary axis
            }
        } // Right y-axis
    },
    series: {
        0: { targetAxisIndex: 0 }, // Bind series 0 (Sales) to the left y-axis
        1: { targetAxisIndex: 0 }, // Bind series 1 (Expenses) to the right y-axis
        2: { targetAxisIndex: 0 }, // Bind series 2 (Profit) to the left y-axis
        3: { targetAxisIndex: 1, type: 'line', tooltip: true, pointSize: 6 } // Bind series 3 (Revenue) to the right y-axis
    },
    seriesType: 'bars',
    explorer: {
        actions: [] // Disable zoom and pan
    }
};

const optionsByMonth = {
    ...optionsAllMonths,
    colors: ['#62C054'],
    hAxis: {
        ...optionsAllMonths.hAxis,
        titleTextStyle: {
            fontSize: 14,
            italic: false,
            color: '#9BA1A9'
        },
        title: 'Dias'
    },

    annotations: {
        textStyle: {
            fontSize: 12,
            bold: true,
            color: '#FFF',
            auraColor: 'none'
        },
        colors: ['#7c8187'],
        boxStyle: {
            // Color of the box outline.
            stroke: '#7c8187',
            // Thickness of the box outline.
            strokeWidth: 1,
            // x-radius of the corner curvature.
            rx: 4,
            // y-radius of the corner curvature.
            ry: 4,
            fill: '#7c8187'
        }
    }
};

const monthsOptions = [
    {
        value: 0,
        label: 'Todos meses'
    },
    {
        value: 1,
        label: 'Janeiro'
    },
    {
        value: 2,
        label: 'Fevereiro'
    },
    {
        value: 3,
        label: 'Março'
    },
    {
        value: 4,
        label: 'Abril'
    },
    {
        value: 5,
        label: 'Maio'
    },
    {
        value: 6,
        label: 'Junho'
    },
    {
        value: 7,
        label: 'Julho'
    },
    {
        value: 8,
        label: 'Agosto'
    },
    {
        value: 9,
        label: 'Setembro'
    },
    {
        value: 10,
        label: 'Outubro'
    },
    {
        value: 11,
        label: 'Novembro'
    },
    {
        value: 12,
        label: 'Dezembro'
    }
];

const Visits = ({ idEnterprise }) => {
    const { visits, filterPer, filterPerMonth, handleSelectOption, graphBarData, ticksByMonth, isLoading } = useVisits(idEnterprise);
    console.log('filterPerMonth', filterPerMonth);
    return (
        <Container>
            <FilterHeader>
                <FilterTitle>Visualizar por: </FilterTitle>
                <TextField
                    size="small"
                    style={{ minWidth: '100px', backgroundColor: 'white', borderRadius: '8px' }}
                    select
                    value={filterPer}
                    onChange={(evt) => handleSelectOption(evt.target.value, 'year')}
                >
                    {visits.years
                        ? visits.years.map((year) => (
                              <MenuItem key={year} value={year}>
                                  {year}
                              </MenuItem>
                          ))
                        : null}
                </TextField>
                <TextField
                    size="small"
                    style={{ minWidth: '168px', backgroundColor: 'white', borderRadius: '8px' }}
                    select
                    value={filterPerMonth ?? 'all-months'}
                    onChange={(evt) => handleSelectOption(evt.target.value, 'month')}
                >
                    {monthsOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>
            </FilterHeader>
            <div id="pdf-content-id">
                <Section>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} gap={'20px'}>
                                <Grid item xs={12}>
                                    <StatsWrapper>
                                        <HeaderTotalGraphic
                                            marginBottom="0"
                                            title={`Mês ${filterPerMonth > 0 ? 'selecionado' : 'atual'}:`}
                                            description={(visits?.items?.totalCurrentMonth ?? 0) + ' Visitas'}
                                        />
                                        <HeaderTotalGraphic
                                            marginBottom="0"
                                            title="Próximo mês:"
                                            description={(visits?.items?.totalNextMonth ?? 0) + ' Visitas'}
                                        />
                                        <HeaderTotalGraphic
                                            marginBottom="0"
                                            title="Total / Ano:"
                                            description={(visits?.items?.totalVisitsYear ?? 0) + ' Visitas'}
                                        />
                                    </StatsWrapper>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container justifyContent={'space-between'} alignItems={'center'} gap={'20px'}>
                                        <Grid item>
                                            <PaperTitle style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                Fazendas agendadas / ano
                                            </PaperTitle>
                                        </Grid>

                                        <Grid item>
                                            <PaperTitle style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                {filterPerMonth > 0 && (
                                                    <PaperTitle>
                                                        {monthsOptions.find((item) => item.value === filterPerMonth).label ?? ''} |{' '}
                                                    </PaperTitle>
                                                )}
                                                {filterPer}
                                            </PaperTitle>
                                        </Grid>

                                        <Grid item xs={12} height={'28.75rem'}>
                                            {isLoading ? (
                                                <LoadingComponent hideLogo />
                                            ) : (
                                                <ComboBar
                                                    height="100%"
                                                    width="100%"
                                                    allData={graphBarData}
                                                    options={
                                                        Number(filterPerMonth) === 0
                                                            ? optionsAllMonths
                                                            : {
                                                                  ...optionsByMonth,
                                                                  hAxis: {
                                                                      ...optionsByMonth.hAxis,
                                                                      ticks: ticksByMonth
                                                                  }
                                                              }
                                                    }
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            {filterPerMonth === 0 ? <GraphicLegend /> : <GraphicPerMonth />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Section>
                {graphBarData.length > 0 && (
                    <Section style={{ marginTop: '2rem' }}>
                        <TableConsultantQuantity filterYear={filterPer} filterMonth={filterPerMonth} enterpriseId={idEnterprise} />
                    </Section>
                )}
            </div>
        </Container>
    );
};

export default Visits;
