import PropTypes from 'prop-types';

export const OptionType = {
    id: PropTypes.number.isRequired,
    option: PropTypes.string.isRequired,
    feedback: PropTypes.string
};

export const AnswerType = {
    answer: PropTypes.string,
    feedback: PropTypes.string,
    id_question: PropTypes.number,
    id: PropTypes.number
};

export const QuizQuestionType = {
    answer: PropTypes.arrayOf(PropTypes.shape(AnswerType)),
    answerStudent: PropTypes.any,
    id: PropTypes.number,
    question: PropTypes.string
};

export const QuizType = {
    created_at: PropTypes.string,
    id: PropTypes.number,
    id_class: PropTypes.number,
    number: PropTypes.number,
    question: PropTypes.arrayOf(PropTypes.shape(QuizQuestionType)),
    time: PropTypes.number,
    title: PropTypes.string,
    updated_at: PropTypes.string
};
