import useApi from '../api';

const useSupportFormService = () => {
    const { api } = useApi();
    return {
        createSupportForm: (data) =>
            api
                .post('support', data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((resp) => resp.data)
    };
};

export default useSupportFormService;
