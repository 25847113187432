import useApi from '../api';

const useEventService = () => {
    const { api } = useApi();
    return {
        getAllEvents: (params) => api.get('event', { params }).then((resp) => resp.data),
        getEventPerId: (id) => api.get(`event/${id}`).then((resp) => resp.data),
        likeEvent: (id) => api.post(`event_user/like/${id}`).then((resp) => resp.data),
        viewEvent: (id) => api.post(`event_user/view/${id}`).then((resp) => resp.data),
        eventUserRegister: (id) => api.post(`event_user/registered/${id}`).then((resp) => resp.data)
    };
};

export default useEventService;
