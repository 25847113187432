import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { daysOfWeek, months } from 'constants/date';

const DateHorizontal = ({ dateString }) => {
    const date = new Date(dateString);

    const getDay = () => {
        const today = new Date();
        const eventDay = new Date(dateString);

        if (today.setHours(0, 0, 0, 0) === eventDay.setHours(0, 0, 0, 0)) {
            return 'Hoje';
        }

        return (
            <p>
                Inicio em: {months[eventDay.getMonth()]} <br /> {daysOfWeek[eventDay.getDay()]}
            </p>
        );
    };

    return (
        <Container>
            <h3>{date.getDate()}</h3>
            <p>{getDay()}</p>
        </Container>
    );
};

DateHorizontal.propTypes = {
    dateString: PropTypes.string
};

export default DateHorizontal;
