import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import moment from 'moment';
import PropTypes from 'prop-types';
import { NotificationType } from 'types/data/notifications';

import { Close } from '@mui/icons-material';

import { Container, CloseButton, Content } from './styles';

const NotificationCard = ({ notification, remove }) => {
    const { id, date, description, backgroundColor, borderColor, redirect, button } = notification;
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
    const user = useSelector((state) => state.auth);
    const navigate = useNavigate();

    moment.locale('pt-br');
    const dateText = moment(date).format('DD/MM/YYYY');

    const handleClick = async () => {
        switch (currentUserRole) {
            case 1:
                navigate(`/admin/${redirect}`);
                break;
            case 2:
                navigate(`/consultor/${redirect}`);
                break;
            case 3:
                navigate(`/empresa/${redirect}`);
                break;
            case 4:
                navigate(`/coordenador/${redirect}`);
                break;
            case 5:
                navigate(`/supervisor/${redirect}`);
                break;

            default:
                navigate(`/${redirect}`);
                break;
        }
    };

    return (
        <Container>
            <CloseButton aria-label="close" onClick={() => remove(id)}>
                <Close fontSize="small" />
            </CloseButton>

            <Content backgroundColor={backgroundColor} borderColor={borderColor} onClick={() => handleClick()}>
                <header>
                    <span>{dateText}</span>
                </header>

                <main>{description}</main>
                <footer>
                    <button>{button}</button>
                </footer>
            </Content>
        </Container>
    );
};

NotificationCard.propTypes = {
    notification: PropTypes.shape(NotificationType).isRequired,
    remove: PropTypes.func.isRequired
};

export default NotificationCard;
