import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import useCourseService from '@services/course';

import PdfViewer from '../../../atoms/structure/PdfViewer';
import { CloseButton, Container, DownloadButton, Modal } from './styles';

const CertificateModal = ({ open, course, handleClose, courseTitle }) => {
    const { id } = useParams();
    const user = useSelector((state) => state.auth);
    const { generateCourseCertificate } = useCourseService();
    const [courseCertificate, setCourseCertificate] = useState(null);
    const [loading, setLoading] = useState(true);

    const base64toBlob = (data) => {
        // Cut the prefix data:application/pdf;base64 from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const getCertificate = () => {
        generateCourseCertificate(user.user.id, id).then((resp) => {
            let urlBlob = base64toBlob(resp.pdf);
            setCourseCertificate({
                url: URL.createObjectURL(urlBlob),
                pdf: resp.pdf
            });
            setLoading(false);
        });
    };
    useEffect(() => {
        getCertificate();
    }, [courseTitle]);

    if (!course) return <></>;

    const downloadPDF = async () => {
        Object.assign(document.createElement('a'), {
            href: courseCertificate.pdf,
            download: `${courseTitle}.pdf`
        }).click();
    };

    if (loading) {
        return;
    }

    if (!courseCertificate) return;

    return (
        <Modal open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
            <Container>
                <CloseButton onClick={handleClose} />
                {course.status === 'approved' ? (
                    <>
                        <h1>{courseTitle}</h1>
                        <h4>{moment(course.final_date).format('DD/MM/YYYY')}</h4>
                        <div className={'certificate'}>
                            <PdfViewer src={courseCertificate.url} />
                        </div>
                        <footer>
                            <DownloadButton onClick={downloadPDF}>Baixar Certificado</DownloadButton>
                        </footer>
                    </>
                ) : (
                    <div
                        style={{
                            textAlign: 'center',
                            minHeight: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <h1>Aguardando aprovação!</h1>
                        <h4>Aguarde a aprovação para baixar o certificado.</h4>
                    </div>
                )}
            </Container>
        </Modal>
    );
};
CertificateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    course: PropTypes.any.isRequired
};
export default CertificateModal;
