import { SET_USER_ACTION_PLANS, SET_USER_ACTION_PLANS_LOADING, SET_USER_ARCHIVE_ACTION_PLANS } from '@/redux/mutations';

export const initialState = {
    actionPlans: [],
    archiveActionPlans: [],
    loadingActionPlans: false
};

const userActionPlansReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_USER_ACTION_PLANS:
            return {
                ...state,
                actionPlans: actions.payload
            };
        case SET_USER_ARCHIVE_ACTION_PLANS:
            return {
                ...state,
                archiveActionPlans: actions.payload
            };
        case SET_USER_ACTION_PLANS_LOADING:
            return {
                ...state,
                loadingActionPlans: actions.payload
            };
        default:
            return state;
    }
};

export default userActionPlansReducer;
