import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';

import { Loading } from '@/pages/Common/EnterpriseDetails/EnterprisePage/tabs/Properties/components/PropertiesPerConsultant/Loading';
import { Ranking } from '@components/Enterprise/Ranking';
import { EmployeeProfileModal } from '@components/molecules/modals/EmployeeProfileModal';
import useCollaboratorService from '@services/collaborator';
import useEnterpriseService from '@services/enterprise';

export const PropertiesPerConsultant = ({ enterpriseId, changeFilter, filterPerAllOrBlocked }) => {
    const enterpriseService = useEnterpriseService();
    const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [yearFilter, setYearFilter] = useState(null);
    const [years, setYears] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const perColumn = 15;
    const maxColumns = 4;
    const columnCount = useMemo(() => {
        let colCount = Math.ceil(data.length / perColumn);
        if (colCount > maxColumns) {
            colCount = maxColumns;
        }
        return colCount;
    }, [data]);

    const { getCollaboratorPerId } = useCollaboratorService();

    const handleOpenConsultant = async (consultantId) => {
        const consultor = await getCollaboratorPerId(consultantId);
        setSelectedConsultant(consultor);
    };
    const getData = async () => {
        enterpriseService
            .getPropertyCountPerConsultant(enterpriseId, yearFilter, filterPerAllOrBlocked)
            .then((resp) => {
                setData(resp.data);
                setYears(resp.years);
                console.log('years', resp.years);
                changeFilter(resp.years[0]);
            })
            .catch((error) => {})
            .finally(() => setLoading(false));
    };

    const handleSetYearFilter = (year) => {
        setYearFilter(year);
        changeFilter(year);
    };

    useEffect(() => {
        getData();
    }, [yearFilter, filterPerAllOrBlocked]);
    useEffect(() => {
        if (years?.length > 0 && yearFilter === null) {
            setYearFilter(years[0]);
        }
    }, [years]);
    return loading ? (
        <Loading />
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3>Consultores / Propriedades atendidas</h3>
            </Grid>
            <Grid item xs={2}>
                <TextField fullWidth size={'small'} select value={yearFilter} onChange={(evt) => handleSetYearFilter(evt.target.value)}>
                    {years
                        ? years.map((year) => (
                              <MenuItem key={year} value={year}>
                                  {year}
                              </MenuItem>
                          ))
                        : null}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {_.chunk(data.slice(0, maxColumns * perColumn), perColumn).map((column, key) => {
                        return (
                            <Grid key={`column-${key}`} item xs={12} md={12 / columnCount}>
                                <Ranking
                                    titleKey={'name'}
                                    valueKey={'properties_count'}
                                    items={column}
                                    valueLabel={'propriedades'}
                                    onClickItem={(item) => {
                                        handleOpenConsultant(item.id);
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <EmployeeProfileModal
                open={selectedConsultant !== null}
                handleClose={(e) => {
                    e.stopPropagation();
                    setSelectedConsultant(null);
                }}
                profile={selectedConsultant}
            />
        </Grid>
    );
};
PropertiesPerConsultant.propTypes = {
    years: PropTypes.array
};
