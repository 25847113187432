import styled from 'styled-components';

import { Paper } from '@mui/material';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 1.33rem;
`;

export const Section = styled.div`
    background: #ffffff;
    flex: 1;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #c6c6c6;
`;

export const PaperTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #25272e;
`;

export const FilterTitle = styled.p`
    font-weight: 700;
    font-size: 1.125rem;
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    min-width: ${(props) => props.minWidth || null};
`;

export const Text = styled(PaperTitle)`
    font-weight: 400;
`;

export const FilterHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #f0f1f3;
    border-radius: 12px;
`;

export const StatsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem;
    grid-auto-rows: min-content;
    justify-content: center
    width: 100%;

    @media (max-width: 1420px) {
        grid-gap: 1rem;
    }

    @media (max-width: 767px) {
        grid-gap: 1rem;
    }

    @media (max-width: 637px) {
        grid-template-columns: unset !important;
        grid-gap: 0.5rem;
    }
`;

export const TotalItemCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-radius: 15px;
    border: 1px solid #cfdcd3;
    background: #fbfbfc;
`;
