import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    DASHBOARD_OPEN_RIGHT_SIDEBAR,
    DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT,
    DASHBOARD_CLOSE_RIGHT_SIDEBAR,
    DASHBOARD_OPEN_SETTINGS_MODAL,
    DASHBOARD_CLOSE_SETTINGS_MODAL
} from '@/redux/mutations';

const useDashboard = () => {
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);
    return {
        ...dashboard,
        openRightSidebar: (content) => {
            dispatch({ type: DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT, payload: content });
            dispatch({ type: DASHBOARD_OPEN_RIGHT_SIDEBAR });
        },
        closeRightSidebar: () => {
            dispatch({ type: DASHBOARD_CLOSE_RIGHT_SIDEBAR });
            dispatch({ type: DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT, payload: <></> });
        },
        openSettingsModal: (content) => {
            dispatch({ type: DASHBOARD_OPEN_SETTINGS_MODAL });
        },
        closeSettingsModal: (content) => {
            dispatch({ type: DASHBOARD_CLOSE_SETTINGS_MODAL });
        }
    };
};
export default useDashboard;
