import styled from 'styled-components';

export const InfoText = styled.p`
    font-size: 1.25rem;
    text-align: center;
    /* font-weight: 600; */
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
`;
