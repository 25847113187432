import PropTypes from 'prop-types';

import { Container, Content, PaperTitle } from './styles';

export const GraphicCard = (props) => {
    return (
        <Container style={{ ...props.style }} height={props.height}>
            <PaperTitle>{props.title}</PaperTitle>
            <Content center={props.contentCenter} justify={props.contentJustify}>
                {props.children}
            </Content>
        </Container>
    );
};

GraphicCard.propTypes = {
    style: PropTypes.object,
    children: PropTypes.node,
    title: PropTypes.string,
    height: PropTypes.string,
    contentCenter: PropTypes.bool,
    contentJustify: PropTypes.string
};
