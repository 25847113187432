import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { ClassType } from 'types/data/course';
import { padNumber } from 'utils/general';

import { Step, StepLabel, Stepper } from '@mui/material';

import CertificateModal from '@/components/molecules/modals/CertificateModal';
import { ReactComponent as IconPdf } from '@assets/icons/IconPdf.svg';

import { Container, ActionButton } from './styles';

const ClassesList = ({ classes, changeClass, currentClassNumber, maxClassNumber, finished, finishedCourse, courseUser, courseTitle }) => {
    const [testActive, setTestActive] = useState(false);
    const [openCertificateModal, setOpenCertificateModal] = useState(false);
    const clickHandler = (index) => {
        isNaN(index) ? setTestActive(true) : setTestActive(false);

        if (finished && index === maxClassNumber + 1) {
            changeClass(index);
            return;
        }

        if (index === currentClassNumber || index > maxClassNumber) {
            return;
        }
        if (isNaN(index)) {
            setTestActive(false);
            return;
        }

        changeClass(index);
    };

    const handleCertificateModal = () => {
        setOpenCertificateModal(!openCertificateModal);
    };

    const activeStep = () => {
        if (!finished && currentClassNumber === maxClassNumber) {
            return currentClassNumber - 1;
        }

        if (finished && currentClassNumber === maxClassNumber) {
            return currentClassNumber;
        }

        if (currentClassNumber === maxClassNumber) {
            return currentClassNumber;
        }

        return maxClassNumber - !finishedCourse;
    };

    return (
        <Container>
            <Stepper activeStep={activeStep()} orientation="vertical">
                {classes.map((classItem, index) => (
                    <Step
                        key={index}
                        sx={{
                            cursor: 'pointer',
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: 'secondary.light' // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-label.Mui-completed': {
                                color: '#373848', // Just text label (COMPLETED)
                                fontWeight: '500'
                            },
                            '& .MuiStepIcon-root': {
                                fontSize: '20px',
                                width: '20px',
                                height: '20px'
                            }
                        }}
                        completed={
                            index + 1 <= currentClassNumber || classItem.class_user?.is_completed || classItem.asking?.[0]?.asking_user
                        }
                    >
                        {classItem.number === undefined ? (
                            <StepLabel onClick={() => clickHandler(Number(classItem.number))}>
                                {testActive ? (
                                    <>
                                        <span style={{ fontWeight: '600' }}>Avaliação final</span>
                                    </>
                                ) : (
                                    'Avaliação final'
                                )}
                            </StepLabel>
                        ) : (
                            <StepLabel onClick={() => clickHandler(Number(classItem.number))}>
                                {Number(classItem.number) === currentClassNumber ? (
                                    <>
                                        <span style={{ fontWeight: '600' }}>
                                            Aula {padNumber(Number(classItem.number))}: {classItem.title}
                                        </span>
                                    </>
                                ) : (
                                    `Aula ${padNumber(Number(classItem.number))}: ${classItem.title}`
                                )}
                            </StepLabel>
                        )}
                    </Step>
                ))}
            </Stepper>
            {courseUser != null && courseUser.is_completed == 1 ? (
                <ActionButton
                    disabled={openCertificateModal}
                    startIcon={<IconPdf />}
                    onClick={handleCertificateModal}
                    sx={{ opacity: openCertificateModal ? 0.5 : 1 }}
                >
                    Certificado
                </ActionButton>
            ) : (
                ''
            )}
            {openCertificateModal && (
                <CertificateModal
                    open={openCertificateModal}
                    course={courseUser}
                    handleClose={handleCertificateModal}
                    courseTitle={courseTitle}
                />
            )}
        </Container>
    );
};

ClassesList.propTypes = {
    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    changeClass: PropTypes.func.isRequired,
    currentClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    finished: PropTypes.bool.isRequired,
    finishedCourse: PropTypes.bool.isRequired
};

export default ClassesList;
