import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';
import useSnackbar from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import { formikProps } from 'utils/helpers/formikProps';
import * as Yup from 'yup';

import { Grid, MenuItem, TextField } from '@mui/material';

import DropzoneInput from '@/components/atoms/formInputs/DropzoneInput';
import MaskedTextField from '@/components/atoms/formInputs/MaskedTextField';
import ButtonCallAction from '@/components/BI/buttonCallAction';
import BaseModal from '@/components/molecules/modals/BaseModal';
import useSupportFormService from '@services/supportForm';

import { Label } from './styles';

const ContentFormModal = ({ open, handleClose, typeProblem = null }) => {
    const [technicalForm, setTechnicalForm] = useState(typeProblem === 'technical');
    const user = useSelector((state) => state.auth.user);
    const supportService = useSupportFormService();
    const snackbar = useSnackbar();

    const formValidation = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string().required('O email é obrigatório').email('Insira um email válido'),
        phone: Yup.string().required('O telefone é obrigatório').min(11, 'O telefone é inválido'),
        problem_description: Yup.string().required('A descrição do problema é obrigatória'),
        type: Yup.string().test('test-selected', 'Selecione o Assunto', (value) => value !== 'not_valid_option'),
        problem_link: Yup.string(),
        files: Yup.array()
    });

    const formik = useFormik({
        initialValues: {
            email: user.email,
            name: user.name,
            phone: user.phone || '',
            problem_description: '',
            type: typeProblem || 'not_valid_option',
            problem_link: '',
            files: []
        },
        validationSchema: formValidation,
        onSubmit: () => {
            const formData = new FormData();
            formData.append('name', formik.values.name);
            formData.append('email', formik.values.email);
            formik.values.phone = formik.values.phone.replace(/[^\d]/g, '');
            formData.append('phone', formik.values.phone);
            formData.append('problem_description', formik.values.problem_description);
            formData.append('type', formik.values.type);

            if (technicalForm) {
                formData.append('problem_link', formik.values.problem_link);
                formik.values.files.forEach((file) => {
                    formData.append('files[]', file);
                });
            }
            supportService
                .createSupportForm(formData)
                .then((resp) => {
                    snackbar(resp.success, 'success');
                    setTimeout(() => {
                        formik.resetForm(formik.initialValues);
                        setTechnicalForm(false);
                    }, 200);
                })
                .catch((error) => {
                    const erro = error.response.data.errors;
                    snackbar(erro[Object.keys(erro)[0]], 'error');
                });
        }
    });

    return (
        <BaseModal
            title="Suporte"
            open={open}
            backgroundColor="rgba(0, 0, 0, 0.6)"
            handleClose={() => handleClose()}
            mdSize={'60vw'}
            maxWidth="md"
            withActions={
                <>
                    <ButtonCallAction variant={'outlined'} onClick={() => handleClose()}>
                        <b>Cancelar</b>
                    </ButtonCallAction>
                    <ButtonCallAction
                        type={'button'}
                        onClick={() => formik.submitForm()}
                        buttonColor="#3A6647"
                        width="11.5rem"
                        marginRightIcon="20px"
                        fontSize={'1rem'}
                    >
                        Enviar
                    </ButtonCallAction>
                </>
            }
        >
            <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <Label>Assunto:</Label>
                        <TextField
                            {...formikProps('type', formik)}
                            fullWidth
                            size={'small'}
                            required
                            select
                            onChange={(event) => {
                                formik.handleChange(event);
                                setTechnicalForm(event.target.value === 'technical');
                            }}
                            value={formik.values.type}
                            disabled={typeProblem !== null}
                        >
                            <MenuItem value={'not_valid_option'}>Selecione</MenuItem>
                            <MenuItem value="content">Dúvidas Conteúdo</MenuItem>
                            <MenuItem value="technical">Técnico</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6} md={6}>
                        <Label>Nome completo:</Label>
                        <TextField
                            {...formikProps('name', formik)}
                            InputProps={{
                                style: {
                                    backgroundColor: '#FFFFFF'
                                }
                            }}
                            fullWidth
                            size={'small'}
                            placeholder={'Nome'}
                            required
                        />
                    </Grid>

                    <Grid item xs={6} md={6}>
                        <Label>E-mail:</Label>
                        <TextField
                            {...formikProps('email', formik)}
                            InputProps={{
                                style: {
                                    backgroundColor: '#FFFFFF'
                                }
                            }}
                            fullWidth
                            size={'small'}
                            placeholder="email@email.com.br"
                            required
                        />
                    </Grid>

                    <Grid item xs={6} md={6}>
                        <Label>Telefone:</Label>
                        <MaskedTextField
                            {...formikProps('phone', formik)}
                            onChange={formik.handleChange}
                            mask={'+55 (99) 99999-9999'}
                            value={formik.values.phone}
                            onBlur={formik.handleBlur}
                            placeholder="+55 (XX) XXXXX-XXXX"
                            InputProps={{
                                style: {
                                    backgroundColor: '#FFFFFF'
                                }
                            }}
                            required
                        />
                    </Grid>

                    {technicalForm && (
                        <Grid item xs={12}>
                            <Label>Link em que ocorreu o problema:</Label>
                            <TextField
                                {...formikProps('problem_link', formik)}
                                fullWidth
                                InputProps={{
                                    style: {
                                        backgroundColor: '#FFFFFF'
                                    }
                                }}
                                size={'small'}
                                required
                            />
                        </Grid>
                    )}

                    <Grid item xs={technicalForm ? 6 : 12} md={technicalForm ? 6 : 12}>
                        <Label>{formik.values.type === 'content' ? 'Descreva à dúvida:' : 'Descreva o problema:'}</Label>
                        <TextField
                            {...formikProps('problem_description', formik)}
                            fullWidth
                            InputProps={{
                                style: {
                                    backgroundColor: '#FFFFFF'
                                }
                            }}
                            size={'small'}
                            onBlur={formik.handleBlur}
                            required
                            multiline
                            minRows={5.8}
                            maxRows={8}
                        />
                    </Grid>

                    {technicalForm && (
                        <Grid item xs={6}>
                            <Label>Anexo:</Label>
                            <DropzoneInput onDrop={(files) => formik.setFieldValue('files', files)} maxFileSize={5} />
                        </Grid>
                    )}
                </Grid>
            </form>
        </BaseModal>
    );
};

export default ContentFormModal;

ContentFormModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    typeProblem: PropTypes.string
};
