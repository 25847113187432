import { projectData } from 'constants/data';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const Meta = ({ title, description, url, image }) => {
    const location = useLocation();
    const completeUrl = url ? `${projectData.url}/${url}` : `${projectData.url}/${location.pathname}`;

    return (
        <Helmet>
            <title>{title ? `${title} | ${projectData.name}` : projectData.name}</title>
            <meta name="description" content={description ? description : projectData.description} />
            <link rel="canonical" href={completeUrl} />
            <meta httpEquiv="content-language" content="pt-BR" />

            <meta property="og:type" content="website" />
            <meta property="og:locale" content="pt-BR" />
            <meta property="og:title" content={title ? `${title} | ${projectData.name}` : `${projectData}`} />
            <meta property="og:url" content={completeUrl} />
            <meta property="og:description" content={description ? description : projectData.description} />
            <meta property="og:image" content={image ? image : projectData.image} />
            <meta property="og:url" content={url ? url : completeUrl} />
            <meta property="og:site_name" content={projectData.name} />
            <meta property="fb:app_id" content="aap_id" />
            <meta name="keywords" content={`${projectData.keywords.join(', ')}, ${projectData.name}, ${projectData.name}`} />
            <meta name="author" content={projectData.author} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={completeUrl} />
            <meta name="twitter:title" content={`${title} | ${projectData.name}`} />
            <meta name="twitter:description" content={description ? description : projectData.description} />
            <meta name="twitter:creator" content="${projectData}" />
            <meta name="twitter:image:src" content={image ? image : projectData.image} />
            <meta name="robots" content="follows" />

            <meta name="pinterest-rich-pin" content="false" />
            <meta name="copyright" content={`© ${new Date().getFullYear()} ${projectData.name}`} />
            <link rel="shortcut icon" href={projectData.image} />
        </Helmet>
    );
};

Meta.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string
};

export default Meta;
