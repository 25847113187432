import styled from 'styled-components';

import { Button } from '@mui/material';

export const Container = styled.div`
    padding: 1rem;
    transition: var(--transition);

    margin-bottom: 1.5rem;
    b {
        font-weight: 900;
        font-size: 1rem;
    }

    .MuiStepConnector-lineVertical {
        border-left-style: dashed;
    }
`;

export const ActionButton = styled(Button)`
    width: 100%;
    color: #fff !important;
    height: 100%;
    margin-top: 10px;
    padding: 0.375rem 0.75rem;
    background-color: #3a6647;
    text-transform: none;
    font-size: 1rem;

    @media (max-width: 1480px) {
        height: 50%;
        padding: 5% 2%;
        font-size: 14px;
    }

    @media (max-width: 1320px) {
        font-size: 12px;
    }

    &:hover {
        background-color: #3a6647;
    }

    &.disabled {
        background-color: #c4d2c8;
    }
`;
