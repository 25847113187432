import React from 'react';

import BlockIcon from '@mui/icons-material/Block';
import { Tooltip } from '@mui/material';

import { Container, Item, ItemTitle, ItemValue } from './styles';
export const Ranking = ({
    items,
    titleKey = 'title',
    valueKey = 'value',
    valueLabel = '',
    onClickItem = null,
    otherKey = '',
    otherLabel = '',
    badge = false
}) => {
    const handleClickItem = (item) => {
        if (typeof onClickItem === 'function') {
            onClickItem(item);
        }
    };
    return (
        <Container>
            {items.map((item, itemKey) => (
                <Item key={`item-${itemKey}`} onClick={() => handleClickItem(item)} clickable={Boolean(onClickItem)}>
                    <ItemTitle>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            {item[titleKey] ?? 'Título inválido.'}{' '}
                            {item.user_blocked === 1 && (
                                <Tooltip title="Usuário bloqueado">
                                    <BlockIcon fontSize="12px" color="red" disabled />
                                </Tooltip>
                            )}
                        </div>
                    </ItemTitle>
                    <ItemValue>
                        {item[valueKey] ?? 'valor inválido'}
                        {valueLabel && ` ${valueLabel}`}

                        {otherKey && (
                            <ItemValue>
                                {item[otherKey]} {otherLabel}
                                {badge && (
                                    <span
                                        className={`badge ${
                                            item[otherKey] > 0 && item[otherKey] >= item[valueKey] ? 'badge-success' : 'badge-warning'
                                        }`}
                                    />
                                )}
                            </ItemValue>
                        )}
                    </ItemValue>
                </Item>
            ))}
        </Container>
    );
};
