import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: ${(props) => (props.rightSidebar ? 'calc(100vw - var(--rightSidebar-width))' : '100vw')};
    height: 100vh;
    background-color: var(--color-background);
    display: flex;
    /* flex-direction: row;
    gap: 2rem; */
`;

export const SidebarGap = styled.div`
    display: block;
    width: 88px; //${({ menuExpanded }) => (!menuExpanded ? '168px' : '388px')};
    transition: width 0.2s ease-in-out;
    height: 100%;
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* min-height: 100vh; */
    /* padding: calc(var(--navbar-height) + 2rem) 2rem 1rem 1rem; */
    overflow-x: hidden;
`;

export const ContentMain = styled.main`
    width: 100%;
    padding: 0 2.5rem;

    margin-top: 5.375rem;

    @media (max-width: 767px) {
        margin-top: 5.375rem;
        padding: 0 20px;
    }
`;

export const NavbarContainer = styled.div`
    width: 100%;
    height: 80px;
    background-color: yellow;
`;

export const RightSidebar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    width: var(--rightSidebar-width);
    height: 100vh;
    background: ${(props) =>
        props.profile === 1
            ? 'linear-gradient(0deg, #d8dfe2, #d8dfe2), #f5f5f5'
            : props.profile === 2
            ? 'var(--color-background-dairy)'
            : 'linear-gradient(0deg, #E1EAE6, #E1EAE6), #F5F5F5;'};
    padding: 0 39.5px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
`;

export const RightSidebarNav = styled.div`
    width: 100%;
    height: var(--navbar-height);
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
