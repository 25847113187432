import React from 'react';
import { useDispatch } from 'react-redux';

import Cookies from 'js-cookie';

import { SET_CURRENT_USER_ROLE, SET_LOGIN } from '@/redux/mutations';
import { removeCookie } from '@/utils/cookies';
import { addHours } from '@/utils/date';

import useApi from '../api';

const useAuthService = () => {
    const { api } = useApi();

    const dispatch = useDispatch();

    return {
        serverConnection: async () => {
            return api.get('server-status');
        },

        logged: async (currentUserRole, setKeyAccessLogin = false) => {
            const token = Cookies.get('token');

            if (!token) {
                return false;
            }
            const response = await api
                .post('me', {
                    currentUserRole,
                    setKeyAccessLogin
                })
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.request;
                });

            return response;
        },

        setCurrentUserRole: (currentUserRole) => {
            dispatch({ type: SET_CURRENT_USER_ROLE, payload: currentUserRole });
        },
        authentication: async (email, password) => {
            return await api
                .post('login', {
                    email: email,
                    password: password
                })
                .then((data) => {
                    removeCookie('token');
                    removeCookie('refresh_token');

                    const hours = 7 * 24;
                    Cookies.set('token', data.data.access_token);

                    Cookies.set('refresh_token', data.data.refresh_token);

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    return JSON.parse(error.request.response);
                });
        },
        recoverPassword: async (email) => {
            return await api
                .post('password/email', {
                    email
                })
                .catch((error) => {
                    return { message: JSON.parse(error.request.response).error };
                });
        },
        resetPassword: async (email, password, passwordConfirmation, token) => {
            return await api
                .post('password/reset', {
                    email,
                    password,
                    password_confirmation: passwordConfirmation,
                    token
                })
                .catch((error) => {
                    const response = JSON.parse(error.request.response);
                    let errorMessage = response.error;

                    if (response.errors && response.errors.password) {
                        errorMessage = response.errors.password[0];
                    }

                    throw new Error(errorMessage);
                });
        },
        signUp: async (name, email, password, passwordConfirmation) => {
            const response = await api
                .post('register_student', {
                    name: name,
                    email: email,
                    password: password,
                    password_confirmation: passwordConfirmation,
                    acceptanceTerm: 1
                })
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    return JSON.parse(error.request.response);
                });

            if (response !== true) {
                throw new Error(Object.values(response.errorValidate)[0]);
            }

            return response;
        },
        logout: () => {
            removeCookie('token');
            removeCookie('refresh_token');
        },
        setLogin: (loggedData) => {
            dispatch({
                type: SET_LOGIN,
                payload: loggedData
            });
        }
    };
};

export default useAuthService;
