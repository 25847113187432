import { CircularProgress } from '@mui/material';

import Logo from '@components/atoms/svgs/Logo';

import { Container } from './styles';

const LoadingComponent = ({ fullSize = false, hideLogo = false, marginTop = '0' }) => {
    return (
        <Container fullSize={fullSize} marginTop={marginTop}>
            {!hideLogo && <Logo />}
            <CircularProgress />
        </Container>
    );
};
export default LoadingComponent;
