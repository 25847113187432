export const initialState = {
    clickedReportButton: false,
    isLoadingReport: false
};

const managerPanelReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'MANAGER_PANEL_REPORT_BUTTON_CLICK':
            return {
                ...state,
                clickedReportButton: actions.payload
            };

        case 'MANAGER_PANEL_REPORT_IS_LOADING':
            return {
                ...state,
                isLoadingReport: actions.payload
            };
        case 'MANAGER_PANEL_RESET':
            return {
                ...state,
                clickedReportButton: false,
                isLoadingReport: false
            };
        default:
            return state;
    }
};

export default managerPanelReducer;
