import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 26px;
    height: 100vh;
    position: relative;

    @media (max-width: 768px) {
        /* position: fixed; */
    }

    width: ${(props) => (props.expanded ? '280px' : '64px')};
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    background: linear-gradient(97deg, rgba(100, 223, 69, 0) 0%, rgba(58, 102, 71, 0.6) 100%), #3a6647;
`;

export const MenuItem = styled.div`
    width: 100% !important;
    display: flex;
    /* flex-direction: ${({ menuExpanded }) => !menuExpanded && 'column'};
    justify-content: ${({ menuExpanded }) => !menuExpanded && 'center'}; */
    justify-content: flex-start;
    align-items: center;
    position: relative;

    padding: 0 0.5rem;

    cursor: pointer;

    > .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;

        &.stroke {
            > * {
                stroke: #ffffff;
            }
        }

        &.fill {
            > * {
                fill: #ffffff;
            }
        }
    }

    > .title {
        color: #ffffff;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        width: 100%;
        display: none;
        position: absolute;
        bottom: -10px;
        left: 0;
    }

    &:hover {
        > .title {
            display: ${({ menuExpanded }) => !menuExpanded && 'block'};
        }
    }

    > .expanded-title {
        left: 60px;
        margin-left: 0.5rem;
        color: #ffffff;
        font-size: 1rem;
        width: 150px;
        position: absolute;
        left: 60px;
        white-space: nowrap;
        font-weight: 800;
        display: ${({ menuExpanded }) => (!menuExpanded ? 'none' : 'block')};
    }

    &.active:after,
    &.active:before {
        top: 10px;
        left: 0;
        border: solid white;
        content: ' ';
        height: 32px;
        border-radius: 100px;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
`;

export const MenuItemIcon = styled.div`
    max-width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const MenuExpandedIcon = styled.div`
    position: absolute;
    bottom: 4rem;
    right: 1.125rem;
    padding: 8px;
    background-color: #ffffffbf;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #ffffff;
    }

    cursor: pointer;
`;
