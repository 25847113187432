import styled from 'styled-components';

export const TextName = styled.p`
    font-size: 18px;
    font-weight: 600;
`;

export const Div = styled.div`
    width: 100%;
    gap: 4px;
`;

export const Seniority = styled.span`
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 8px;
`;
