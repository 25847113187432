export const initialState = {
    invalidated: false
};

const producerReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PRODUCER_INVALIDATED':
            return {
                ...state,
                invalidated: actions.payload
            };
        default:
            return state;
    }
};

export default producerReducer;
