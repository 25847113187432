import { TOGGLE_ACTION_PLAN_DRAWER } from '@/redux/mutations';

export const initialState = {
    actionPlanDrawer: false,
    questionTitle: null
};

const propertyReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case TOGGLE_ACTION_PLAN_DRAWER:
            return {
                ...state,
                actionPlanDrawer: !state.actionPlanDrawer
            };
        case 'SET_QUESTION_TITLE':
            return {
                ...state,
                questionTitle: actions.payload
            };
        default:
            return state;
    }
};

export default propertyReducer;
