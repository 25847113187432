import { SET_USER_PROPERTIES, SET_CHANGE_HOLDER_PROPERTY_MODAL } from '@/redux/mutations';

export const initialState = {
    properties: [],
    changeHolderModalOpen: false
};

const userPropertiesReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_USER_PROPERTIES:
            return {
                ...state,
                properties: actions.payload
            };
        case SET_CHANGE_HOLDER_PROPERTY_MODAL:
            return {
                ...state,
                changeHolderModalOpen: actions.payload.open
            };
        default:
            return state;
    }
};

export default userPropertiesReducer;
