import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding-top: 56.25%;
    margin: 1rem 0;

    .react-player {
        position: absolute;
        top: 0;
        left: 0;

        iframe {
            border-radius: 8px;
            background-color: #ccc;
        }
    }
`;
