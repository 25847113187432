import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Line = ({ color, marginTop }) => {
    return <Container color={color} marginTop={marginTop} />;
};

Line.propTypes = {
    color: PropTypes.string,
    marginTop: PropTypes.string
};

export default Line;
