import { useEffect, useRef, useState } from 'react';

import { random, sortBy } from 'lodash';
import moment from 'moment';

import { ALL_MONTHS, MONTH_TRANSLATIONS } from '@/constants/date';
import useDashboardActionPlansService from '@/services/dashboard-action-plans';

export const useActions = (enterprise_id) => {
    const [graphBarData, setGraphBarData] = useState([]);
    const [sectorsData, setSectorsData] = useState([]);
    const [years, setYears] = useState([]);
    const [totalActionPlansPerYear, setTotalActionPlansPerYear] = useState(0);
    const [totalActionPlansConsolidated, setTotalActionPlansConsolidated] = useState(0);
    const [totalEnterpriseActionPlansActionsCreated, setTotalEnterpriseActionPlansActionsCreated] = useState(0);
    const [totalEnterpriseActionPlansActionsCompleted, setTotalEnterpriseActionPlansActionsCompleted] = useState(0);
    const [totalEnterpriseActionPlansActionsCanceled, setTotalEnterpriseActionPlansActionsCanceled] = useState(0);

    const { getDashboardActionsPlans } = useDashboardActionPlansService();

    const [filterPer, setFilterPer] = useState('');

    const selectRef = useRef();

    function handleGraphBarData(mounthsCount) {
        const formattedData = [['Mês', 'Quantidade', { role: 'annotation' }]];
        ALL_MONTHS.forEach((month) => {
            const translatedMonth = MONTH_TRANSLATIONS[month];
            const find = mounthsCount.find((d) => d.month === month);
            const quantity = find ? parseInt(find.count, 10) : 0;
            const annotation = quantity;
            formattedData.push([translatedMonth, quantity, annotation]);
        });

        setGraphBarData(formattedData);

        const sectors = [];

        for (let i = 20; i > 0; i--) {
            sectors.push({
                title: 'Sector ' + i,
                progress: random(40, 100)
            });
        }
    }

    const handleCountEnterpriseActionPlansByArea = (sectorsData) => {
        const sectors = sectorsData.map((item) => {
            return {
                title: item.area_name,
                progress: item.area_count
            };
        });

        // const sortedSectors = sortBy(sectors, 'title');

        setSectorsData(sectors);
    };

    const handleFilter = (value) => {
        setFilterPer(value);
        handleDashboardActionsPlans(value);
    };

    const handleDashboardActionsPlans = async (filter = '') => {
        const { data } = await getDashboardActionsPlans(enterprise_id, filter);

        const yearsSelect = data.yearsFilter.map((y) => String(y));

        const currentYear = new Date().getFullYear();

        setYears(yearsSelect ?? [currentYear]);

        if (!filterPer.length) {
            setFilterPer(yearsSelect[0] ?? currentYear);
        }

        setTotalActionPlansPerYear(data.totalEnterpriseActionPlansYear ?? 0);
        setTotalActionPlansConsolidated(data.totalEnterpriseActionPlans ?? 0);
        setTotalEnterpriseActionPlansActionsCreated(data.countEnterpriseActionPlansActionsCreated ?? 0);
        setTotalEnterpriseActionPlansActionsCompleted(data.countEnterpriseActionPlansActionsCompleted ?? 0);
        setTotalEnterpriseActionPlansActionsCanceled(data.countEnterpriseActionPlansActionsCanceled ?? 0);

        if (data && data.countEnterpriseActionPlansMonthYear.length) {
            handleGraphBarData(data.countEnterpriseActionPlansMonthYear);
        }

        if (data && data.countEnterpriseActionPlansByArea) {
            handleCountEnterpriseActionPlansByArea(data.countEnterpriseActionPlansByArea);
        }
    };

    useEffect(() => {
        handleDashboardActionsPlans();
    }, [enterprise_id]);

    return {
        selectRef,
        filterPer,
        handleFilter,
        totalActionPlansPerYear,
        totalActionPlansConsolidated,
        totalEnterpriseActionPlansActionsCreated,
        totalEnterpriseActionPlansActionsCompleted,
        totalEnterpriseActionPlansActionsCanceled,
        graphBarData,
        sectorsData,
        years
    };
};
