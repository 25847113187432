import React from 'react';

import PropTypes from 'prop-types';

import noImage from '@assets/images/no_image.jpeg';

import { Img } from './styles';

const SafeImg = ({ src, alt }) => {
    const handleError = (evt) => {
        evt.target.onError = null;
        evt.target.src = noImage;
    };
    return <Img src={src} alt={alt} onError={handleError} />;
};

export default SafeImg;

SafeImg.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};
