import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

import moment from 'moment';

import useManagerPanelService from '@/services/managerPanel';
import useUserEventsService from '@/services/user-events';

const optionsPDF = {
    // default is `save`
    method: 'save',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.HIGH,
    page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.SMALL,
        // default is 'A4'
        format: 'A4',
        // default is 'portrait'
        orientation: 'portrait'
    },
    canvas: {
        // default is 'image/jpeg' for better size performance
        qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
            // compress: true
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {}
    }
};

const getTargetElement = () => document.getElementById('pdf-content-id');

const monthTranslations = {
    January: 'Jan',
    February: 'Fev',
    March: 'Mar',
    April: 'Abr',
    May: 'Mai',
    June: 'Jun',
    July: 'Jul',
    August: 'Ago',
    September: 'Set',
    October: 'Out',
    November: 'Nov',
    December: 'Dez'
};

const monthsNumbers = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12
};

const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const useVisits = (idEnterprise) => {
    // const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
    const [isLoading, setIsLoading] = useState(false);
    const [visits, setVisits] = useState('');
    const [filterPer, setFilterPer] = useState(null);
    const [filterPerMonth, setFilterPerMonth] = useState(0);
    const [constultantsFilter, setConsultantsFilter] = useState(null);
    const [graphBarData, setGraphBarData] = useState([]);
    const [graphBarTickers, setGraphBarTickers] = useState([]);

    const { getVisitsPetMonth } = useUserEventsService();
    const { getVisitsPDFReport } = useManagerPanelService();

    const dispatch = useDispatch();

    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);

    function handleGraphBarDataPerYear(data, filterYear) {
        const formattedData = [['Month', 'Total Fazendas', 'Visitas agendadas', 'Fazendas visitadas', 'Porcentagem']];

        const currentMonth = Number(moment().locale('en').format('M'));
        const currentYear = Number(moment().locale('en').format('YYYY'));
        const perYear = filterYear || currentYear;

        allMonths.forEach((month) => {
            const item = data.find((item) => item.month == month);
            const monthNumber = monthsNumbers[month] - 1;

            if (perYear === currentYear && monthNumber > currentMonth) {
                return false;
            }

            const translatedMonth = monthTranslations[month];

            formattedData.push([
                translatedMonth,
                item?.totalProperties ?? 0,
                item?.quantity ?? 0,
                item?.quantityReportsApproved ?? 0,
                Number(item?.visitPercentage ?? 0)
            ]);
        });

        const processedData = formattedData.map(function (row, index) {
            if (index === 0) {
                // Add tooltip column headers to the first row
                return row.concat([
                    { type: 'string', role: 'tooltip' },
                    { type: 'string', role: 'annotation' }
                ]);
            }

            const porcetageText = `Porcentagem: ${row[4]}%`;
            return row.concat([`${porcetageText}`, `${row[4]}%`]);
        });

        setGraphBarData(processedData);
    }
    function handleGraphBarDataPerMonth(data, filterYear, filterMonth = 0) {
        const formattedData = [['Dia', 'Visitas agendadas', { role: 'style' }]];

        const currentDay = Number(moment().locale('en').format('D'));
        const currentYear = Number(moment().locale('en').format('YYYY'));
        const currentMonth = Number(moment().locale('en').format('M'));

        const perYear = filterYear ?? currentYear;

        const numberOfDaysOnMonth = moment(new Date(perYear, filterMonth ?? currentMonth, 0)).daysInMonth();

        if (numberOfDaysOnMonth > 0) {
            const days = [];
            for (let i = 1; i <= numberOfDaysOnMonth; i++) {
                days.push(i);
            }

            setGraphBarTickers(days);
        }

        data.forEach((item) => {
            const dayNumber = item.day ?? 1;
            let color = '#62C054';
            if (perYear === Number(currentYear) && dayNumber === currentDay && currentMonth === filterMonth) {
                color = '#3A6647';
            }

            formattedData.push([dayNumber, item?.quantity ?? 0, color]);
        });

        const processedData = formattedData.map(function (row, index) {
            if (index === 0) {
                // Add tooltip column headers to the first row
                return row.concat([{ type: 'string', role: 'tooltip' }]);
            }

            const text = `Visitas: ${row[1]}`;
            return row.concat([`${text}`]);
        });

        setGraphBarData(processedData);
    }

    const handleData = async (option, type = 'year') => {
        const perYear = type === 'month' ? filterPer : option;
        const perMonth = type === 'year' ? filterPerMonth : option;

        try {
            setIsLoading(true);
            const data = await getVisitsPetMonth(idEnterprise, perYear, perMonth);

            setVisits(data);

            if (filterPer === null) {
                setFilterPer(data.years[0]);
            }

            if (data.items?.countPerMonth?.length && perMonth === 0) {
                handleGraphBarDataPerYear(data?.items?.countPerMonth, perYear);
            } else if (data?.items?.countPerDay?.length && perMonth > 0) {
                handleGraphBarDataPerMonth(data?.items?.countPerDay, perYear, perMonth);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectOption = (option, type) => {
        setIsLoading(true);
        if (type === 'year') {
            setFilterPer(option);
        } else {
            setFilterPerMonth(option);
        }

        handleData(option, type);
    };

    const handleReport = async () => {
        dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
        const filename = `relatorio_de_visitas_${filterPer}_${filterPerMonth}.pdf`;
        await generatePDF(getTargetElement, {
            ...optionsPDF,
            filename
        });
        dispatch({ type: 'MANAGER_PANEL_RESET' });
        // try {
        //     dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
        //     const { data } = await getVisitsPDFReport(idEnterprise, filterPer, constultantsFilter ?? '');

        //     if (data) {
        //         handleDownloadData(data, `relatorio_de_visitas_${filterPer}`);
        //     }
        // } catch (error) {
        //     console.log(error);
        // } finally {
        //     dispatch({ type: 'MANAGER_PANEL_RESET' });
        // }
    };

    useEffect(() => {
        // handleFakeData();
        handleData();
    }, []);

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    return {
        visits,
        isLoading,
        filterPer,
        constultantsFilter,
        graphBarData,
        handleSelectOption,
        handleReport,
        setConsultantsFilter,
        filterPerMonth,
        setFilterPerMonth,
        ticksByMonth: graphBarTickers
    };
};
