import styled from 'styled-components';

import { Paper } from '@mui/material';

export const Container = styled(Paper)`
    width: 100%;
    height: ${({ height }) => height ?? '140px'};
    padding: 24px;
    box-shadow: 0px 3px 12px rgba(81, 69, 159, 0.08);
    border-radius: 15px;
`;
export const PaperTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #25272e;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    justify-content: ${({ justify }) => justify ?? 'space-evenly'};
    padding: 24px;
    gap: 15px;
`;
