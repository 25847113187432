import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { MenuItem } from '@mui/material';

import SectionTitle from '@/components/atoms/fonts/SectionTitle';
import ConsultantActivesAndInactives from '@/components/molecules/modals/ConsultantActivesAndInactives';
import WelcomeModal from '@/components/molecules/modals/WelcomeModal';
import HeaderTotalGraphic from '@/components/organisms/headers/HeaderTotalGraphic';
import useCollaboratorService from '@/services/collaborator';
import useDashboardAccessesService from '@/services/dashboard-accesses';
import useManagerPanelService from '@/services/managerPanel';
import { handleDownloadData } from '@/utils/helpers/downloads';
import Bar from '@components/BI/graphicBar';
import LoadingComponent from '@components/templates/LoadingComponent';

import { Section } from '../../../styles';
import {
    PaperContainer,
    PaperContainerSmall,
    PaperCount,
    PaperTitle,
    Div,
    TextFildStyled,
    ContainerYears,
    PaperActiveAndInactive,
    SubTitle
} from './styles';

const monthTranslations = {
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro'
};

const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
const Accesses = () => {
    const [loading, setLoading] = useState(true);
    const [graphBarData, setGraphBarData] = useState([]);
    const [filterPer, setFilterPer] = useState('');
    const [years, setYears] = useState(1);
    const [consolidatedTotal, setConsolidatedTotal] = useState(0);
    const [totalAccessesPerYear, setTotalAccessesPerYear] = useState(0);
    const [accessActivesAndInactives, setAccessActivesAndInactives] = useState(0);
    const [showModal, setShowModal] = useState({ isOpen: false, data: [], title: '' });

    const { getAccessLoginPDFReport } = useManagerPanelService();

    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);

    const dispatch = useDispatch();

    const { id, tab } = useParams();

    const enterpriseId = id;

    const { getDashboardAccesses, getDashboardAtivesAndInactives } = useDashboardAccessesService();
    const selectRef = useRef();
    const options = {
        chartArea: { width: '80%', height: '80%' },
        colors: ['#62C054'],
        explorer: { axis: 'horizontal', keepInBounds: false },
        orientation: 'horizontal',
        legend: { position: 'none' },
        fontSize: 12,
        tooltip: {
            ignoreBounds: false,
            textStyle: {
                bold: true
            }
        },
        annotations: {
            textStyle: {
                fontSize: 15,
                bold: true,
                color: '#000'
            }
        },
        vAxis: {
            title: 'Quant. de acessos',
            titleTextStyle: {
                italic: false,
                color: '#9BA1A9'
            }
        }
    };

    function handleGraphBarData(data) {
        if (!data || !Object.values(data).length) {
            return;
        }

        const formattedData = [['Mês', 'Quantidade', { role: 'annotation' }]];

        allMonths.forEach((month) => {
            const translatedMonth = monthTranslations[month];
            const quantity = data[month] ? parseInt(data[month], 10) : 0;
            const annotation = quantity;
            formattedData.push([translatedMonth, quantity, annotation]);
        });

        setGraphBarData(formattedData);
    }

    const handleFilter = (value) => {
        setFilterPer(value);
        handleData(value);
    };

    const handleReport = async () => {
        try {
            dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
            const { data } = await getAccessLoginPDFReport(enterpriseId, filterPer);

            if (data) {
                handleDownloadData(data, `relatorio_de_acessos_${filterPer}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({ type: 'MANAGER_PANEL_RESET' });
        }
    };

    const handleData = async (filter = '') => {
        try {
            setLoading(true);
            const { data } = await getDashboardAccesses(filter, enterpriseId);
            const responseAtivesAndInactives = await getDashboardAtivesAndInactives(enterpriseId);
            setAccessActivesAndInactives(responseAtivesAndInactives.data);

            const currentYear = new Date().getFullYear();
            setYears(data?.years.length ? data.years : [currentYear]);

            if (!filterPer.length) {
                setFilterPer(data.years[0] ?? currentYear);
            }

            setTotalAccessesPerYear(data.totalAccessesPerYear);
            setConsolidatedTotal(data.consolidatedTotal);

            handleGraphBarData(data.quantityPerMonth);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleData();
    }, []);

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    return (
        <>
            <Section>
                {!loading ? (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <PaperContainer height="765px">
                                    <HeaderTotalGraphic
                                        title="Total Consolidado:"
                                        description={consolidatedTotal + ' Acessos de Consultores'}
                                    />
                                    <PaperTitle>Acessos / ano</PaperTitle>
                                    <SubTitle>
                                        Acessos totais dos consultores. (Múltiplos acessos do mesmo consultor também contam)
                                    </SubTitle>
                                    <ContainerYears>
                                        <Div>
                                            <TextFildStyled
                                                ref={selectRef}
                                                fullWidth
                                                size={'small'}
                                                select
                                                value={filterPer}
                                                onChange={(evt) => handleFilter(evt.target.value)}
                                            >
                                                {years.length > 0 &&
                                                    years?.map((value, key) => (
                                                        <MenuItem key={key} value={value}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                            </TextFildStyled>
                                        </Div>
                                        <Div width="18rem">
                                            <p>
                                                <b>{filterPer} |</b> {totalAccessesPerYear} acessos de Consultores
                                            </p>
                                        </Div>
                                    </ContainerYears>
                                    <Bar height="100%" width="100%" allData={graphBarData} options={options} />
                                </PaperContainer>
                            </Grid>

                            {graphBarData.length > 0 && (
                                <>
                                    <Grid item xs={6}>
                                        <PaperActiveAndInactive
                                            elevation={0}
                                            height="197px"
                                            onClick={() =>
                                                setShowModal({
                                                    isOpen: true,
                                                    data: accessActivesAndInactives.actives,
                                                    title: 'Consultores ativos'
                                                })
                                            }
                                        >
                                            <div>
                                                <h2>Consultores ativos</h2>
                                                <p>(acessou nos últimos 5 dias)</p>
                                                <h1 style={{ color: '#62c054' }}>{accessActivesAndInactives.actives.length}</h1>
                                            </div>
                                        </PaperActiveAndInactive>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PaperActiveAndInactive
                                            height="197px"
                                            elevation={0}
                                            onClick={() =>
                                                setShowModal({
                                                    isOpen: true,
                                                    data: accessActivesAndInactives.inactives,
                                                    title: 'Consultores inativos'
                                                })
                                            }
                                        >
                                            <div>
                                                <h2>Consultores inativos</h2>
                                                <p>(acessou nos últimos 5 dias)</p>
                                                <h1 style={{ color: '#D35E4B' }}>{accessActivesAndInactives.inactives.length}</h1>
                                            </div>
                                        </PaperActiveAndInactive>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <ConsultantActivesAndInactives
                            title={showModal.title}
                            data={showModal.data}
                            isOpen={showModal.isOpen}
                            handleClose={() => setShowModal({ isOpen: false, data: [], title: '' })}
                        />
                    </>
                ) : (
                    <LoadingComponent hideLogo />
                )}
            </Section>
        </>
    );
};

export default Accesses;
