import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 126px;
    padding: 8px;
    background-color: #fbfbfc;
    border: 1px solid #cfdcd3;
    border-radius: 12px;
    margin-bottom: ${(props) => props.marginBottom || '1rem'};
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h1,
    h2 {
        margin: 0 !important;
    }

    h1 {
        font-size: 1.5rem;
        margin: 0 !important;
    }

    h2 {
        font-size: 2rem;
        margin: 0 !important;
    }

    @media (max-width: 767px) {
        height: auto;
        h1 {
            font-size: 1rem;
        }
        h2 {
            font-size: 1.3rem;
        }
    }
`;
