import styled from 'styled-components';

import { InputLabel } from '@mui/material';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const InputGroup = styled.div`
    padding: 1.5rem;
    border: 1px solid #cfdcd3;
    border-radius: 0.5rem;
    /* grid-gap: 1.5rem; */
    position: relative;

    .content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        /* min-width: 400px; */

        label {
            /* font-size: 18px; */
        }

        &__item {
            @media (max-width: 580px) {
                max-width: 200px;
            }
        }
    }
`;

export const ErrorMsg = styled.div`
    margin-top: -16px;
    color: #d32f2f;
    font-size: 12px;
`;

export const ItemAddress = styled.div`
    margin-top: 1.5rem;
    display: flex;
    gap: 4rem;

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 1.5rem;
    }
`;

export const ItemAvatar = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    @media (max-width: 900px) {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;
    }

    img {
        border-radius: 50%;
        width: 6.875rem;
        height: 6.875rem;
        object-fit: contain;
    }
`;

export const EditButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 12px;
    padding: 5px;
    cursor: default;
    :hover {
        background: #ebefed;
    }
`;

export const FormTitle = styled.div`
    font-weight: 600;
    font-size: 24px;

    color: #91949c;
`;

export const Label = styled(InputLabel)`
    font-weight: 600;
    font-size: 16px;
    color: #1c1c1c;
    margin-bottom: 0.8rem;

    span {
        font-weight: 400;
    }
`;

export const FileUpload = styled.label`
    padding: 8px 16px;
    background-color: #3a6647;
    color: #ffffff;
    font-weight: bold;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    input[type='file'] {
        display: none;
    }
`;
