import styled, { css } from 'styled-components';

export const Button = styled.button`
    width: ${(props) => (props.size === 'medium' ? '42px' : '24px')};
    height: ${(props) => (props.size === 'medium' ? '42px' : '24px')};
    background-color: #fff;
    border: none;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    color: var(--color-primary);

    &:hover {
        filter: brightness(0.95);
    }

    &:active {
        filter: brightness(0.9);
    }
`;

export const TransparentButton = styled.div`
    width: ${(props) => (props.size === 'small' ? '32px' : '42px')};
    height: ${(props) => (props.size === 'small' ? '32px' : '42px')};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.disabled ? props.bgColor : 'transparent')};
    border-radius: 12px;
    padding: 8px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    color: ${(props) => props.color} !important;
    border: none;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};

    > svg {
        ${(props) =>
            props.size === 'large-icon'
                ? css`
                      width: 35px;
                      height: 35px;
                  `
                : css`
                      width: 20px;
                      height: 20px;
                  `}
        > [stroke='current'] {
            stroke: ${(props) => props.color} !important;
        }
    }

    :hover {
        background: ${(props) => props.bgColor};
    }

    :active {
        filter: brightness(0.95);
    }
`;
