import styled from 'styled-components';

import { Button, Dialog, IconButton } from '@mui/material';

export const Modal = styled(Dialog)``;

export const Container = styled.div`
    width: 890px;
    position: relative;
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;

        color: #373848;
    }
    > h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;

        color: #000000;
    }
    > footer {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;
export const DownloadButton = styled(Button)`
    width: 230px;
    padding: 8px 22px;

    background: #87a851;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    color: white;
    &:hover {
        background: #87a851;
        color: white;
    }
    &:active {
        background: #58732d;
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    right: 16px;
    top: 16px;
    color: black;
`;
