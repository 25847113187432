import { ReactComponent as PorcentageIcon } from '@assets/icons/GraphicPorcentageIcon.svg';

import { LegendContainer, LegendDot, LegendItem } from './style';

export const GraphicPerMonth = () => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <LegendContainer>
                <LegendItem>
                    <LegendDot color="#55A559" />
                    <span>Fazendas agendadas</span>
                </LegendItem>
                <LegendItem>
                    <LegendDot color="#3A6647" />
                    <span>Fazendas agendadas hoje</span>
                </LegendItem>
            </LegendContainer>
        </div>
    );
};
