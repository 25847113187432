import styled from 'styled-components';

import { Grid, Paper } from '@mui/material';
import { Button } from '@mui/material';

export const CardActiveConsultants = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    gap: 10px;
    cursor: pointer;
    width: 100%;
    min-height: 78px;

    background: #ffffff;

    border: 1px solid #cfdcd3;
    border-radius: 6px;

    > div {
        width: 100%;
        gap: 4px;
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;

        > span {
            border-radius: 8px;
            font-size: 10px;
            font-weight: 600;
            padding: 2px 8px;
        }
    }

    .last-access {
        text-align: right;
        font-size: 12px;
        font-weight: 600;
    }

    .name {
        font-size: 18px;
    }
`;

export const Actions = styled.div`
    display: flex;
    max-width: 25%;
    justify-content: space-between;
    margin-right: 5%;
`;

export const ButtonSyled = styled(Button)`
    text-transform: none;
    font-weight: bold;
`;

export const ImageProfile = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
