import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    width: 100%;
    overflow-y: auto;
    /* Card */
`;

export const Content = styled.div`
    flex: 1;
`;

export const Section = styled.div`
    flex: 1;
`;
