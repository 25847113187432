import styled from 'styled-components';

import { Paper } from '@mui/material';
import { TextField } from '@mui/material';
export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    /* justify-content: ${({ justify }) => justify ?? 'space-evenly'}; */
    padding: 24px;
    gap: 15px;
    width: 100%;
    height: ${({ height }) => height ?? '140px'};

    box-shadow: 0px 3px 12px rgba(81, 69, 159, 0.08);
    border-radius: 15px;
`;

export const PaperActiveAndInactive = styled(Paper)`
    padding: 24px;
    cursor: pointer;
    box-shadow: 0px 3px 12px rgba(81, 69, 159, 0.08);
    > div {
        width: 100%;
        align-items: center;
        justify-content: center;
        > h1 {
            font-size: 42px;
            font-weight: 700;
            text-align: center;
        }
        > h2 {
            margin-top: 13px;
            line-height: 20px;
            font-size: 24px;
            text-align: center;
        }
        > p {
            font-size: 16px;
            text-align: center;
        }
    }
`;
export const PaperContainerSmall = styled(PaperContainer)`
    padding: 12px;
    gap: 0px;
`;

export const Row = styled.div`
    display: flex;
    gap: 1rem;
`;
export const Col = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`;

export const PaperTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #25272e;
`;

export const PaperCount = styled.span`
    font-size: 2.25rem;
    font-weight: 700;
`;

export const Div = styled.div`
    width: ${({ width }) => width ?? '6.5rem'};
    display: flex;
    align-items: center;
    gap: 1.25rem;

    justify-content: flex-end;

    > p {
        font-size: 20px;
    }
`;

export const ContainerYears = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const TextFildStyled = styled(TextField)`
    width: 15rem;
    background-color: white;
`;

export const SubTitle = styled.p`
    margin-top: -0.625rem;
    margin-bottom: 0.625rem;
`;
