import { FormControl } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(FormControl)`
    width: 100%;
    font-size: 0.8rem;
    border-radius: var(--border-radius);

    > label > div {
        display: flex;
        align-items: center;
    }
`;
