import { SET_USER_EVENTS, SET_USER_EVENTS_LOADING } from '@/redux/mutations';

export const initialState = {
    events: [],
    loadingEvents: false
};

const userEventReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_USER_EVENTS:
            return {
                ...state,
                events: actions.payload
            };
        case SET_USER_EVENTS_LOADING:
            return {
                ...state,
                loadingEvents: actions.payload
            };
        default:
            return state;
    }
};

export default userEventReducer;
