import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';

import UploadWithPreview from 'components/atoms/formInputs/UploadWithPreview';
import useEnterprise from 'hooks/useEnterprise';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import {
    Grid,
    TextField,
    MenuItem,
    CircularProgress,
    Select,
    OutlinedInput,
    Box,
    Chip,
    Checkbox,
    ListItemText,
    FormHelperText
} from '@mui/material';

import MaskedTextField from '@/components/atoms/formInputs/MaskedTextField';
import { formikProps } from '@/utils/helpers/formikProps';
import { OutlinedInputPassword } from '@components/atoms/formInputs/OutlinedInputPassword';
import { RadioInput } from '@components/atoms/formInputs/RadioInput';
import useAddressService from '@services/address';
import useCollaboratorService from '@services/collaborator';
import useUserService from '@services/user';

import { FileUpload, Form, ItemAvatar, Label, InputGroup, ErrorMsg } from './styles';

const ROLES = [
    {
        id: 4,
        name: 'Coordenador'
    },
    {
        id: 5,
        name: 'Supervisor'
    },
    {
        id: 2,
        name: 'Consultor'
    }
];

const COURSES = ['Veterinário', 'Agrônomo', 'Zootecnista', 'Tecnólogo', 'Técnico Agrícola', 'Outro'];

const LEVELS = ['Trainee', 'Júnior', 'Pleno', 'Sênior'];

export const EmployeesForm = ({ formik, editing, isDemonstration }) => {
    const { employees } = useEnterprise();
    const addressService = useAddressService();
    const userService = useUserService();
    const collaboratorService = useCollaboratorService();

    const { search } = useLocation();

    const fileInput = useRef();
    const [usersConnection, setUsersConnection] = useState([]);
    const [isLoadingConnections, setIsLoadingConnections] = useState(false);
    const [rolesOptions, setRolesOptions] = useState(ROLES);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCity, setLoadingCity] = useState(false);
    const [graduationError, setGraduationError] = useState(false);
    const [userResource, setUserResource] = useState(false);
    const [optionsCity, setOptionsCity] = useState([]);
    const [validCep, setValidcep] = useState(true);
    const currentUserRole = Number(Cookies.get('currentUserRole'));
    useEffect(() => {
        if (formik.isSubmitting && !formik.values.graduation.length) {
            setGraduationError(true);
        }
    }, [formik.isSubmitting]);

    // const handleFileChange = (evt) => {
    //     const url = URL.createObjectURL(evt.target.files[0]);
    //     const files = evt.target.files;

    //     formik.setFieldValue('toUpload', files[0]);
    //     formik.setFieldValue('photo', url);
    // };

    const handleFileChange = (files, url) => {
        formik.setFieldValue('toUpload', files[0]);
        if (url == formik.values.photo) {
            formik.setFieldValue('photo', null);
        } else {
            formik.setFieldValue('photo', url);
        }
    };

    const handleChangeRole = (roles) => {
        formik.setFieldValue('roles', roles);
    };

    const changeGraduation = (item) => {
        setGraduationError(false);
        formik.setFieldValue('graduation', item);
    };

    const changeRehagroCourse = (item) => {
        formik.setFieldValue('rehagroCourse', item);
    };

    const addressByCep = (cep) => {
        addressService
            .addressByCep(cep)
            .then((resp) => {
                if (resp.error) {
                    snackbar(resp.error, 'error');
                    formik.setFieldValue('street', '');
                    formik.setFieldValue('district', '');
                    formik.setFieldValue('cityName', '');
                    setOptionsCity([]);
                    setValidcep(false);
                } else {
                    formik.setFieldValue('street', resp.logradouro);
                    let state = userResource?.states?.find((state) => state.uf === resp.uf);
                    formik.setFieldValue('district', state.uf);
                    let city = userResource?.cities?.find((city) => city.title === resp.localidade && city.uf === state.uf);
                    formik.setFieldValue('cityName', city.title);
                    setValidcep(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const searchCity = (event) => {
        setLoadingCity(true);
        if (event.target.value) {
            userService.getCityPerIdState(event.target.value).then((resp) => {
                setCities(resp);
                setLoadingCity(false);
            });
        }
    };

    const changeState = (target) => {
        formik.setFieldValue('district', target);
        formik.setFieldValue('cityName', '');
    };

    const handleSetConnections = () => {
        let rolesArray = String(formik.values.roles).split(',');
        if (!rolesArray.length) {
            rolesArray = [formik.values.roles];
        }

        if (rolesArray.includes('2')) {
            setIsLoadingConnections(true);
            const connections = employees.filter((item) => item.roles.includes(5));
            setUsersConnection(connections);
            setIsLoadingConnections(false);
        } else if (rolesArray.includes('5')) {
            setIsLoadingConnections(true);
            const connections = employees.filter((item) => item.roles.includes(4));
            setUsersConnection(connections);
            setIsLoadingConnections(false);
        } else {
            setUsersConnection([]);
        }
    };

    useEffect(() => {
        // if (editing && formik.values.enterpriseEmployeeId) {
        //     handleChangeRole(formik.values.roles);
        // }

        handleSetConnections();
    }, [formik.values.roles]);

    function changeCityField(stateUf) {
        stateUf != '' &&
            !editing &&
            setOptionsCity(
                userResource?.cities
                    .filter((cityFilter) => {
                        return cityFilter.uf === stateUf;
                    })
                    .map((city) => ({
                        value: city.value,
                        title: city.title,
                        state_id: city.state_id
                    }))
            );
        if (editing && userResource) {
            setOptionsCity(
                userResource?.cities
                    .filter((cityFilter) => {
                        return cityFilter.uf === stateUf;
                    })
                    .map((city) => ({
                        value: city.value,
                        title: city.title,
                        state_id: city.state_id
                    }))
            );
        }
    }

    const getAllResource = () => {
        collaboratorService
            .getResource()
            .then((resp) => {
                setUserResource(resp);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getAllResource();
    }, []);

    useEffect(() => {
        if (isDemonstration) {
            setRolesOptions([
                {
                    id: 7,
                    name: 'Demonstração'
                }
            ]);
        }
    }, [isDemonstration]);

    useEffect(() => {
        changeCityField(formik.values.district);
    }, [formik.values.district, userResource]);

    return (
        <Form onSubmit={formik.handleSubmit} noValidate>
            <InputGroup>
                <ItemAvatar>
                    <UploadWithPreview
                        rounded
                        helperText={formik.touched.photo && <>{formik.errors.photo}</>}
                        error={formik.touched.photo && Boolean(formik.errors.photo)}
                        text={'Foto'}
                        value={formik.values.photo}
                        //defaultValue={auth.user.image}
                        onFileChange={handleFileChange}
                    />
                    {/* <img src={formik.values.photo} alt="avatar" />

                    <FileUpload>
                        Foto
                        <input type={'file'} onChange={handleFileChange} ref={fileInput} accept={'image/jpeg, image/png'} />
                    </FileUpload> */}
                </ItemAvatar>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <div className="content">
                            <div className="content__item">
                                <Label>Nome:</Label>
                                <TextField
                                    {...formikProps('name', formik)}
                                    value={formik.values.name}
                                    error={Boolean(formik.errors.name)}
                                    helperText={formik.errors.name}
                                    fullWidth
                                    size={'small'}
                                    required
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <div className="content">
                            <div className="content__item">
                                <Label>Como deseja ser chamado:</Label>
                                <TextField {...formikProps('nickname', formik)} value={formik.values.nickname} fullWidth size={'small'} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4.5}>
                        <div className="content">
                            <div className="content__item">
                                <Label>CPF:</Label>
                                <MaskedTextField
                                    {...formikProps('cpf', formik)}
                                    onChange={formik.handleChange}
                                    mask={'999.999.999-99'}
                                    value={formik.values.cpf}
                                    onBlur={formik.handleBlur}
                                    placeholder="XXX.XXX.XXX-XX"
                                    required
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4.5}>
                        <div className="content">
                            <div className="content__item">
                                <Label>Data de nascimento:</Label>
                                <MaskedTextField
                                    {...formikProps('birthDate', formik)}
                                    onChange={formik.handleChange}
                                    mask={'99/99/9999'}
                                    value={formik.values.birthDate}
                                    onBlur={formik.handleBlur}
                                    placeholder="XX/XX/XXXX"
                                    required
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </InputGroup>

            {editing && (
                <>
                    <InputGroup>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Label>Tipo:</Label>
                                <Select
                                    id="roles"
                                    {...formikProps('roles', formik)}
                                    fullWidth
                                    required
                                    size={'small'}
                                    multiple
                                    disabled={currentUserRole === 5}
                                    value={formik.values.roles || []}
                                    onChange={(evt) => handleChangeRole(evt.target.value)}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => {
                                                const label = rolesOptions.find((item) => item.id === value);
                                                return <Chip size="small" key={value} label={label?.name} />;
                                            })}
                                        </Box>
                                    )}
                                >
                                    {rolesOptions?.map((value) => (
                                        <MenuItem key={value.id} value={value.id}>
                                            <Checkbox checked={formik.values.roles && formik.values.roles.indexOf(value.id) > -1} />
                                            <ListItemText primary={value.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.errors.roles && <FormHelperText error={formik.errors.roles}>{formik.errors.roles}</FormHelperText>}
                            </Grid>
                            <Grid item xs>
                                <Label>Senioridade:</Label>
                                <TextField
                                    {...formikProps('seniority', formik)}
                                    fullWidth
                                    required
                                    size={'small'}
                                    select
                                    value={formik.values.seniority || ''}
                                    onChange={formik.handleChange}
                                >
                                    {LEVELS?.map((value) => (
                                        <MenuItem
                                            key={value}
                                            value={String(value)
                                                .normalize('NFD')
                                                .replace(/[\u0300-\u036f]/g, '')}
                                        >
                                            {value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {String(formik.values.roles).split(',').includes('2') && (
                                <Grid item xs>
                                    <Label>Vinculo:</Label>
                                    <TextField
                                        {...formikProps('enterpriseEmployeeId', formik)}
                                        fullWidth
                                        size={'small'}
                                        required={formik.values.enterpriseEmployeeId > 1}
                                        select
                                        value={formik.values.enterpriseEmployeeId || ''}
                                        onChange={formik.handleChange}
                                    >
                                        {isLoadingConnections ? (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <CircularProgress size={20} />
                                            </div>
                                        ) : (
                                            usersConnection?.map((value) => (
                                                <MenuItem key={value.id} value={value.id}>
                                                    {value.name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </TextField>
                                </Grid>
                            )}
                        </Grid>
                    </InputGroup>

                    <InputGroup>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div className="content">
                                    <div className="content_item">
                                        <Label>
                                            Email <span>(Responsável)</span>:
                                        </Label>
                                        <TextField
                                            {...formikProps('enterpriseEmployeeEmail', formik)}
                                            type="email"
                                            value={formik.values.enterpriseEmployeeEmail}
                                            fullWidth
                                            size={'small'}
                                            disabled={editing}
                                            aria-readonly
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="content">
                                    <div className="content_item">
                                        <Label>Celular:</Label>
                                        <MaskedTextField
                                            {...formikProps('enterpriseEmployeePhone', formik)}
                                            onChange={formik.handleChange}
                                            mask={'+55 (99) 99999-9999'}
                                            value={formik.values.enterpriseEmployeePhone}
                                            onBlur={formik.handleBlur}
                                            placeholder="+55 (XX) XXXXX-XXXX"
                                            disabled={editing}
                                            required
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </InputGroup>
                </>
            )}

            <InputGroup>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <div className="content">
                            <Label>Endereço:</Label>
                            <div className="content_item">
                                <span>CEP:</span>
                                <MaskedTextField
                                    {...formikProps('zipcode', formik)}
                                    onChange={formik.handleChange}
                                    mask={'99999-999'}
                                    value={formik.values.zipcode}
                                    onBlur={(e) => addressByCep(e.target.value)}
                                    placeholder="XXXXX-XXX"
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div className="content">
                            <div className="content_item">
                                <span>Rua:</span>
                                <TextField {...formikProps('street', formik)} value={formik.values.street} fullWidth size={'small'} />
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <div className="content">
                            <div className="content_item">
                                <span>Número:</span>
                                <TextField {...formikProps('number', formik)} value={formik.values.number} fullWidth size={'small'} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div className="content">
                            <div className="content_item">
                                <span>Complemento:</span>
                                <TextField
                                    {...formikProps('complement', formik)}
                                    value={formik.values.complement}
                                    fullWidth
                                    size={'small'}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span>Estado:</span>
                        <TextField
                            id="district"
                            {...formikProps('district', formik)}
                            fullWidth
                            size={'small'}
                            select
                            required
                            value={formik.values.district}
                            onChange={(evt) => changeState(evt.target.value)}
                            disabled={formik.values.zipcode || !validCep}
                        >
                            {validCep &&
                                userResource?.states?.map((value) => (
                                    <MenuItem key={value.value} value={value.uf}>
                                        {value.uf}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span>Cidade:</span>
                        <TextField
                            id="cityName"
                            {...formikProps('cityName', formik)}
                            fullWidth
                            required
                            size={'small'}
                            select
                            value={formik.values.cityName}
                            disabled={formik.values.zipcode || optionsCity.length == 0}
                        >
                            {optionsCity?.map((value) => (
                                <MenuItem key={value.value} value={value.title}>
                                    {value.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </InputGroup>

            {editing && (
                <InputGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <div className="content">
                                <div className="content_item">
                                    <Label>Email (Login):</Label>
                                    <TextField {...formikProps('email', formik)} value={formik.values.email} fullWidth size={'small'} />
                                </div>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <div className="content">
                                <div className="content_item">
                                    <Label>Defina sua senha:</Label>
                                    <OutlinedInputPassword
                                        {...formikProps('password', formik)}
                                        value={formik.values.password}
                                        type="password"
                                        fullWidth
                                        size={'small'}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className="content">
                                <div className="content_item">
                                    <Label>Confirme sua senha:</Label>
                                    <OutlinedInputPassword
                                        {...formikProps('password_confirmation', formik)}
                                        value={formik.values.password_confirmation}
                                        type="password"
                                        fullWidth
                                        size={'small'}
                                    />
                                </div>
                            </div>
                        </Grid> */}
                    </Grid>
                </InputGroup>
            )}

            <InputGroup>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Label>Formação:</Label>
                        {graduationError && <ErrorMsg>{formik.errors.graduation}</ErrorMsg>}
                        {COURSES.map((item) => (
                            <div className="content" key={item}>
                                <div className="content_item">
                                    <RadioInput
                                        {...formikProps('graduation', formik)}
                                        handleChange={() => changeGraduation(item)}
                                        value={item === formik.values.graduation}
                                        item={{
                                            label: item
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                        <div className="content">
                            <div className="content_item">
                                <Label>Ano de formatura:</Label>
                                <TextField
                                    {...formikProps('graduationCompletedAt', formik)}
                                    value={formik.values.graduationCompletedAt}
                                    fullWidth
                                    type="number"
                                    size={'small'}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </InputGroup>

            <InputGroup>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Label>Já fez algum curso do Rehagro?</Label>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={1.5}>
                                <div className="content">
                                    <div className="content_item">
                                        <RadioInput
                                            {...formikProps('rehagroCourse', formik)}
                                            handleChange={() => changeRehagroCourse(1)}
                                            value={Boolean(formik.values.rehagroCourse)}
                                            item={{
                                                label: 'Sim'
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <div className="content">
                                    <div className="content_item">
                                        <RadioInput
                                            {...formikProps('rehagroCourse', formik)}
                                            value={!Boolean(formik.values.rehagroCourse)}
                                            handleChange={() => changeRehagroCourse(0)}
                                            item={{
                                                label: 'Não'
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div className="content">
                            <div className="content_item">
                                <Label>Qual?</Label>
                                <TextField
                                    {...formikProps('whichCourse', formik)}
                                    value={formik.values.whichCourse}
                                    fullWidth
                                    disabled={!formik.values.rehagroCourse}
                                    size={'small'}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </InputGroup>
        </Form>
    );
};

EmployeesForm.propTypes = {
    formik: PropTypes.any.isRequired,
    editing: PropTypes.bool,
    isDemonstration: PropTypes.bool
};
