import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const Button = ({ type, disabled, onClick, children, size, width, color, icon, variant, bgColor, height, ...props }) => {
    return (
        <Container
            size={size || 'medium'}
            variant={variant || 'contained'}
            type={type || 'button'}
            onClick={() => onClick()}
            disabled={disabled}
            width={width || null}
            color={color || 'primary'}
            startIcon={icon || null}
            bgColor={bgColor || null}
            height={height || null}
            {...props}
        >
            {children}
        </Container>
    );
};

Button.propTypes = {
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.any,
    variant: PropTypes.string,
    bgColor: PropTypes.string,
    height: PropTypes.string
};

export default Button;
