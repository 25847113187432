export const getEvaluationSectorTypeName = (type) => {
    switch (type) {
        case 'required':
            return 'Básico';
        case 'secondary':
            return 'Básico';
        case 'optional':
            return 'Opcional';
        default:
            return '';
    }
};
