import React from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { Container } from './styles';

const CarouselArrowButton = ({ type, onClick }) => {
    return (
        <Container onClick={() => onClick()} type={type}>
            {
                {
                    left: <ChevronLeft />,
                    right: <ChevronRight />
                }[type]
            }
        </Container>
    );
};

CarouselArrowButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['left', 'right']).isRequired
};

export default CarouselArrowButton;
