import PropTypes from 'prop-types';

import { Worker } from '@react-pdf-viewer/core';

import { Container, ViewerContainer } from './styles';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({ src, onDocumentLoad }) => {
    return (
        <Container>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <ViewerContainer defaultScale="PageFit" fileUrl={src} onDocumentLoad={onDocumentLoad} />
            </Worker>
        </Container>
    );
};

PdfViewer.propTypes = {
    src: PropTypes.string,
    onDocumentLoad: PropTypes.func
};

export default PdfViewer;
