import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;

    width: 100%;
    overflow-y: auto;
`;

export const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5%;
    padding-left: 2px;
`;

export const Card = styled.div`
    display: flex;
    margin-bottom: 5%;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    height: 420px;
    border-radius: 8px;
    padding: 1%;
    gap: 1%;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.12);
`;
