import { months } from 'constants/date';
import moment from 'moment';

import {
    SET_SUBCLINICAL_MASTITIS_DATA,
    SET_SUBCLINICAL_MASTITIS_FILTER_OPTIONS,
    SET_SUBCLINICAL_MASTITIS_FILTERS,
    SET_SUBCLINICAL_MASTITIS_DATA_LOADING,
    RESET_SUBCLINICAL_MASTITIS_FILTERS,
    SET_SUBCLINICAL_MASTITIS_PROPERTY_ID
} from '@/redux/mutations';

export const initialState = {
    property_id: null,
    data: {},
    loading: false,
    optionsFilters: {
        years: [moment().year()],
        months: months
    },
    selectedFilters: {
        sectors: ['Todos'],
        years: [moment().year()],
        months: months
    }
};

const indicatorsPanelSubclinicalMastitisReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_SUBCLINICAL_MASTITIS_PROPERTY_ID:
            return {
                ...state,
                property_id: actions.payload
            };
        case SET_SUBCLINICAL_MASTITIS_DATA:
            return {
                ...state,
                data: actions.payload
            };
        case SET_SUBCLINICAL_MASTITIS_DATA_LOADING:
            return {
                ...state,
                loading: actions.payload
            };
        case SET_SUBCLINICAL_MASTITIS_FILTER_OPTIONS:
            return {
                ...state,
                optionsFilters: actions.payload
            };
        case SET_SUBCLINICAL_MASTITIS_FILTERS:
            return {
                ...state,
                selectedFilters: actions.payload
            };
        case RESET_SUBCLINICAL_MASTITIS_FILTERS:
            return {
                ...state,
                selectedFilters: {
                    sectors: ['Todos'],
                    years: [moment().year()],
                    months: months
                }
            };
        default:
            return state;
    }
};

export default indicatorsPanelSubclinicalMastitisReducer;
