import useApi from '../api';

const useDashboardAccessesService = () => {
    const { api } = useApi();

    return {
        getDashboardAccesses: (year, enterpriseId) =>
            api.get(`access_login`, {
                params: {
                    year,
                    enterpriseId
                }
            }),
        getDashboardAtivesAndInactives: (enterpriseId) =>
            api.get(`active_and_inactive`, {
                params: {
                    enterpriseId
                }
            })
    };
};

export default useDashboardAccessesService;
