import styled from 'styled-components';

export const Container = styled.div`
    border-top: 1px solid #c7c7c7;
    padding-top: 1rem;
    width: 100%;

    > header {
        width: 100%;
        margin-bottom: 1rem;

        > h2 {
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 130%;
            display: flex;
            align-items: center;
            color: #464646;
        }
    }

    > main {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const QuestionContainer = styled.div`
    padding: 1rem 0;
    width: 100%;
`;
