import React from 'react';

import PropTypes from 'prop-types';

import CarouselArrowButton from '../../buttons/CarouselArrowButton';
import CardCarousel from '../../cards/CardCarousel';
import GridItemCard from '../../cards/GridItemCard';
import { CarouselContainer, DesktopContainer, GridContainer, DesktopContainerCarousel } from './styles';

const CardsCarousel = ({ values, redirectUser, userViewEvent }) => {
    const allCoursesWithoutEvents = values.every((course) => course.event_user === null);

    const eventUserRegistered = values.filter((value) => value?.event_user?.registered == 1);

    return (
        <>
            {eventUserRegistered.length < 4 && (
                <DesktopContainer>
                    {allCoursesWithoutEvents ? (
                        <h3>
                            Você ainda não se inscreveu em nenhum evento. Continue navegando para descobrir e participar de eventos
                            interessantes!
                        </h3>
                    ) : (
                        <>
                            <GridContainer>
                                {values.map((value, index) => (
                                    <React.Fragment key={`value-${index}`}>
                                        {value.event_user !== null && value.event_user.registered === 1 && (
                                            <GridItemCard value={value} redirectUser={redirectUser} action={userViewEvent} />
                                        )}
                                    </React.Fragment>
                                ))}
                            </GridContainer>
                        </>
                    )}
                </DesktopContainer>
            )}

            {eventUserRegistered.length >= 4 && (
                <DesktopContainerCarousel>
                    <CarouselContainer
                        cellSpacing={65}
                        dragging={false}
                        swiping={false}
                        renderCenterLeftControls={({ previousSlide }) => (
                            <CarouselArrowButton onClick={previousSlide} type="left" backgroundWhite />
                        )}
                        renderCenterRightControls={({ nextSlide }) => (
                            <CarouselArrowButton onClick={nextSlide} type="right" backgroundWhite />
                        )}
                        renderBottomCenterControls={null}
                        dragThreshold={0.1}
                        slidesToShow={4}
                        wrapAround
                        enableKeyboardControls={false}
                        scrollMode="remainder"
                    >
                        {eventUserRegistered.map((value, index) => (
                            <CardCarousel redirectUser={redirectUser} key={`value-${index}`} value={value} />
                        ))}
                    </CarouselContainer>
                </DesktopContainerCarousel>
            )}
        </>
    );
};

CardsCarousel.propTypes = {
    values: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeCompletedCourse: PropTypes.func,
    finishedCourses: PropTypes.bool,
    newCourses: PropTypes.bool,
    label: PropTypes.string,
    errorLabel: PropTypes.string
};

export default CardsCarousel;
