import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ButtonStyled } from './styles';

const ButtonCallAction = ({ children, onClick, disabled, iconSize, Icon, width, startIcon, marginRightIcon, ...props }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    if (props.to) {
        return (
            <ButtonStyled
                variant="contained"
                component={Link}
                onClick={handleClick}
                disabled={disabled}
                startIcon={Icon ? <Icon style={{ width: iconSize, height: iconSize }} /> : startIcon}
                {...props}
            >
                {children}
            </ButtonStyled>
        );
    }

    return (
        <ButtonStyled
            onClick={handleClick}
            startIcon={Icon && <Icon style={{ width: iconSize, height: iconSize, stroke: '#fff' }} />}
            {...props}
            disabled={disabled}
            width={width}
            marginRightIcon={marginRightIcon}
        >
            {children}
        </ButtonStyled>
    );
};

export default ButtonCallAction;

ButtonCallAction.defaultProps = {
    variant: 'contained',
    type: 'button',
    disabled: false
};

ButtonCallAction.propTypes = {
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
    children: PropTypes.any,
    Icon: PropTypes.node,
    disabled: PropTypes.bool,
    iconSize: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
    marginRightIcon: PropTypes.string,
    width: PropTypes.string,
    type: PropTypes.string
};
