import styled from 'styled-components';

import { Button } from '@mui/material';
export const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 12px;
    padding: 5px;
    cursor: pointer;
    color: #294732;
    :hover {
        background: #ebefed;
    }
    :active {
        filter: brightness(0.95);
    }
`;
export const SectionTitle = styled.h2`
    font-weight: bold;
    color: #9ba1a9;
`;

export const ButtonTerciario = styled(Button)`
    text-transform: none;
    height: 32px;
    padding: 0px 16px 0px 16px;
`;
