import useApi from '../api';

const useDashboardActionPlansService = () => {
    const { api } = useApi();

    return {
        getDashboardActionsPlans: (enterpriseId, year) =>
            api.get(`enterprise/${enterpriseId}/get_action_plans_details`, {
                params: {
                    year
                }
            })
    };
};

export default useDashboardActionPlansService;
