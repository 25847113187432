import React from 'react';
import PropTypes from 'prop-types';
import { AccessTimeOutlined } from '@mui/icons-material';
import { Container } from './styles';

const TimerDuration = ({ minutes, text, unit, onlyHours }) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;

    return (
        <Container>
            <aside>
                <AccessTimeOutlined fontSize="small" color="gray" />
            </aside>
            <p>
                {text || 'Duração'} {hours}
                {unit || 'h'}
                {!onlyHours && minutesLeft > 0 && ` ${minutesLeft} min`}
            </p>
        </Container>
    );
};

TimerDuration.propTypes = {
    minutes: PropTypes.number.isRequired,
    text: PropTypes.string,
    unit: PropTypes.string,
    onlyHours: PropTypes.bool
};

export default TimerDuration;
