import styled from 'styled-components';
import { FormControlLabel, FormControl } from '@mui/material';

export const Container = styled.div`
    > header {
        > h2 {
            font-weight: 700;
            font-size: 1.1rem;
            line-height: 130%;
            display: flex;
            align-items: center;
            color: var(--color-primary);
        }

        > p {
            font-weight: 400;
            font-size: 1rem;
            padding: 0.5rem 0;
        }
    }

    > footer {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Form = styled(FormControl)`
    margin-top: 0.5rem;

    * {
        display: flex;
        align-items: flex-start;
    }
`;

export const Option = styled(FormControlLabel)`
    margin-top: 1rem;
    display: flex;
    align-items: center;

    > span {
        font-size: 0.9rem;
    }

    &:first-child {
        margin-top: 0;
    }
`;
