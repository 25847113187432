import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    > h2 {
        font-size: 22px;
        font-weight: 700;
        color: #033025;
        line-height: 28.6px;
    }
`;
export const Notifications = styled.div`
    width: 100%;
    margin: 0 auto;

    > * {
        margin-bottom: 1rem;
    }
`;
