import React from 'react';

import PropTypes from 'prop-types';

import { Radio } from '@mui/material';

import { Container } from './styles';

export const RadioInput = ({ id, item, value, handleBlur, handleChange }) => {
    const { label } = item;

    return <Container onBlur={handleBlur} control={<Radio checked={value === true} onClick={handleChange} />} label={label} name={id} />;
};

RadioInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    value: PropTypes.any,
    id: PropTypes.string
};
