import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Cookies from 'js-cookie';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Grid } from '@mui/material';

import ButtonCallAction from '@/components/BI/buttonCallAction';
import { ReactComponent as PDFIcon } from '@assets/icons/PDFIcon.svg';

import { Container, Tabs, Tab, HeaderStyled, ButtonMenu, HeaderWrapper } from './styles';

const PageHeader = ({ idEnterprise }) => {
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
    const navigate = useNavigate();
    const params = useParams();
    const [currentRole, setCurrentRole] = useState(null);
    const dispatch = useDispatch();
    const { isLoadingReport } = useSelector((state) => state.managerPanelReducer);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const isDemonstration = queryParams.get('demo');

    const tabs =
        currentUserRole === 5
            ? [
                  { key: 'users', label: 'Usuários' },
                  { key: 'performance', label: 'Desempenho' },
                  { key: 'reports', label: 'Relatórios' }
              ]
            : [
                  { key: 'accesses', label: 'Acessos' },
                  { key: 'users', label: 'Usuários' },
                  { key: 'properties', label: 'Propriedades' },
                  { key: 'visits', label: 'Visitas' },
                  { key: 'action_plan', label: 'Plano de Ação' },
                  // { key: 'check_agro', label: 'Check Agro' },
                  { key: 'check_agro', label: 'Check Agro' },

                  { key: 'reports', label: 'Relatórios' },
                  { key: 'performance', label: 'Desempenho' }
                  // { key: 'performance', label: 'Desempenho' }
              ];

    const tabParams = params.tab ?? tabs[0].key;

    const handleClickTab = (clickedTab) => {
        navigate(`/${currentRole}/empresa/geral/${idEnterprise}/${clickedTab}${isDemonstration ? '?demo=1' : ''}`);
    };

    const handleButtonClick = () => {
        dispatch({ type: 'MANAGER_PANEL_REPORT_BUTTON_CLICK', payload: true });
    };

    const handleRule = () => {
        switch (currentUserRole) {
            case 1:
                return setCurrentRole('admin');
            case 6:
                return setCurrentRole('assistente');
            case 4:
                return setCurrentRole('coordenador');
            case 5:
                return setCurrentRole('supervisor');
        }
    };

    useEffect(() => {
        handleRule();
    }, []);

    return (
        <HeaderWrapper>
            <HeaderStyled>
                {tabs.map((tab) => (
                    <ButtonMenu
                        key={tab.key}
                        onClick={() => handleClickTab(tab.key)}
                        style={
                            tab.key !== tabParams
                                ? { borderRadius: '8px 8px 0px 0px', fontSize: '13px', fontWeight: 600 }
                                : {
                                      borderRadius: '8px 8px 0px 0px',
                                      backgroundColor: '#3A6647',
                                      color: '#ffffff',
                                      fontWeight: 600,
                                      fontSize: '14px'
                                  }
                        }
                    >
                        <h3>{tab.label}</h3>
                    </ButtonMenu>
                ))}
            </HeaderStyled>

            <div>
                <ButtonCallAction
                    sx={{ fontSize: 16, fontWeight: 700, padding: '16px' }}
                    onClick={handleButtonClick}
                    loading={isLoadingReport}
                    Icon={PDFIcon}
                >
                    Gerar Relatório
                </ButtonCallAction>
            </div>
        </HeaderWrapper>
    );
};

export default PageHeader;
