import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 1rem;
    border-radius: var(--border-radius);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    background-color: #fff;

    width: 100%;

    > header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 130%;
            color: ${(props) => (props.correct ? '#87D04D' : '#DB5252')};
        }
    }

    > main {
        margin-top: 0.5rem;

        > p {
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: #787885;
        }
    }
`;
