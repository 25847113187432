import { ReactComponent as PorcentageIcon } from '@assets/icons/GraphicPorcentageIcon.svg';

import { LegendContainer, LegendDot, LegendItem } from './style';

export const GraphicLegend = () => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <LegendContainer>
                <LegendItem>
                    <LegendDot color="#62C054" />
                    <span>Fazendas cadastradas</span>
                </LegendItem>
                <LegendItem>
                    <LegendDot color="#55A559" />
                    <span>Fazendas agendadas</span>
                </LegendItem>
                <LegendItem>
                    <LegendDot color="#3A6647" />
                    <span>Fazendas visitadas</span>
                </LegendItem>
                <LegendItem>
                    <PorcentageIcon />
                    <span>% de visitas</span>
                </LegendItem>
            </LegendContainer>
        </div>
    );
};
