import moment from 'moment';

import {
    SET_MILKING_CONTROL_DATA,
    SET_MILKING_CONTROL_FILTER_OPTIONS,
    SET_MILKING_CONTROL_FILTERS,
    SET_MILKING_CONTROL_DATA_LOADING,
    RESET_MILKING_CONTROL_FILTERS,
    SET_MILKING_PROPERTY_ID
} from '@/redux/mutations';

export const initialState = {
    property_id: null,
    data: {},
    loading: false,
    optionsFilters: {
        sectors: ['Todos'],
        bought: ['Todos', 'Sim', 'Não']
    },
    selectedFilters: {
        sectors: ['Todos'],
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
        bought: 'Todos'
    }
};

const indicatorsPanelMilkingControlReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_MILKING_PROPERTY_ID:
            return {
                ...state,
                property_id: actions.payload
            };
        case SET_MILKING_CONTROL_DATA:
            return {
                ...state,
                data: actions.payload
            };
        case SET_MILKING_CONTROL_DATA_LOADING:
            return {
                ...state,
                loading: actions.payload
            };
        case SET_MILKING_CONTROL_FILTER_OPTIONS:
            return {
                ...state,
                optionsFilters: actions.payload
            };
        case SET_MILKING_CONTROL_FILTERS:
            return {
                ...state,
                selectedFilters: actions.payload
            };
        case RESET_MILKING_CONTROL_FILTERS:
            return {
                data: {},
                loading: false,
                optionsFilters: {
                    sectors: ['Todos'],
                    bought: ['Todos', 'Sim', 'Não']
                },
                selectedFilters: {
                    sectors: ['Todos'],
                    startDate: moment().subtract(30, 'days'),
                    endDate: moment(),
                    bought: 'Todos'
                }
            };
        default:
            return state;
    }
};

export default indicatorsPanelMilkingControlReducer;
