import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const FavoriteCheckbox = ({ active, setActive, noPaddingLeft, color }) => {
    return (
        <Container active={active} noPaddingLeft={noPaddingLeft} color={color}>
            <IconButton aria-label="toggle password visibility" onClick={() => setActive(!active)}>
                {active ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
        </Container>
    );
};

FavoriteCheckbox.propTypes = {
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    setActive: PropTypes.func,
    noPaddingLeft: PropTypes.bool,
    color: PropTypes.string
};

export default FavoriteCheckbox;
