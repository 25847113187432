import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Cookies from 'js-cookie';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import SectionTitle from '@/components/atoms/fonts/SectionTitle';
import ButtonBack from '@/components/BI/buttonBack';
import ButtonCallAction from '@/components/BI/buttonCallAction';
import CardsCarousel from '@/components/molecules/carousels/CardsCarousel';
import LoadingComponent from '@components/templates/LoadingComponent';
import Meta from '@components/templates/Seo/Meta';
import useEventService from '@services/event';

import { Header, Container, Main, Card, CarouselContainer, Subtitle } from './styles';

const Events = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState('');
    const user = useSelector((state) => state.auth);
    const { getAllEvents, viewEvent } = useEventService();
    const navigate = useNavigate();
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));

    function redirectUser(id) {
        switch (currentUserRole) {
            case 1:
                navigate(`/admin/encontros-ao-vivo/${id}`);
                break;
            case 6:
                navigate(`/assistente/encontros-ao-vivo/${id}`);
                break;
            case 2:
                navigate(`/consultor/encontros-ao-vivo/${id}`);
                break;
            case 3:
                navigate(`/empresa/encontros-ao-vivo/${id}`);
                break;
            case 4:
                navigate(`/coordenador/encontros-ao-vivo/${id}`);
                break;
            case 5:
                navigate(`/supervisor/encontros-ao-vivo/${id}`);
                break;

            default:
                navigate(`/encontros-ao-vivo/${id}`);
                break;
        }
    }

    const formatDate = (date) => {
        return format(new Date(date), 'dd/MM/yyyy HH:mm', { locale: ptBR });
    };

    useEffect(() => {
        getAllEvents()
            .then((resp) => {
                setEvents(resp.data);
                setIsLoading(false);
            })
            .catch(() => {
                snackbar('Propriedade não encontrada', 'error');
                navigate('/consultor/propriedades');
            });
    }, []);

    if (isLoading) {
        return <LoadingComponent />;
    }
    return (
        <>
            <Meta title="Encontros ao vivo" />

            <ButtonBack />
            <Container>
                <Header>
                    <SectionTitle tituloString={'Encontros ao vivo'} />
                </Header>
                <Subtitle>Eventos inscritos:</Subtitle>
                <CarouselContainer>
                    <CardsCarousel
                        values={events}
                        label={null}
                        errorLabel={null}
                        redirectUser={redirectUser}
                        userViewEvent={(idEvent) => viewEvent(idEvent)}
                    />
                </CarouselContainer>
                <div className="divider" />
                <div style={{ width: '100%' }}>
                    <Subtitle>Todos eventos:</Subtitle>
                </div>

                <Main>
                    {events.map((value, key) => (
                        <Card key={key}>
                            <img src={value.image} alt="imagem-curso" height="35%" style={{ borderRadius: '8px' }} />
                            <p style={{ color: '#373848', fontWeight: 700, fontSize: '16px', marginTop: '16px' }}>{value.title}</p>
                            <p style={{ fontWeight: 600, fontSize: '12px', marginTop: '16px' }}>{value.speaker}</p>
                            <div style={{ height: '48px' }}>
                                <p style={{ fontSize: '12px', marginTop: '5px' }}>
                                    {value.description.length > 152 ? `${value.description.substring(0, 152)}...` : value.description}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: '2rem', gap: '2px', marginBottom: '20px', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                    <AccessTimeIcon fontSize="40px" />
                                    <p style={{ fontWeight: 500, fontSize: '12px' }}>De: {formatDate(value.initial_date)}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                    <AccessTimeIcon fontSize="40px" />
                                    <p style={{ fontWeight: 500, fontSize: '12px' }}>Ate: {formatDate(value.final_date)}</p>
                                </div>
                            </div>
                            <ButtonCallAction
                                height="44px"
                                endIcon={<ChevronRightIcon />}
                                iconSize="24px"
                                buttonColor="#3A6647"
                                onClick={() => {
                                    redirectUser(value.id);
                                }}
                            >
                                Saiba mais
                            </ButtonCallAction>
                        </Card>
                    ))}
                </Main>
            </Container>
        </>
    );
};

export default Events;
