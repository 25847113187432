import styled from 'styled-components';

export const Dropzone = styled.div`
    position: relative;
    width: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: ${(props) => (props.error ? '1px solid #cf322c' : '1px solid #c6c6c6')};
    color: ${(props) => (props.error ? '#cf322c' : '#c6c6c6')};
    font-weight: ${(props) => (props.error ? 'bold' : 'normal')};
    border-radius: 8px;
    background-color: #fbfbfb;
    padding: 10px;
    flex-direction: column;
    gap: 20px;

    &:hover {
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        font-weight: bold;
    }
`;

export const DropzoneText = styled.p`
    width: 100%;
    text-align: center;
`;
export const Files = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    left: 10px;
    flex-wrap: wrap;
`;

export const File = styled.div`
    position: relative;
    width: calc(100% / 6 - 8.5px);
    height: 100px;
    object-fit: contain;
    border: 1px solid #c6c6c6;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
`;

export const FileDeleteButton = styled.button`
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -5px;
    background: #3a6647;
    color: white;
    border: 2px solid #fbfbfb;
    border-radius: 3px;

    &:hover {
        top: -7px;
        right: -7px;
        width: 1.9rem;
        height: 1.9rem;
    }
`;
