import React, { useState } from 'react';

import MultipleQuestion from 'components/molecules/questions/MultipleQuestion';
import PropTypes from 'prop-types';
import { QuizQuestionType } from 'types/components/quiz';

import { Container, QuestionContainer } from './styles';

const Quiz = ({ questions, answers, setUserResponses }) => {
    const handleAnswerQuestion = (index, response, questionId) => {
        setUserResponses(index, response, questionId);
    };
    return (
        <Container>
            <header>
                <h2>{questions.title}</h2>
            </header>
            <main>
                {questions &&
                    questions.asking.map((question, index) => (
                        <QuestionContainer key={index}>
                            <MultipleQuestion
                                questionId={question.id}
                                index={index}
                                alternatives={question.alternatives}
                                question={question.description}
                                previousAnswer={question.alternatives[index]}
                                askingUser={question.asking_user}
                                setResponseTest={handleAnswerQuestion}
                            />
                        </QuestionContainer>
                    ))}
            </main>
        </Container>
    );
};

Quiz.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.shape(QuizQuestionType)).isRequired,
    answers: PropTypes.arrayOf(PropTypes.number),
    answerQuestion: PropTypes.func.isRequired
};

export default Quiz;
