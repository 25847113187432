import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    h3 {
        font-size: 1.75rem;
        color: var(--color-primary);
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
`;

export const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 93%;
    gap: 5%;
    padding-left: 2px;
    margin-left: 7%;
    .divider {
        margin-left: 5%;
        background-color: #cfcfcf;
        height: 2px;
        width: 80%;
    }
`;

export const CarouselContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5%;
    padding-left: 2px;
    margin-left: 3%;
`;

export const Card = styled.div`
    display: flex;
    margin-bottom: 5%;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    height: 420px;
    border-radius: 8px;
    padding: 1%;
    gap: 1%;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.12);
`;

export const Subtitle = styled.h3`
    color: var(--color-primary);
    font-weight: 700;
    text-align: center;
`;
