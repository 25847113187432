import styled from 'styled-components';

import { Alert, Collapse } from '@mui/material';

export const SnackbarCollapse = styled(Collapse)`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
`;
export const SnackbarAlert = styled(Alert)`
    border-radius: 0;
    font-weight: 700;
    font-size: 1rem;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    padding-left: 4.3rem;
    z-index: 999;
`;
