// import BaseModal from '../BaseModal';
import React, { useState } from 'react';

import { format } from 'date-fns';

import useCollaboratorService from '@/services/collaborator';
import noImage from '@assets/images/no_image.jpeg';

import { EmployeeProfileModal } from '../../modals/EmployeeProfileModal';
import { CardActiveConsultants, Container } from './styles';

export const ConsultantsActivesAndInactivesList = ({ data }) => {
    const [profileModal, setProfileModal] = useState(false);
    const [dataProfile, setDataProfile] = useState(null);
    const { getCollaboratorPerId } = useCollaboratorService();
    // const [displayProfile, setDisplayProfile] = useState(null);

    const openProfileModal = async (collaboratorId) => {
        try {
            const response = await getCollaboratorPerId(collaboratorId);
            setDataProfile(response);
            setProfileModal(true);
        } catch (error) {
            console.error('Erro ao buscar colaborador:', error);
        }
    };
    return (
        <Container>
            {data?.map((employee, key) => (
                <CardActiveConsultants key={key} onClick={() => openProfileModal(employee.collaborator_id)} elevation={0}>
                    <img
                        style={{ width: '48px', minWidth: '48px', height: '48px', borderRadius: '50%' }}
                        src={employee.photo != null ? employee.photo : noImage}
                        alt="Consultor"
                    />
                    <div>
                        <span className="name">{employee.name}</span>
                        <div className="footer">
                            <span
                                style={{
                                    backgroundColor:
                                        employee.seniority === 'Junior' || employee.seniority === 'Trainee'
                                            ? '#E1EAE1'
                                            : employee.seniority === 'Pleno'
                                            ? '#EFEFDB'
                                            : employee.seniority === 'Senior'
                                            ? '#EEE7F0'
                                            : ''
                                }}
                            >
                                {employee.seniority}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="last-access">Último acesso: {employee.last_access}</div>
                    </div>
                </CardActiveConsultants>
            ))}
            {profileModal && dataProfile && (
                <EmployeeProfileModal
                    open={profileModal}
                    handleClose={(e) => {
                        e.stopPropagation();
                        setProfileModal(false);
                    }}
                    profile={dataProfile}
                />
            )}
        </Container>
    );
};
