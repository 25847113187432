import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import ButtonCallAction from '@/components/BI/buttonCallAction';
import { EmployeeProfileModal } from '@/components/molecules/modals/EmployeeProfileModal';
import useCollaboratorService from '@/services/collaborator';
import noImage from '@assets/images/no_image.jpeg';

import { Div, Seniority, TextName } from './styles';

const CardConsultant = ({ data }) => {
    const [profileModal, setProfileModal] = useState(false);
    const [dataProfile, setDataProfile] = useState(null);
    const { getCollaboratorPerId } = useCollaboratorService();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const openProfileModal = async (collaboratorId) => {
        try {
            const response = await getCollaboratorPerId(collaboratorId);
            setDataProfile(response);
            setProfileModal(true);
        } catch (error) {
            console.error('Erro ao buscar colaborador:', error);
        }
    };

    const handleViewAs = (consultant) => (evt) => {
        sessionStorage.setItem('consultant_id', consultant.id);
        sessionStorage.setItem('redirect_to_employees_list', location.pathname);
        sessionStorage.setItem('consultant', JSON.stringify(consultant));

        dispatch({ type: 'SET_VIEW_AS_CONSULTANT', payload: true });
        navigate('/consultor');
    };

    return (
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item onClick={() => openProfileModal(data.id)} style={{ cursor: 'pointer' }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <img
                            style={{ width: '56px', minWidth: '56px', height: '56px', borderRadius: '50%' }}
                            src={data.photo != null ? data.photo : noImage}
                            alt="Consultor"
                        />
                    </Grid>
                    <Grid item>
                        <TextName>{data.name}</TextName>
                        <Div>
                            <Seniority
                                style={{
                                    backgroundColor:
                                        data.seniority === 'Junior' || data.seniority === 'Trainee'
                                            ? '#E1EAE1'
                                            : data.seniority === 'Pleno'
                                            ? '#EFEFDB'
                                            : data.seniority === 'Senior'
                                            ? '#EEE7F0'
                                            : ''
                                }}
                            >
                                {data.seniority}
                            </Seniority>
                        </Div>
                    </Grid>
                </Grid>
            </Grid>

            {profileModal && dataProfile && (
                <EmployeeProfileModal
                    open={profileModal}
                    handleClose={(e) => {
                        e.stopPropagation();
                        setProfileModal(false);
                    }}
                    profile={dataProfile}
                />
            )}

            <Grid item>
                <ButtonCallAction onClick={handleViewAs(data)} height="30px" buttonColor="#3A6647">
                    Acessar Plataforma do Consultor
                </ButtonCallAction>
            </Grid>
        </Grid>
    );
};

export default CardConsultant;
