import useApi from '../api';

const useEnterpriseEmployeesService = () => {
    const { api } = useApi();

    return {
        getConsultantEnterprise: (id) => api.get(`enterprise/${id}/employees/consultant`).then((resp) => resp.data),
        addConsultantProperty: (payload) =>
            api
                .post('collaborator_property', payload, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .then((resp) => resp.data),
        removeConsultantProperty: (id) => api.delete(`collaborator_property/${id}`).then((resp) => resp.data),
        changeHolderConsultant: (payload) =>
            api
                .post('collaborator_property_primary', payload, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .then((resp) => resp.data),

        getConsultantProperties: (id, withReports = false) =>
            api.get(`collaborator-properties/${id}`, withReports && { params: { withReports } }),

        getCollaboratorPerformance: (id, date) => api.get(`/enterprise/collaborator_performance/${id}?date=${date}`)
    };
};

export default useEnterpriseEmployeesService;
