import {
    DASHBOARD_ACTIVE_USER,
    DASHBOARD_CLOSE_RIGHT_SIDEBAR,
    DASHBOARD_CLOSE_SETTINGS_MODAL,
    DASHBOARD_NAVBAR_SHOW,
    DASHBOARD_OPEN_RIGHT_SIDEBAR,
    DASHBOARD_OPEN_SETTINGS_MODAL,
    DASHBOARD_SET_LEFT_SIDEBAR_EXPANDED,
    DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT,
    SET_SEARCH
} from '@/redux/mutations';

const initialState = {
    leftSidebarExpanded: false,
    rightSidebar: false,
    rightSidebarContent: <></>,
    navbar: {
        searchBox: false
    },
    settingsModal: false,
    activeUser: [],
    search: ''
};
const dashboardReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_SEARCH:
            return { ...state, search: actions.payload };
        case DASHBOARD_SET_LEFT_SIDEBAR_EXPANDED:
            return { ...state, leftSidebarExpanded: actions.payload };
        case DASHBOARD_OPEN_RIGHT_SIDEBAR:
            return { ...state, rightSidebar: true };
        case DASHBOARD_CLOSE_RIGHT_SIDEBAR:
            return { ...state, rightSidebar: false };
        case DASHBOARD_OPEN_SETTINGS_MODAL:
            return { ...state, settingsModal: true };
        case DASHBOARD_CLOSE_SETTINGS_MODAL:
            return { ...state, settingsModal: false };
        case DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT:
            return { ...state, rightSidebarContent: actions.payload };
        //NAVBAR
        case DASHBOARD_NAVBAR_SHOW:
            return { ...state, navbar: { ...state.navbar, searchBox: actions.payload } };
        case DASHBOARD_ACTIVE_USER:
            return { ...state, activeUser: actions.payload };
        default:
            return state;
    }
};
export default dashboardReducer;
