import { ptBR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

export const theme = () => {
    const themeOptions = {
        typography: {
            fontFamily: 'Myriad Pro'
        },
        palette: {
            type: 'light',
            primary: {
                main: '#3A6647' //'#0A432D'
            },
            secondary: {
                main: '#6B9D4A'
            },
            neutral: {
                main: '#87A851'
            },
            light: {
                main: '#E2EDD0',
                primary: '#0A432D'
            },
            gray: {
                main: '#A1A1A7'
            },
            lightGray: {
                main: '#E8E8E8'
            },
            orange: {
                main: '#DF9B34'
            },
            purple: {
                main: '#6967C1'
            },
            aqua: {
                main: '#8DC0BA'
            },
            pink: {
                main: '#B15FA4'
            },
            blue: {
                main: '#73A4F7'
            },
            red: {
                main: '#D35E4B'
            },
            darkOrange: {
                main: '#ED9F4A'
            },
            green: {
                main: '#62C054'
            },
            lightPurple: {
                main: '#7E5FB1'
            },
            white: {
                main: '#FFFFFF'
            }
        },
        shape: {
            borderRadius: 8
        },
        ptBR
    };

    const themes = createTheme(themeOptions);

    return themes;
};

export default theme;
