export const initialState = {
    notifications: null,
    empty: null
};

const notificationReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_NOTIFICATIONS':
            return {
                ...state,
                notifications: actions.payload
            };
        case 'SET_EMPTY':
            return {
                ...state,
                empty: actions.payload
            };
        default:
            return state;
    }
};

export default notificationReducer;
