import { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import ButtonCallAction from '@/components/BI/buttonCallAction';

import Logo from '../../../components/atoms/svgs/Logo';
import Meta from '../../../components/templates/Seo/Meta';
import { Container } from './styles';

const Error404 = () => {
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState('');
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
    const consultantId = sessionStorage.getItem('consultant_id') ?? null;

    useEffect(() => {
        handleRedirect();
    }, []);

    const handleRedirect = () => {
        if (!currentUserRole) {
            navigate('/login');
            return;
        }

        if (consultantId) {
            setRedirect('/consultor');
            return;
        }
        const profile = {
            1: 'admin',
            2: 'consultor',
            3: 'empresa',
            4: 'coordenador',
            5: 'supervisor'
        };

        switch (currentUserRole) {
            case 2: {
                setRedirect(`/${profile[currentUserRole]}`);
                break;
            }
            case 1:
            case 4:
            case 5: {
                setRedirect(`/${profile[currentUserRole]}/painel`);
                break;
            }
        }
    };
    return (
        <Container>
            <Meta title={'404'} />
            <Logo />
            <h1>404</h1>
            <p>A página solicitada não existe</p>
            {/* <Link component={ReactLink} to={redirect != '' ? redirect : '/'} sx={{ textDecoration: 'none', fontWeight: 600 }}>
                Voltar para o Início
            </Link> */}

            <ButtonCallAction component={ReactLink} to={redirect != '' ? redirect : '/'} buttonColor="#3A6647">
                Voltar para o Início
            </ButtonCallAction>
        </Container>
    );
};
export default Error404;
