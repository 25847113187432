import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 65px;
    filter: drop-shadow(0px 1px 8px rgba(81, 69, 159, 0.05));
    position: absolute;
    transition: all 0.2s;
    padding: 0 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c1c1c1;

    /* z-index: 1; */
    > div {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }
    > .logotipo img {
        height: 36px;
    }

    @media (max-width: 767px) {
        position: relative;
    }
`;
export const UserAvatar = styled.img`
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: contain;
    box-shadow: 0 0 1px black;
`;

export const Title = styled.h3`
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #294732;

    border-left: 1 solid #000;
`;

export const Pipe = styled.div`
    height: 36px;
    border: 1px solid #dbe2df;
`;
