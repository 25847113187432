import styled from 'styled-components';

export const Container = styled.div`
    width: ${(props) => (props.fullSize ? '100vw' : '100%')};
    height: ${(props) => (props.fullSize ? '100vh' : '100%')};
    margin-top: ${(props) => props.marginTop};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-secondary);
    gap: 16px;
`;
