import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import { MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';

import consultant from '@/routes/paths/consultant';
import useEnterpriseEmployeesService from '@/services/enterprise-employees';
import ButtonCallAction from '@components/BI/buttonCallAction';
import GraphicProgressBar from '@components/BI/GraphicProgressBar';
import InfoList from '@components/BI/InfoList';

import { ActionsPlansProgressTitle, Container, ProgressContainer } from './EmployeePerformanceStyle';

const EmployeePerformance = ({ collaborator }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { getCollaboratorPerformance } = useEnterpriseEmployeesService();

    const [currentMonth, setCurrentMonth] = useState('geral');

    const [meses, setMeses] = useState([
        {
            label: 'Geral',
            value: 'geral'
        }
    ]);

    const [perfomanceData, setPerformanceData] = useState({});
    const [progressData, setProgressData] = useState([]);

    const [infoListData, setInfoListData] = useState([
        [
            {
                title: 'Total de Propriedades:',
                value: '--'
            }
        ],
        [
            {
                title: 'Total geral de visitas agendadas:',
                value: '--'
            },
            {
                title: 'Relatórios entregues (aprovados):',
                value: '--'
            }
        ],
        [
            {
                title: 'Total de ações ativas:',
                value: '--'
            }
        ],
        [
            {
                title: 'Total de ações arquivadas:',
                value: '--'
            }
        ]
    ]);

    const handleViewAs = (consultant) => (evt) => {
        sessionStorage.setItem('consultant_id', consultant.id);
        sessionStorage.setItem('consultant', JSON.stringify(consultant));
        dispatch({ type: 'SET_VIEW_AS_CONSULTANT', payload: true });
        navigate('/consultor');
    };

    const getPerformanceData = async () => {
        // setPerformanceData({});
        // setInfoListData([]);
        // setProgressData([]);
        try {
            const date = currentMonth !== 'geral' ? moment(currentMonth).format() : null;
            const { data } = await getCollaboratorPerformance(collaborator.id, date);

            setPerformanceData(data);

            const infoList = [
                [
                    {
                        title: 'Total de Propriedades:',
                        value: data.qtdCollaboratorProperty ?? 0
                    }
                ],
                [
                    {
                        title: 'Total geral de visitas agendadas:',
                        value: data.qtdVisits ?? 0
                    },
                    {
                        title: 'Relatórios entregues (aprovados):',
                        value: data.qtdReportsApproved ?? 0
                    }
                ],
                [
                    {
                        title: 'Total de ações ativas:',
                        value: data.actionPlansActions ?? 0
                    }
                ],
                [
                    {
                        title: 'Total de ações arquivadas:',
                        value: data.actionPlansAndCountActionsArchived ?? 0
                    }
                ]
            ];

            setInfoListData(infoList);

            const progress = data.actionPlansAndCountActions.map((item) => ({
                title: item.area_name,
                subtitle: 'ações',
                progress: item.actions_count
            }));

            setProgressData(progress);
        } catch (error) {
            console.log(error);
        }
    };

    const setCollaboratorMonths = () => {
        const result = [
            {
                label: 'Geral',
                value: 'geral'
            }
        ];

        let currentDate = new Date();
        const endDate = new Date(collaborator.created_at);

        while (currentDate >= endDate) {
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear().toString();
            const value = `${month}-01-${year}`;

            result.push({
                label: moment(value).format('MMMM YY'),
                value
            });

            // set to next month
            currentDate.setMonth(currentDate.getMonth() - 1);
        }

        setMeses(result);
        // setCurrentMonth(result[0].value);
    };

    useEffect(() => {
        if (collaborator && collaborator.id) {
            setCollaboratorMonths();
            getPerformanceData();
        }
    }, [collaborator]);

    useEffect(() => {
        if (currentMonth) {
            getPerformanceData();
        }
    }, [currentMonth]);

    return (
        <Container>
            <Grid container spacing={'4rem'}>
                <Grid item xs={12} md={5}>
                    <TextField
                        className="collaborator-performance-select"
                        select
                        size={'small'}
                        fullWidth
                        placeholder={'Selecione'}
                        value={currentMonth}
                        onChange={(evt) => setCurrentMonth(evt.target.value)}
                    >
                        {meses.map((mes, key) => (
                            <MenuItem className="collaborator-performance-select-item" key={`mes-${key}`} value={mes.value}>
                                {mes.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={7} display={'flex'} justifyContent={'flex-end'}></Grid>
            </Grid>
            <Grid container spacing={'4rem'}>
                <Grid item xs={12} md={5} display={'flex'} flexDirection={'column'} gap={'2rem'}>
                    {infoListData.map((data, key) => (
                        <InfoList key={`infoList-${key}`} data={data} />
                    ))}
                </Grid>
                <Grid item xs={12} md={7}>
                    <ProgressContainer>
                        <GraphicProgressBar
                            title={
                                <ActionsPlansProgressTitle>
                                    <span>
                                        <b>Plano de Ação:</b>
                                    </span>
                                    <div className="created-actions">
                                        <b>Total de ações ativas</b>
                                        <span>{perfomanceData.actionPlansActions}</span>
                                    </div>
                                </ActionsPlansProgressTitle>
                            }
                            data={progressData}
                            total={perfomanceData.actionPlansActions}
                        />
                    </ProgressContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

EmployeePerformance.propTypes = {
    collaborator: PropTypes.object
};

export default EmployeePerformance;
