export const initialState = {
    steps: null,
    currentStep: 0,
    isOpen: false,
    currentTour: null
};

const tourReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_STEPS':
            return {
                ...state,
                steps: actions.payload
            };
        case 'SET_CURRENTSTEP':
            return {
                ...state,
                currentStep: actions.payload
            };
        case 'SET_ISOPEN':
            return {
                ...state,
                isOpen: actions.payload
            };
        case 'SET_CURRENT_TOUR':
            return {
                ...state,
                currentTour: actions.payload
            };
        default:
            return state;
    }
};

export default tourReducer;
