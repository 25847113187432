import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowBackSmall } from '@assets/icons/Arrow_back.svg';

import { ButtonStyled } from './styles';

const ButtonBack = () => {
    const navigate = useNavigate();

    return (
        <>
            <ButtonStyled startIcon={<ArrowBackSmall />} variant="outlined" onClick={() => navigate(-1)}>
                Voltar
            </ButtonStyled>
        </>
    );
};

export default ButtonBack;
