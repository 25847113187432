import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ButtonBack from '@/components/BI/buttonBack';
import EventPerId from '@/components/organisms/containers/EventPerId';
import LoadingComponent from '@components/templates/LoadingComponent';
import Meta from '@components/templates/Seo/Meta';
import useEventService from '@services/event';

import { Container } from './styles';

const ViewEvent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [event, setEvent] = useState('');
    const { getEventPerId } = useEventService();
    const { id } = useParams();

    useEffect(() => {
        getEventPerId(id)
            .then((resp) => {
                setEvent(resp.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <>
            <Meta title="Encontros ao vivo" />

            <ButtonBack />
            <Container>
                <EventPerId event={event} />
            </Container>
        </>
    );
};

export default ViewEvent;
