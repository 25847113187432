import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NotificationsList from 'components/organisms/lists/NotificationsList';

import SectionTitle from '@/components/atoms/fonts/SectionTitle';
import ButtonBack from '@/components/BI/buttonBack';
import Meta from '@components/templates/Seo/Meta';
import useNotificationService from '@services/notification';

import { Header, Container, Main } from './styles';

const Notifications = () => {
    const dispatch = useDispatch();
    const { getAllNotificationsUser, removeNotificationUser } = useNotificationService();

    const [notifications, setNotifications] = useState('');
    function getAllNotifications() {
        getAllNotificationsUser().then((resp) => {
            dispatch({ type: 'SET_NOTIFICATIONS', payload: resp.data });
            resp.data.length === 0 && dispatch({ type: 'SET_EMPTY', payload: true });
            setNotifications(resp.data);
        });
    }

    function removeNotification(idNotification) {
        removeNotificationUser(idNotification).then(() => {
            getAllNotifications();
        });
    }

    useEffect(() => {
        getAllNotifications();
    }, []);

    return (
        <>
            <Meta title="Notificações" />

            <ButtonBack />
            <Container>
                <Header>
                    <SectionTitle tituloString={'Notificações'} />
                </Header>
                <Main>
                    <NotificationsList notifications={notifications} removeNotification={removeNotification} />
                </Main>
            </Container>
        </>
    );
};

export default Notifications;
