import styled from 'styled-components';

export const Container = styled.div`
    border-radius: 8px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding-base);
    text-align: center;
    color: var(--color-primary);
`;
