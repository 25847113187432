import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SafeImg from '@components/atoms/misc/SafeImg';
import ButtonCallAction from '@components/BI/buttonCallAction';

import { Container } from './styles';

const LEVELS = {
    Trainee: 'Trainee',
    Junior: 'Júnior',
    Pleno: 'Pleno',
    Senior: 'Sênior'
};
const ConsultantViewDrawer = () => {
    const consultantId = sessionStorage.getItem('consultant_id') ?? null;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const consultant = JSON.parse(sessionStorage.getItem('consultant')) ?? {};
    const navigate = useNavigate();
    const handleExitViewAs = () => {
        const redirectToEmployeesList = sessionStorage.getItem('redirect_to_employees_list');

        sessionStorage.removeItem('consultant_id');
        sessionStorage.removeItem('consultant');
        dispatch({ type: 'SET_VIEW_AS_CONSULTANT', payload: false });

        user.roles.includes(1) ? navigate(redirectToEmployeesList || '/admin/painel') : navigate(redirectToEmployeesList || '/');
    };

    return consultantId ? (
        <Container>
            <div className={'info'}>
                <div className={'image'}>
                    <SafeImg src={consultant.image ?? ''} alt={'Imagem do Consultor'} />
                </div>
                <div className={'data'}>
                    <b>{consultant.name ?? '--'}</b>
                    <div
                        className={`level ${
                            consultant.roles[0]
                                ? String(consultant.seniority)
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                : ''
                        }`}
                    >
                        <span>{LEVELS[consultant.seniority]}</span>
                    </div>
                </div>
            </div>
            <ButtonCallAction variant={'outlined'} onClick={handleExitViewAs}>
                <b>Fechar</b>
            </ButtonCallAction>
        </Container>
    ) : (
        <></>
    );
};

export default ConsultantViewDrawer;
