import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
`;

export const ContainerTitle = styled.div`
    color: #25272e;
    font-size: 1.25rem;
    line-height: normal;
`;
export const GraphicItem = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const GraphicItemTitle = styled.div`
    color: #25272e;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
export const GraphicItemSubTitle = styled.div`
    color: #25272e;
    font-size: 0.9rem;
    font-style: normal;
    display: flex;
    gap: 5px;
    align-items: center;

    span {
        font-size: 1.1rem;
        font-weight: 700;
    }
`;

export const ProgressBarLabel = styled.div`
    position: absolute;
    bottom: 10px;
    width: ${(props) => props.width}%;

    .label {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
`;
