import { BASEURL } from 'baseURL/baseURLExample';

const padNumber = (number, length = 2) => {
    const string = String(number);
    return string.length < length ? padNumber('0' + string, length) : string;
};

const cleanAccents = (string) => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const chunkArray = (array, chunkSize) => {
    const tempArray = [];
    let myChunk;

    for (let index = 0; index < array.length; index += chunkSize) {
        myChunk = array.slice(index, index + chunkSize);
        tempArray.push(myChunk);
    }

    return tempArray;
};

const downloadFile = (filename, filepath) => {
    const url = filename === 'url' ? `${BASEURL}/get_file_extern?link=${filepath}` : `${BASEURL}/get_file/${filename}`;

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.click();
};

const getFileExtension = (fileName) => {
    if (!fileName) {
        return null;
    }

    if (['pdf', 'url'].includes(fileName)) {
        return null;
    }

    const fileNameArray = fileName?.split('.');
    return fileNameArray[fileNameArray.length - 1];
};

const getFallbackUrl = (url, returnName) => {
    const path = window.location.pathname;

    return `${url}${`?returnUrl=${path}${returnName ? `&returnName=${returnName}` : ''}`}`;
};

const getIOS = () => {
    return typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export { padNumber, chunkArray, cleanAccents, downloadFile, getFileExtension, getFallbackUrl, getIOS };
