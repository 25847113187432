import { Box, LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
    width: 100%;
    padding-right: 0;
    border-radius: 50px;
    display: flex;
    align-items: center;
`;

export const LineContainer = styled(Box)`
    width: 100%;
    margin-right: 8px;
`;

export const LabelContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
`;

export const Line = styled(LinearProgress)`
    border-radius: 50px;
    height: ${(props) => props.height};
    position: relative;

    .MuiLinearProgress-bar {
        border-radius: 50px;
    }

    .MuiLinearProgress-determinate {
        background-color: #ddd !important;
    }

    .MuiLinearProgress-root {
        background-color: #ddd !important;
    }

    > div {
        border-radius: 50px;
    }
`;
