import React from 'react';

import SignupLayout from '@/components/templates/layouts/Components/SignupLayout';
import FormSignUp from '@components/organisms/forms/Auth/FormSignUp';
import Meta from '@components/templates/Seo/Meta';

const Cadastro = () => {
    return (
        <>
            <Meta title="Cadastre-se" description="Faça seu cadastro na Plataforma Consultores" />

            <SignupLayout>
                <FormSignUp />
            </SignupLayout>
        </>
    );
};

export default Cadastro;
