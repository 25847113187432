import { useDispatch } from 'react-redux';

const useFeedback = () => {
    const dispatch = useDispatch();

    const closeFeedback = () => {
        dispatch({ type: 'REMOVE_FEEDBACK' });
    };

    const finishCourse = (points) => {
        dispatch({
            type: 'ADD_FEEDBACK',
            payload: {
                type: 'finishCourse',
                points
            }
        });
    };

    const finishEvent = (points) => {
        dispatch({
            type: 'ADD_FEEDBACK',
            payload: {
                type: 'finishEvent',
                points
            }
        });
    };

    const levelUpCompetences = (competences) => {
        dispatch({
            type: 'ADD_FEEDBACK',
            payload: {
                type: 'levelUpCompetences',
                competences
            }
        });
    };

    const levelUp = (level) => {
        dispatch({
            type: 'ADD_FEEDBACK',
            payload: {
                type: 'levelUp',
                level
            }
        });
    };

    const finishLibrary = (points) => {
        dispatch({
            type: 'ADD_FEEDBACK',
            payload: {
                type: 'finishLibrary',
                points
            }
        });
    };

    const toLevelUp = (level, currentLevel) => {
        dispatch({
            type: 'ADD_FEEDBACK',
            payload: {
                type: 'toLevelUp',
                level,
                currentLevel
            }
        });
    };

    const satisfactionSearch = (quiz) => {
        dispatch({
            type: 'ADD_FEEDBACK',
            payload: {
                type: 'satisfactionSearch',
                quiz
            }
        });
    };

    return { finishCourse, finishEvent, levelUpCompetences, levelUp, toLevelUp, closeFeedback, finishLibrary, satisfactionSearch };
};

export default useFeedback;
