export const initialState = {
    open: false,
    alertTitle: 'Atenção',
    buttonText: 'Confirmar',
    title: '',
    description: '',
    onClick: () => null,
    withCancel: false
};

const alertReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'OPEN_ALERT':
            return {
                ...state,
                open: true,
                title: actions.payload.title,
                description: actions.payload.description,
                onClick: actions.payload.onClick,
                withCancel: actions.payload.withCancel,
                content: actions.payload.content,
                buttonText: actions.payload.buttonText || 'Confirmar',
                alertTitle: actions.payload.alertTitle,
                withActions: actions.payload.withActions
            };
        case 'CLOSE_ALERT':
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
};

export default alertReducer;
