import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;

    .textInformation {
        text-align: center;
        font-size: 16px;
    }
`;
