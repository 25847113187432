import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Backdrop = ({ handler, active }) => {
    const [startShow, setStartShow] = useState(false);

    useEffect(() => {
        if (!active) {
            setStartShow(false);
            return;
        }

        setStartShow(true);
    }, [active]);

    if (!active) return null;

    return <Container active={startShow} onClick={handler} />;
};

Backdrop.propTypes = {
    handler: PropTypes.func,
    active: PropTypes.bool
};

export default Backdrop;
