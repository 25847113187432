import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';

import { formikFormData } from '@/utils/helpers/formikFormData';
import BaseModal from '@components/molecules/modals/BaseModal';
import useSnackbar from '@hooks/useSnackbar';
import useAuthService from '@services/auth';
import useUserService from '@services/user';

import { ButtonTerciario } from './styles';

const WelcomeModal = ({ handleClose, open, imageUrl = null, fromScreen = null }) => {
    const navigate = useNavigate();
    const userService = useUserService();
    const snackbar = useSnackbar();
    const user = useSelector(({ auth }) => auth.user);
    const authService = useAuthService();
    const currentUserRole = Number(Cookies.get('currentUserRole'));
    const handleCloseModal = () => {
        handleClose();
        userService
            .updateProfileInfo(
                formikFormData({
                    id: user.id,
                    role: fromScreen === 'homeAdm' ? currentUserRole : 2,
                    welcome: 1,
                    _method: 'put'
                })
            )
            .then((resp) => {
                authService.setLogin(resp.user);
            });
    };
    return (
        <BaseModal handleClose={handleCloseModal} open={open} title={'Bem Vindo !'} md mdSize="58%">
            <Grid item xs={12}>
                <div style={{ padding: '24px', gap: '24px', textAlign: 'center' }}>
                    <div style={{ padding: '50% 0 0 0', position: 'relative' }}>
                        <iframe
                            title="welcome"
                            src={
                                fromScreen === 'homeAdm'
                                    ? 'https://www.youtube.com/embed/829vsRu3ctQ'
                                    : 'https://www.youtube.com/embed/7kZvsO0rOG0'
                            }
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                    <h1 style={{ fontSize: '1.5rem', margin: '1rem 1rem' }}>Quer saber mais? Acesse o nosso Saber Mais</h1>
                    <ButtonTerciario
                        variant="contained"
                        onClick={() => navigate(`/consultor/glossario`)}
                        endIcon={<ChevronRightIcon fontSize="large" />}
                    >
                        Saber Mais
                    </ButtonTerciario>
                    <script src="https://player.vimeo.com/api/player.js" />
                </div>
            </Grid>
        </BaseModal>
    );
};

export default WelcomeModal;
WelcomeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    imageUrl: PropTypes.string
};
