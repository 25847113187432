import React, { useState } from 'react';

import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import ClassesMenu from 'components/organisms/menus/ClassesMenu';
import PropTypes from 'prop-types';
import { ClassType } from 'types/data/course';

const WatchHeader = ({
    title,
    classes,
    favorite,
    handleFavorite,
    progress,
    activeClassNumber,
    maxClassNumber,
    changeClass,
    finished,
    courseUser
}) => {
    const [classesMenu, setClassesMenu] = useState(false);
    const handleClass = (value) => {
        setClassesMenu(false);
        changeClass(value);
    };
    return (
        <FullWidthContainer background="#fff" marginTop="0 !important" contentMaxWidth={1200}>
            <ClassesMenu
                active={classesMenu}
                handler={() => setClassesMenu(false)}
                classes={classes}
                progress={progress}
                currentClassNumber={activeClassNumber}
                maxClassNumber={maxClassNumber}
                changeClass={handleClass}
                finished={finished}
                courseUser={courseUser}
            />
        </FullWidthContainer>
    );
};

WatchHeader.propTypes = {
    title: PropTypes.string.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    favorite: PropTypes.bool,
    progress: PropTypes.number.isRequired,
    activeClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    changeClass: PropTypes.func.isRequired,
    finished: PropTypes.bool.isRequired,
    handleFavorite: PropTypes.func.isRequired
};

export default WatchHeader;
