/* eslint-disable import-helpers/order-imports */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';

import useSnackbar from 'hooks/useSnackbar';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import ButtonCallAction from '@/components/BI/buttonCallAction';
import NavigationScroll from '@components/templates/layouts/Components/NavigationScroll';
import LoadingComponent from '@components/templates/LoadingComponent';
import { TourProvider } from '@reactour/tour';
import useOnboardService from '@services/useOnboardService';

import GlobalStyles from './styles/global';
import theme from './styles/theme';

import './styles.css';
import useThemeRoutes from './routes';
import useAuthService from './services/auth';

// import config from './config';

const App = () => {
    const env = process.env;
    ReactGA.initialize(`${env.REACT_APP_GOOGLE_ANALYTICS}`);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const onboardService = useOnboardService();
    const snackbar = useSnackbar();
    const { currentTour } = useSelector((state) => state.tour);
    const { setLogin } = useAuthService();

    const { isLoading, routes } = useThemeRoutes();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    }, []);

    const tourOptions = {
        // showDots: false,
        showBadge: false
    };

    const handleCloseTour = () => {
        if (!user.onboard?.includes(currentTour)) {
            onboardService.register(currentTour).then((resp) => {
                setLogin({ ...user, onboard: resp });
                snackbar('Tutorial finalizado com sucesso!', 'success');
                dispatch({ type: 'SET_CURRENT_TOUR', payload: null });
            });
        }
    };

    const handleClose = () => {
        dispatch({ type: 'SET_CURRENT_TOUR', payload: null });
    };

    function Close({ onClick }) {
        return (
            <ButtonCallAction onClick={onClick} className="reactour__close-button" height="30px" buttonColor="#3A6647">
                Finalizar Tutorial
            </ButtonCallAction>
        );
    }

    return (
        <TourProvider
            {...tourOptions}
            disableInteraction
            onClickClose={({ setIsOpen }) => {
                handleCloseTour();
                setIsOpen(false);
            }}
            beforeClose={handleClose}
            disableKeyboardNavigation
            components={{ Close }}
        >
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme()}>
                    <CssBaseline />
                    <GlobalStyles />
                    <NavigationScroll>{isLoading ? <LoadingComponent fullSize /> : routes}</NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </TourProvider>
    );
};

export default App;
