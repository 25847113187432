import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import BaseModal from '../BaseModal';
import { Container } from './styles';

export const AlertModal = ({
    open,
    handleClose,
    alertTitle,
    title,
    description,
    content,
    onClick,
    buttonText,
    withCancel,
    useHook,
    withActions
}) => {
    const dispatch = useDispatch();
    const alert = useSelector((state) => state.alert);

    if (useHook) {
        handleClose = () => {
            dispatch({ type: 'CLOSE_ALERT' });
        };

        open = alert.open;
        alertTitle = alert.alertTitle;
        title = alert.title;
        description = alert.description;
        onClick = alert.onClick;
        buttonText = alert.buttonText;
        withCancel = alert.withCancel;
        content = alert.content || undefined;
        withActions = alert.withActions || undefined;
    }

    const handleConfirm = () => {
        if (typeof onClick !== 'undefined') {
            onClick();
        }

        handleClose();
    };

    return (
        <BaseModal title={title} open={open} handleClose={handleClose} maxWidth="sm" withActions={withActions}>
            <Container>
                <>
                    {!content ? (
                        <>
                            {/* {<h3>{title}</h3>} */}
                            <p>{description}</p>

                            <div className="actions">
                                {withCancel && (
                                    <Button variant={'outlined'} onClick={handleClose}>
                                        <b>Cancelar</b>
                                    </Button>
                                )}
                                <Button variant={'contained'} onClick={handleConfirm}>
                                    <b>{buttonText}</b>
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>{content}</>
                    )}
                </>
            </Container>
        </BaseModal>
    );
};

AlertModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    alertTitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.elementType,
    withActions: PropTypes.elementType,
    withCancel: PropTypes.bool,
    useHook: PropTypes.bool
};
