import jsCookie from 'js-cookie';

const removeCookie = (value) => {
    document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;

    jsCookie.remove(value, {
        path: '/'
    });
};

export { removeCookie };
