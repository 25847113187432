import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { random, sortBy } from 'lodash';
import moment from 'moment';

import { ALL_MONTHS, MONTH_TRANSLATIONS } from '@/constants/date';
import useDashboardCheckAgroService from '@/services/dashboard-check-agro';

export const useCheckAgro = (enterprise_id) => {
    const [graphBarData, setGraphBarData] = useState([]);
    const [sectorsAverage, setSectorsAverage] = useState([]);
    const [years, setYears] = useState([]);
    const [totalPerYear, setTotalPerYear] = useState(0);
    const [totalConsolidated, setTotalConsolidated] = useState(0);

    const auth = useSelector((state) => state.auth);

    const { getDashboardCheckAgro } = useDashboardCheckAgroService();

    const [filterPer, setFilterPer] = useState('');

    const selectRef = useRef();

    const goToCheckAgroSectorDetails = (sector) => {
        console.log(sector, auth);
    };

    function handleGraphBarData(mounthsCount) {
        const formattedData = [['Mês', 'Quantidade', { role: 'annotation' }]];
        ALL_MONTHS.forEach((month) => {
            const translatedMonth = MONTH_TRANSLATIONS[month];
            const find = mounthsCount.find((d) => d.month === month);
            const quantity = find ? parseInt(find.count, 10) : 0;
            const annotation = quantity;
            formattedData.push([translatedMonth, quantity, annotation]);
        });

        setGraphBarData(formattedData);

        const sectors = [];

        for (let i = 20; i > 0; i--) {
            sectors.push({
                title: 'Sector ' + i,
                progress: random(40, 100)
            });
        }
    }

    const handleFilter = (value) => {
        setFilterPer(value);
        handleDashboard(value);
    };

    const handleSectorsAverageSort = (items) => {
        const required = items.filter((item) => item.type === 'required') ?? [];
        const secondaries = items.filter((item) => item.type === 'secondary') ?? [];
        const optionals = items.filter((item) => item.type === 'optional') ?? [];

        setSectorsAverage([...required, ...secondaries, ...optionals]);
    };

    const handleDashboard = async (filter = '') => {
        const { data } = await getDashboardCheckAgro(enterprise_id, filter);

        const yearsSelect = data.yearsFilter.map((y) => String(y));

        const currentYear = new Date().getFullYear();

        setYears(yearsSelect ?? [currentYear]);

        if (!filterPer.length) {
            setFilterPer(yearsSelect[0] ?? currentYear);
        }

        setTotalPerYear(data.totalByYear ?? 0);
        setTotalConsolidated(data.totalConsolidated ?? 0);

        if (data && data.countEnterpriseCheckAgroMonthYear.length) {
            handleGraphBarData(data.countEnterpriseCheckAgroMonthYear);
        }

        if (data && data.sectorsAverage.length) {
            handleSectorsAverageSort(data.sectorsAverage);
        }
    };

    useEffect(() => {
        handleDashboard();
    }, [enterprise_id]);

    return {
        selectRef,
        filterPer,
        handleFilter,
        goToCheckAgroSectorDetails,
        totalPerYear,
        totalConsolidated,
        graphBarData,
        sectorsAverage,
        years
    };
};
