import styled from 'styled-components';

import { InputLabel } from '@mui/material';

export const Label = styled(InputLabel)`
    font-weight: 600;
    font-size: 16px;
    color: #1c1c1c;
    margin-bottom: 0.5rem;
`;

export const ButtonsGroup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
`;
