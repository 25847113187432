import styled from 'styled-components';

export const Container = styled.div`
    background-color: #fff;
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    margin: 0;
    margin-left: ${(props) => (props.active ? '0' : '-100vw')};
    transition: var(--transition);
    z-index: 7;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 15;

    > header {
        padding: 2rem 1rem 1rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #333;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 5;

        > h2 {
            font-size: 1.4rem;
            color: var(--color-text);
        }

        > button {
            color: #000;
            padding: 0;
            margin: 0;
        }
    }

    > main {
        min-height: calc(100vh - 160px);
        overflow: auto;

        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.4rem;
            height: 0.4rem;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #bbb;
        }

        ::-webkit-scrollbar-thumb:hover {
            border-radius: 10px;
            background: #aaa;
        }
    }

    @media screen and (min-width: 768px) {
        width: 600px;
        margin-left: ${(props) => (props.active ? '0' : '-600px')};
    }
`;

export const MyProgress = styled.div`
    width: 100%;
    padding: 1rem;
    background-color: #eee;

    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;

    > * {
        align-self: center;
    }

    > p {
        font-size: 1rem;
        color: var(--color-text);
        font-weight: bold;
    }
`;
