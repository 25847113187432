import React from 'react';

import Cadastro from '@/pages/Auth/Cadastro';
import BlankLayout from '@components/templates/layouts/Outlet/BlankLayout';

const Login = React.lazy(() => import('pages/Auth/Login'));
const RecoverPassword = React.lazy(() => import('pages/Auth/RecoverPassword'));

const AuthenticationRoutes = {
    path: '/',
    element: <BlankLayout noPadding={true} />,
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/recover-password',
            element: <RecoverPassword />
        },
        {
            path: '/cadastro',
            element: <Cadastro />
        }
    ]
};

export default AuthenticationRoutes;
