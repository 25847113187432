import Carousel from 'nuka-carousel';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
    padding: 0.5rem 1rem 0.8rem 1rem;

    > * {
        margin-right: 0.5rem;
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bbb;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #aaa;
    }

    @media screen and (max-width: 768px) {
        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.1rem;
            height: 0.1rem;
        }
    }
`;
export const DesktopContainer = styled.div`
    width: 95%;
    margin-left: 4%;
    display: flex;
    gap: 5%;
    > header {
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        color: #373848;
    }

    > main {
        width: 100%;
    }

    > h3 {
        text-align: center;
        color: gray;
    }
`;

export const DesktopContainerCarousel = styled.div`
    width: 95%;
    > header {
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        color: #373848;
    }

    > main {
        width: 100%;
    }

    > h3 {
        text-align: center;
        color: gray;
    }
`;

export const Footer = styled.footer`
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
        background-color: transparent;
        border: none;
        font-weight: 700;
        color: var(--color-primary);
    }
`;
export const GridContainer = styled.div`
    width: 100%;
    gap: 5%;
    display: flex;
`;
export const CarouselContainer = styled(Carousel)`
    padding: 0.65rem 0 0.65rem 1rem;
    width: 100%;
    gap: 5%;
    height: 100%;
`;

export const CarouselCard = styled.div`
    min-height: 381px;
    height: 381px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    h3 {
        font-size: 1.75rem;
        color: var(--color-primary);
    }
`;

export const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 93%;
    gap: 5%;
    padding-left: 2px;
    margin-left: 7%;
    .divider {
        margin-left: 5%;
        background-color: #cfcfcf;
        height: 2px;
        width: 80%;
    }
`;

export const Card = styled.div`
    display: flex;
    margin-bottom: 5%;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    height: 460px;
    border-radius: 8px;
    padding: 1%;
    gap: 1%;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.12);
`;

export const Subtitle = styled.h3`
    color: var(--color-primary);
    font-weight: 700;
    text-align: center;
`;
