import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Line from 'components/atoms/structure/Line';
import TimerDuration from 'components/atoms/texts/TimerDuration';
import Player from 'components/molecules/functionalities/Player';
import Quiz from 'components/organisms/forms/Structure/Quiz';
import useSnackbar from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import { ClassType } from 'types/data/course';
import { padNumber } from 'utils/general';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress } from '@mui/material';

import SectionTitle from '@/components/atoms/fonts/SectionTitle';
import ButtonCallAction from '@/components/BI/buttonCallAction';
import ContentFormModal from '@/components/molecules/modals/ContentForm';
import { ReactComponent as Question } from '@assets/icons/Question.svg';
import useCourseService from '@services/course';

import ClassesList from '../../lists/ClassesList';
import { Container, DesktopContainer, InfoButton } from './styles';

const WatchClass = ({
    watchClass,
    liked,
    setLiked,
    likes,
    nextClass,
    lastClass,
    lastLesson,
    setFinished,
    quizAnswers,

    classes,
    changeClass,
    activeClassNumber,
    maxClassNumber,
    finished,
    progress,
    courseUser,
    courseTitle,
    course_title
}) => {
    const { id } = useParams();
    const video = watchClass?.asking !== undefined ? null : watchClass.url;
    const quiz = watchClass?.asking === undefined ? null : watchClass;
    const quizTime = 0;
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [finishedQuiz, setFinishedQuiz] = useState(null);
    const [finishedVideo, setFinishedVideo] = useState(null);
    const [userResponses, setUserResponses] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [showContentFormModal, setShowContentFormModal] = useState(false);
    const { stopClass, finishTest, finishCourse, completedClass } = useCourseService();
    const user = useSelector((state) => state.auth);
    const checkFinishVideo = () => {
        if (!video || watchClass?.myStudentFinishedVideo || watchClass?.myStudentComplete) {
            return true;
        }

        return false;
    };

    const checkFinishQuiz = () => {
        if (!quiz || watchClass?.myStudentComplete) {
            return true;
        }

        return quizAnswers?.filter((answer) => answer !== null).length === quiz?.length;
    };

    const checkStatus = useCallback(() => {
        const finishVideo = checkFinishVideo();
        const finishQuiz = checkFinishQuiz();

        setFinishedVideo(finishVideo);
        setFinishedQuiz(finishQuiz);

        setFinished(false);
    }, [watchClass]);

    const goToNextClass = () => {
        if (lastLesson && finished) {
            navigate('/glossario');
            return;
        }

        if (lastLesson && !quizAnswers) {
            completedClass(watchClass.id);
            handleFinishCourse();
            return;
        }

        nextClass();
    };

    const handleResponse = (index, response, questionId) => {
        const updatedResponses = [...userResponses];
        updatedResponses[index] = { asking_id: parseInt(questionId), alternative_id: response };
        setUserResponses(updatedResponses);
        let questionsId = answers.filter((el) => el != questionId);
        updatedResponses[index].asking_id;
        setAnswers([...questionsId, questionId]);
    };

    const handleContentSupportModal = () => {
        setShowContentFormModal(!showContentFormModal);
    };

    const finishTestEnd = () => {
        finishTest(userResponses)
            .then(() => {
                finishCourse(user.user.id, id).then(() => {
                    snackbar('Curso finalizado com sucesso', 'success');
                });
            })
            .catch((error) => {
                const erro = error.response.data.errorValidate;
                snackbar(erro[Object.keys(erro)[0]], 'error');
            });

        switch (currentUserRole) {
            case 1:
                navigate('/admin/glossario');
                break;
            case 2:
                navigate('/consultor/glossario');
                break;
            case 3:
                navigate('/empresa/glossario');
                break;
            case 4:
                navigate('/coordenador/glossario');
                break;
            case 5:
                navigate('/supervisor/glossario');
                break;

            default:
                navigate('/glossario');
                break;
        }
    };

    const handleFinishCourse = () => {
        console.log('teste');
        finishCourse(user.user.id, id)
            .then(() => {
                snackbar('Curso finalizado com sucesso', 'success');
            })
            .catch((error) => {
                const erro = error.response.data.errorMessage;
                snackbar(erro, 'error');
            });

        switch (currentUserRole) {
            case 1:
                navigate('/admin/glossario');
                break;
            case 2:
                navigate('/consultor/glossario');
                break;
            case 3:
                navigate('/empresa/glossario');
                break;
            case 4:
                navigate('/coordenador/glossario');
                break;
            case 5:
                navigate('/supervisor/glossario');
                break;

            default:
                navigate('/glossario');
                break;
        }
    };

    useEffect(() => {
        setLoading(true);
        checkStatus();
        setLoading(false);
    }, [watchClass.id]);

    if (loading) {
        return <CircularProgress />;
    }
    return (
        <>
            <DesktopContainer>
                <aside style={{ marginTop: '30px' }}>
                    <ClassesList
                        classes={classes}
                        changeClass={changeClass}
                        currentClassNumber={activeClassNumber}
                        maxClassNumber={maxClassNumber}
                        finished={finished}
                        finishedCourse={progress === 100}
                        courseUser={courseUser}
                        courseTitle={courseTitle}
                    />

                    <InfoButton startIcon={<Question />} onClick={() => window.open('https://wa.me/5519910034306', '_blank')}>
                        Pergunte ao professor
                    </InfoButton>
                </aside>
                <main>
                    <Container>
                        <SectionTitle tituloString={course_title} />
                        <header>
                            {watchClass?.asking === undefined ? (
                                <h3>
                                    Aula {padNumber(Number(watchClass.number))}: {watchClass.title}{' '}
                                </h3>
                            ) : (
                                <h3>{watchClass.title} </h3>
                            )}

                            <h1>{watchClass.name}</h1>
                            {watchClass?.asking === undefined && (
                                <TimerDuration minutes={watchClass.time + quizTime} text="Tempo estimado de" />
                            )}
                        </header>

                        {video && <Player url={video} setFinished={() => finishVideo()} watchClass={watchClass} stopClass={stopClass} />}

                        <main>
                            <div style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: watchClass.description }} />
                        </main>
                        {quiz && <Quiz questions={quiz} answers={quizAnswers} setUserResponses={handleResponse} />}

                        <footer style={{ marginBottom: '2rem' }}>
                            <header>
                                <h3>Gostando do curso? Nôs dê um like!</h3>

                                <aside>
                                    <FavoriteCheckbox active={liked} setActive={setLiked} noPaddingLeft />
                                    <p>{likes}</p>
                                </aside>
                            </header>

                            <Line color="#333" />

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <>
                                    {watchClass.number !== 1 && (
                                        <ButtonCallAction
                                            height="44px"
                                            width="200px"
                                            startIcon={<ChevronLeftIcon />}
                                            iconSize="24px"
                                            buttonColor="#3A6647"
                                            onClick={lastClass}
                                        >
                                            Anterior
                                        </ButtonCallAction>
                                    )}
                                    {watchClass?.asking === undefined ? (
                                        <ButtonCallAction
                                            height="44px"
                                            width="200px"
                                            endIcon={<ChevronRightIcon />}
                                            iconSize="24px"
                                            buttonColor="#3A6647"
                                            onClick={goToNextClass}
                                        >
                                            Próximo
                                        </ButtonCallAction>
                                    ) : watchClass.asking[0].asking_user === null ? (
                                        <ButtonCallAction
                                            height="44px"
                                            width="200px"
                                            endIcon={<ChevronRightIcon />}
                                            iconSize="24px"
                                            buttonColor="#3A6647"
                                            disabled={answers.length !== quiz.asking.length}
                                            onClick={finishTestEnd}
                                        >
                                            Finalizar avaliação
                                        </ButtonCallAction>
                                    ) : null}
                                </>
                            </div>
                        </footer>
                    </Container>
                </main>
            </DesktopContainer>
            <ContentFormModal typeProblem={'content'} open={showContentFormModal} handleClose={handleContentSupportModal} />
        </>
    );
};

WatchClass.propTypes = {
    watchClass: PropTypes.shape(ClassType).isRequired,
    liked: PropTypes.bool.isRequired,
    setLiked: PropTypes.func.isRequired,
    likes: PropTypes.number.isRequired,
    nextClass: PropTypes.func.isRequired,
    lastLesson: PropTypes.bool,
    setFinished: PropTypes.func,
    finishedCourse: PropTypes.bool,
    noVideo: PropTypes.bool,
    quizAnswers: PropTypes.arrayOf(PropTypes.number),
    answerQuiz: PropTypes.func.isRequired,

    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    changeClass: PropTypes.func.isRequired,
    activeClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    finished: PropTypes.bool.isRequired,
    courseUser: PropTypes.object,
    courseTitle: PropTypes.string,
    progress: PropTypes.number.isRequired
};

export default WatchClass;
