import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Snackbar as MUISnackBar } from '@mui/material';

import { SnackbarAlert, SnackbarCollapse } from '@components/molecules/alerts/Snackbar/styles';

const Snackbar = () => {
    const snackbar = useSelector((state) => state.snackbar);
    const dispatch = useDispatch();

    const cleanSnackbar = () => {
        dispatch({
            type: 'SET_SNACKBAR',
            payload: {
                text: '',
                type: snackbar.type
            }
        });
    };
    useEffect(() => {
        const timeout = setTimeout(cleanSnackbar, 3000);
        return () => clearTimeout(timeout);
    }, [snackbar.text]);
    if (snackbar.text == '') {
        return <></>;
    }
    return (
        <SnackbarCollapse in={snackbar.text !== ''}>
            <SnackbarAlert onClose={() => cleanSnackbar()} severity={snackbar?.type || 'success'} sx={{ width: '100%' }}>
                <>{snackbar?.text}</>
            </SnackbarAlert>
        </SnackbarCollapse>
    );
};

export default Snackbar;
