import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import { Button } from '@mui/material';

import HeaderOptions from '@/components/organisms/headers/HeaderOptions';
import EmployeePerformance from '@components/molecules/modals/EmployeeProfileModal/EmployeePerformance';

import BaseModal from '../BaseModal';
import { EmployeePropertiesList } from './EmployeePropertiesList';
import { ProfileItem } from './ProfileItem';
import { Actions, ContainerActiveConsultant, InputRole, ItemAvatar, TabMenuWrapper } from './styles';

export const EmployeeProfileModal = ({ open, handleClose, profile }) => {
    const [optionSelected, setOptionSelected] = useState('Perfil');
    const [optionsHeader, setOptionsHeader] = useState(['Perfil', 'Desempenho']);

    useEffect(() => {
        if (open && profile.roles.includes(2)) {
            setOptionsHeader((previous) => [...previous, 'Propriedades']);
        } else {
            setOptionsHeader(['Perfil', 'Desempenho']);
            setOptionSelected('Perfil');
        }
    }, [open]);

    return (
        <BaseModal
            title="Colaborador"
            open={open}
            mdSize="70%"
            backgroundColor="rgba(128, 128, 128, 0.1)"
            handleClose={handleClose}
            withActions={
                <Actions>
                    <Button variant={'outlined'} onClick={handleClose}>
                        <b>Fechar</b>
                    </Button>
                </Actions>
            }
        >
            <TabMenuWrapper>
                <HeaderOptions options={optionsHeader} setOptionSelected={setOptionSelected} optionSelected={optionSelected} />
            </TabMenuWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ContainerActiveConsultant>
                        {optionSelected === 'Perfil' && profile && <ProfileItem profile={profile} />}
                        {optionSelected === 'Desempenho' && profile && <EmployeePerformance collaborator={profile} />}
                        {optionSelected === 'Propriedades' && profile && <EmployeePropertiesList collaboratorId={profile.id} />}
                    </ContainerActiveConsultant>
                </Grid>
            </Grid>
        </BaseModal>
    );
};

EmployeeProfileModal.propTypes = {
    profile: PropTypes.object
};
