import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Message = ({ text, html }) => {
    if (html) {
        return <Container dangerouslySetInnerHTML={{ __html: text }} />;
    }

    return (
        <Container>
            <p>{text}</p>
        </Container>
    );
};

Message.propTypes = {
    text: PropTypes.string,
    html: PropTypes.bool
};

export default Message;
