import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import Meta from '@components/templates/Seo/Meta';

import useAuthService from '../services/auth';

const Index = () => {
    const { logged } = useAuthService();
    const navigate = useNavigate();
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
    const redirect = (path) => {
        try {
            navigate(path);
        } catch (error) {
            window.location.href = path;
        }
    };

    const authVerify = async () => {
        let setKeyAccessLogin = true;
        const isLogged = await logged(currentUserRole, setKeyAccessLogin);

        if (!isLogged) {
            sessionStorage.removeItem('currentUserRole');
        }

        redirect('/login');
    };

    useEffect(() => {
        setTimeout(() => {
            authVerify();
        }, 500);
    }, [navigate]);

    return (
        <>
            <Meta />
            <CircularProgress color="inherit" />
        </>
    );
};

export default Index;
