import styled from 'styled-components';

import { Grid } from '@mui/material';

export const Section = styled.div`
    background: #ffffff;
    flex: 1;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #c6c6c6;
`;

export const PaperTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #25272e;
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    width: ${(props) => props.width || null};
`;

export const GridConsultant = styled(Grid)`
    border: solid 1px #cfdcd3;
    padding: 1rem;
    border-radius: 8px;
`;

export const NoDataContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
`;

export const NoData = styled.h3`
    font-size: 1.25rem;
`;
