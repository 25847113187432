import useApi from '../api';

const useNotificationService = () => {
    const { api } = useApi();
    return {
        getAllNotificationsUser: (data) =>
            api
                .get('notification', data)
                .then((resp) => resp.data)
                .catch((err) => err),
        removeNotificationUser: (id) => api.delete(`notification_user/${id}`)
    };
};

export default useNotificationService;
