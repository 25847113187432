import React from 'react';

import Index from '@/pages';
import Error404 from '@/pages/Errors/404';
import BlankLayout from '@components/templates/layouts/Outlet/BlankLayout';

const MainRoutes = {
    path: '/',
    element: <BlankLayout noPadding={true} />,
    children: [
        {
            path: '/',
            element: <Index />
        },
        {
            path: '*',
            element: <Error404 />
        }
    ]
};

export default MainRoutes;
