import PropTypes from 'prop-types';

export const NotificationType = {
    id: PropTypes.number,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    button: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    idCourse: PropTypes.number,
    idEvent: PropTypes.number,
    date: PropTypes.instanceOf(Date)
};
