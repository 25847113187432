import PropTypes from 'prop-types';
import React from 'react';

import { Container, Content } from './styles';

const FullWidthContainer = ({ children, sticky, background, marginTop, padding, containerWidth, containerMaxWidth }) => {
    return (
        <Container
            marginTop={marginTop}
            sticky={sticky}
            background={background}
            padding={padding}
            containerWidth={containerWidth}
            containerMaxWidth={containerMaxWidth}
        >
            <Content>{children}</Content>
        </Container>
    );
};

FullWidthContainer.propTypes = {
    children: PropTypes.node.isRequired,
    sticky: PropTypes.bool,
    background: PropTypes.string,
    marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    padding: PropTypes.string,
    first: PropTypes.bool,
    contentMaxWidth: PropTypes.number,
    containerWidth: PropTypes.string,
    containerMaxWidth: PropTypes.string
};

FullWidthContainer.defaultProps = {
    containerWidth: '100vw',
    containerMaxWidth: 'var(--max-width)'
};

export default FullWidthContainer;
