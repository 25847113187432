import { combineReducers } from 'redux';

import propertyReducer from '@/redux/reducers/propertyReducer';

import alertReducer from './alertReducer';
import authReducer from './authReducer';
import confirmDialogReducer from './confirmDialogReducer';
import dashboardReducer from './dashboardReducer';
import enterpriseReducer from './enterpriseReducer';
import indicatorsPanelMilkingControlReducer from './indicatorsPanelMilkingControlReducer';
import indicatorsPanelSubclinicalMastitisReducer from './indicatorsPanelSubclinicalMastitisReducer';
import managerPanelReducer from './ManagerPanel';
import notificationReducer from './notificationsReducer';
import producerReducer from './producerReducer';
import snackbarReducer from './snackbarReducer';
import tabsDiagnosticReducer from './tabsDiagnosticReducer';
import tourReducer from './tourReducer';
import userActionPlansReducer from './userActionPlansReducer';
import userEventReducer from './userEventReducer';
import userPropertiesReducer from './userPropertiesReducer';

const reducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    snackbar: snackbarReducer,
    confirmDialog: confirmDialogReducer,
    tabsDiagnostic: tabsDiagnosticReducer,
    producer: producerReducer,
    property: propertyReducer,
    enterprises: enterpriseReducer,
    alert: alertReducer,
    userEvent: userEventReducer,
    userProperties: userPropertiesReducer,
    userActionPlans: userActionPlansReducer,
    notification: notificationReducer,
    tour: tourReducer,
    managerPanelReducer: managerPanelReducer,
    indicatorsPanelMilkingControl: indicatorsPanelMilkingControlReducer,
    indicatorsPanelSubclinicalMastitis: indicatorsPanelSubclinicalMastitisReducer
});

export default reducer;
