import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ButtonCallAction from '@/components/BI/buttonCallAction';

import { Card } from './styles';
const CardCarousel = ({ value, redirectUser, action }) => {
    const formatDate = (date) => {
        return format(new Date(date), 'dd/MM/yyyy HH:mm', { locale: ptBR });
    };
    return (
        <Card>
            <img src={value.image} alt="imagem-curso" height="35%" style={{ borderRadius: '8px' }} />
            <p style={{ color: '#373848', fontWeight: 700, fontSize: '16px', marginTop: '16px' }}>
                {value.title.length > 50 ? `${value.title.substring(0, 26)}...` : value.title}
            </p>
            <p style={{ fontWeight: 600, fontSize: '12px', marginTop: '16px' }}>{value.speaker}</p>
            <div style={{ height: '48px' }}>
                <p style={{ fontSize: '12px', marginTop: '10px' }}>
                    {value.description.length > 152 ? `${value.description.substring(0, 100)}...` : value.description}
                </p>
            </div>
            <div style={{ display: 'flex', marginTop: '2rem', gap: '2px', marginBottom: '20px', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <AccessTimeIcon fontSize="40px" />
                    <p style={{ fontWeight: 500, fontSize: '12px' }}>De: {formatDate(value.initial_date)}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <AccessTimeIcon fontSize="40px" />
                    <p style={{ fontWeight: 500, fontSize: '12px' }}>Ate: {formatDate(value.final_date)}</p>
                </div>
            </div>
            <ButtonCallAction
                height="44px"
                endIcon={<ChevronRightIcon />}
                iconSize="24px"
                buttonColor="#3A6647"
                onClick={() => {
                    if (value.event_user === null) {
                        action(value.id);
                    }
                    redirectUser(value.id);
                }}
            >
                Saiba mais
            </ButtonCallAction>
        </Card>
    );
};
CardCarousel.propTypes = {
    course: PropTypes.any.isRequired,
    variant: PropTypes.string
};
export default CardCarousel;
