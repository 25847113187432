import { useDispatch, useSelector } from 'react-redux';

import {
    SET_ENTERPRISE_SELECTED_NEW_EMPLOYEE,
    SET_EMPLOYEES,
    SET_ENTERPRISE_SELECTED_EMPLOYEE,
    SET_ENTERPRISE_BLOCK_EMPLOYEE_MODAL,
    SET_ENTERPRISE_DETAILS_EMPLOYEE_MODAL,
    SET_ENTERPRISE_EMPLOYEES_ARCHIVED_MODAL
} from '@/redux/mutations';

const useEnterprise = () => {
    const dispatch = useDispatch();
    const enterprises = useSelector((state) => state.enterprises);
    return {
        ...enterprises,
        setEmployees: (payload) => {
            dispatch({ type: SET_EMPLOYEES, payload });
        },
        handleEmployeeApproveModal: (content) => {
            dispatch({ type: SET_ENTERPRISE_SELECTED_NEW_EMPLOYEE, payload: content });
        },
        handleEmployeeEditModal: (content) => {
            dispatch({ type: SET_ENTERPRISE_SELECTED_EMPLOYEE, payload: content });
        },
        handleEmployeeBlockModal: (content) => {
            dispatch({ type: SET_ENTERPRISE_BLOCK_EMPLOYEE_MODAL, payload: content });
        },
        handleEmployeeDetailsModal: (content) => {
            dispatch({ type: SET_ENTERPRISE_DETAILS_EMPLOYEE_MODAL, payload: content });
        },
        handleEmployeesArchivedModal: (content) => {
            dispatch({ type: SET_ENTERPRISE_EMPLOYEES_ARCHIVED_MODAL, payload: content });
        }
    };
};

export default useEnterprise;
