import React from 'react';

import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';

import { Ranking } from '@components/Enterprise/Ranking';

export const Loading = () => {
    const content = {
        name: <Skeleton width={200} />,
        properties_count: <Skeleton width={100} />
    };
    const data = new Array(40).fill(content);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton width={240} height={40} />
            </Grid>
            {_.chunk(data.slice(0, 4 * 10), 10).map((column, key) => {
                return (
                    <Grid key={`column-${key}`} item xs={12 / 4}>
                        <Ranking titleKey={'name'} valueKey={'properties_count'} items={column} />
                    </Grid>
                );
            })}
        </Grid>
    );
};
