import styled from 'styled-components';

export const NoDataContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
`;

export const NoData = styled.h3`
    font-size: 1.25rem;
`;
