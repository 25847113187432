import React from 'react';

import { round } from 'lodash';
import PropTypes from 'prop-types';

import ProgressBar from '@components/BI/progressBar';

import { Container, ContainerTitle, GraphicItem, GraphicItemSubTitle, GraphicItemTitle, ProgressBarLabel } from './styles';

const GraphicProgressBar = ({ title, data, comparision = 'max', className, total }) => {
    // const max = data.length > 0 ? data.reduce((prev, current) => (prev.progress > current.progress ? prev : current)).progress : 0;
    const totalValue = data.length > 0 ? data.reduce((total, y) => total + y.progress, 0) : 0;

    return (
        <Container className={className}>
            <ContainerTitle>{title}</ContainerTitle>
            {data.map((item, key) => (
                <GraphicItem key={`progress-${key}`}>
                    <GraphicItemTitle>{item.title} </GraphicItemTitle>
                    <GraphicItemSubTitle>
                        <span>{item.progress}</span>
                        <span>|</span>
                        <span>{round((100 / total || totalValue) * item.progress, 0)}%</span>
                    </GraphicItemSubTitle>
                    {/* <ProgressBarLabel width={(100 / totalValue) * item.progress}>
                        <div className="label">
                            <span>{round((100 / totalValue) * item.progress, 2)}%</span>
                        </div>
                    </ProgressBarLabel> */}
                    <ProgressBar progress={round((100 / total || totalValue) * item.progress)} />
                </GraphicItem>
            ))}
        </Container>
    );
};

GraphicProgressBar.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    comparision: PropTypes.string,
    className: PropTypes.string,
    total: PropTypes.number
};

export default GraphicProgressBar;
