import { ReactComponent as CheckCircle } from '@assets/icons/CheckCircle.svg';

import { AlertContainer, AlertContent, AlertHeader } from './styles';

export const RegisteredAlert = () => {
    return (
        <AlertContainer>
            <div className="content-wrapper">
                <AlertHeader>
                    <h3>Cadastro de Usuário</h3>
                </AlertHeader>
                <AlertContent>
                    <div className="title">
                        <CheckCircle /> O cadastro foi realizado com sucesso.
                    </div>
                    <p className="description">
                        Confira a liberação de seu acesso no seu e-mail. Você pode fechar essa janela no seu navegador.
                    </p>
                    <p className="description">Obrigado!</p>
                </AlertContent>
            </div>
        </AlertContainer>
    );
};
