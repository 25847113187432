import useApi from '../api';

const useUserEventsService = () => {
    const { api } = useApi();

    return {
        getUserEvents: (consultantId = null) =>
            api.get('user-events', {
                params: {
                    consultantId
                }
            }),

        getVisitsPetMonth: (id, data, month) =>
            api
                .get(`user-events/get_properties_visits/${id}`, {
                    params: {
                        data,
                        month
                    }
                })
                .then((resp) => resp.data),

        getQuantityOfVisits: (year, month, enterpriseId) =>
            api
                .get(`user-events/quantity_of_visits`, {
                    params: {
                        year,
                        month,
                        enterpriseId
                    }
                })
                .then((resp) => resp.data),

        createUserEvent: (payload) =>
            api.post('user-events', payload, {
                headers: { 'Content-Type': 'application/json' }
            }),

        updateUserEvent: (id, payload) =>
            api.put(`user-events/${id}`, payload, {
                headers: { 'Content-Type': 'application/json' }
            }),

        deleteUserEvent: (id) => api.delete(`user-events/${id}`)
    };
};

export default useUserEventsService;
