import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UploadWithPreview from 'components/atoms/formInputs/UploadWithPreview';
import { useFormik } from 'formik';
import useDashboard from 'hooks/useDashboard';
import useSnackbar from 'hooks/useSnackbar';
import useUserService from 'services/user';
import { formikProps } from 'utils/helpers/formikProps';
import * as Yup from 'yup';

import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';

import ButtonCallAction from '@/components/BI/buttonCallAction';
import { OutlinedInputPassword } from '@components/atoms/formInputs/OutlinedInputPassword';
import useAuthService from '@services/auth';

import BaseModal from '../BaseModal';

const settingsValidation = Yup.object().shape({
    email: Yup.string().required('O email é obrigatório').email('Insira um email válido'),
    name: Yup.string().required('O nome é obrigatório'),
    newPassword: Yup.string().min('8', 'O campo deve conter no mínimo 8 dígitos'),
    confirmPassword: Yup.string()
        .min('8', 'O campo deve conter no mínimo 8 dígitos')
        .when('newPassword', {
            is: (newPassword) => typeof newPassword !== 'undefined',
            then: Yup.string().required('Você deve confirmar a nova senha')
        })
        .oneOf([Yup.ref('newPassword'), null], 'As senhas não são iguais')
});

const SettingsModal = () => {
    const { closeSettingsModal, ...dashboard } = useDashboard();
    const auth = useSelector((state) => state.auth);
    const userService = useUserService();
    const snackbar = useSnackbar();
    const dispatch = useDispatch();
    const { setLogin } = useAuthService();

    const formik = useFormik({
        initialValues: {
            id: '',
            image: '',
            email: '',
            name: 'Rehagro',
            newPassword: '',
            confirmPassword: '',
            toUpload: []
        },
        validationSchema: settingsValidation,
        onSubmit: () => {
            const formData = new FormData();
            formData.append('id', formik.values.id);
            formData.append('email', formik.values.email);
            formData.append('newPassword', formik.values.newPassword);
            formData.append('confirmPassword', formik.values.confirmPassword);
            formData.append('noImage', formik.values.image == '');
            formData.append('_method', 'PUT');
            if (typeof formik.values.toUpload !== 'undefined') {
                formData.append('toUpload', formik.values.toUpload);
            }
            userService
                .updateProfileInfo(formData)
                .then((resp) => {
                    snackbar(resp.success, 'success');
                    closeSettingsModal();

                    let user = resp.user;
                    if (user != null) {
                        setLogin(user);
                    }
                })
                .catch(
                    ({
                        response: {
                            data: { error }
                        }
                    }) => {
                        snackbar(error, 'error');
                    }
                );
        }
    });
    useEffect(() => {
        if (dashboard.settingsModal) {
            formik.setValues({
                id: auth.user.id,
                image: auth.user.image,
                email: auth.user.email,
                name: auth.user.name || 'Rehagro',
                newPassword: '',
                confirmPassword: '',
                toUpload: []
            });
        }
    }, [dashboard.settingsModal]);

    const handleFileChange = (files, url) => {
        formik.setFieldValue('toUpload', files[0]);
        if (url == auth.user.image) {
            formik.setFieldValue('image', null);
        } else {
            formik.setFieldValue('image', url);
        }
    };
    const handleCloseModal = () => {
        formik.resetForm();
        closeSettingsModal();
    };
    const handleSubmit = () => {
        formik.handleSubmit();
    };

    return (
        <BaseModal
            backgroundColor="rgba(0, 0, 0, 0.6)"
            title={'Configurações do Perfil'}
            open={dashboard.settingsModal}
            handleClose={handleCloseModal}
            withActions={
                <>
                    <ButtonCallAction variant={'outlined'} onClick={handleCloseModal}>
                        Cancelar
                    </ButtonCallAction>
                    <ButtonCallAction onClick={handleSubmit} buttonColor="#3A6647" disabled={!formik.isValid}>
                        Salvar
                    </ButtonCallAction>
                </>
            }
        >
            <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'} style={{ marginBottom: '20px' }}>
                        <UploadWithPreview
                            rounded
                            helperText={formik.touched.image && <>{formik.errors.image}</>}
                            error={formik.touched.image && Boolean(formik.errors.image)}
                            text={'Inserir Imagem'}
                            value={formik.values.image}
                            //defaultValue={auth.user.image}
                            onFileChange={handleFileChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span>Nome:</span>
                        <TextField
                            {...formikProps('name', formik)}
                            fullWidth
                            size={'small'}
                            value={formik.values.name || 'Rehagro'}
                            disabled={auth.user.idRole !== 1}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span>E-mail:</span>
                        <TextField {...formikProps('email', formik)} fullWidth size={'small'} disabled={auth.user.idRole !== 1} required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span>Alterar sua senha:</span>
                        <OutlinedInputPassword
                            {...formikProps('newPassword', formik)}
                            id="password"
                            value={formik.values.newPassword}
                            type="password"
                            fullWidth
                            size={'small'}
                            error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                            errorText={formik.errors.newPassword}
                            inputProps={{ autoComplete: 'new-password' }}
                        />
                        {/* <TextField
                            {...formikProps('newPassword', formik)}
                            fullWidth
                            size={'small'}
                            label={'Nova Senha'}
                            inputProps={{ type: 'password' }}
                        /> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span>Confirmar nova senha:</span>
                        <OutlinedInputPassword
                            {...formikProps('confirmPassword', formik)}
                            value={formik.values.confirmPassword}
                            type="password"
                            fullWidth
                            size={'small'}
                            error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                            errorText={formik.errors.confirmPassword}
                        />
                        {/* <TextField
                            {...formikProps('confirmPassword', formik)}
                            fullWidth
                            size={'small'}
                            label={'Confirmar nova senha'}
                            inputProps={{ type: 'password' }}
                            required={Boolean(formik.values.newPassword)}
                        /> */}
                    </Grid>
                </Grid>
            </form>
        </BaseModal>
    );
};

export default SettingsModal;

SettingsModal.propTypes = {};
