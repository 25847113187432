import styled from 'styled-components';

export const LegendContainer = styled.div`
    width: 80%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1.5rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 20px;
    }
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        margin-top: 2px;
    }
`;

export const LegendDot = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
`;
