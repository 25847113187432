import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { yearsToMonths } from 'date-fns/esm/fp';
import PropTypes from 'prop-types';

import { MenuItem } from '@mui/material';

import Bar from '@/components/BI/graphicBar';
import GraphicProgressBar from '@/components/BI/GraphicProgressBar';
import HeaderTotalGraphic from '@/components/organisms/headers/HeaderTotalGraphic';

import { useActions } from './hooks';
import {
    ActionCard,
    ActionCardWrapper,
    ActionsContainer,
    ActionStatus,
    ActionWrapper,
    ContainerYears,
    Div,
    GraphicWrapper,
    PaperContainer,
    PaperTitle,
    SectorsContent,
    SectorsDivider,
    SectorsHeader,
    SectorsWrapper,
    TextFildStyled
} from './styles';
import useManagerPanelService from '@/services/managerPanel';
import { handleDownloadData } from '@/utils/helpers/downloads';

const graphicOptions = {
    chartArea: { width: '80%', height: '80%' },
    colors: ['#ED9F4A'],
    explorer: { axis: 'horizontal', keepInBounds: false },
    orientation: 'horizontal',
    legend: { position: 'none' },
    fontSize: 14,
    tooltip: {
        ignoreBounds: false,
        textStyle: {
            bold: true
        }
    },
    annotations: {
        textStyle: {
            fontSize: 15,
            bold: true,
            color: '#000'
        },
        boxStyle: {
            rx: 10,
            ry: 10
        }
    },
    vAxis: {
        title: 'Quant. de Planos de Ação',
        titleTextStyle: {
            italic: false,
            color: '#9BA1A9'
        }
    }
};

export const ActionPlans = ({ idEnterprise }) => {
    const {
        selectRef,
        filterPer,
        handleFilter,
        years,
        totalActionPlansPerYear,
        totalActionPlansConsolidated,
        totalEnterpriseActionPlansActionsCreated,
        totalEnterpriseActionPlansActionsCompleted,
        totalEnterpriseActionPlansActionsCanceled,
        graphBarData,
        sectorsData
    } = useActions(idEnterprise);

    const dispatch = useDispatch();
    const { getActionPlansPDFReport } = useManagerPanelService();
    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);

    const handleReport = async () => {
        try {
            dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
            const { data } = await getActionPlansPDFReport(idEnterprise, filterPer);

            if (data) {
                handleDownloadData(data, `relatorio_de_plano_de_ações_${filterPer}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({ type: 'MANAGER_PANEL_RESET' });
        }
    };

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    return (
        <PaperContainer>
            <HeaderTotalGraphic title="Total Consolidado:" description={`${totalActionPlansConsolidated} Planos de ação criados`} />
            {/* <PaperContainer> */}
            <PaperTitle>Planos de Ação / ano</PaperTitle>
            <ContainerYears>
                <Div>
                    <TextFildStyled
                        ref={selectRef}
                        fullWidth
                        size={'small'}
                        select
                        value={filterPer}
                        onChange={(evt) => handleFilter(evt.target.value)}
                    >
                        {years?.map((value, key) => (
                            <MenuItem key={key} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextFildStyled>
                </Div>
                <Div width="18rem">
                    <p>
                        <b>{filterPer} |</b> {totalActionPlansPerYear} Planos de Ação criados
                    </p>
                </Div>
            </ContainerYears>

            <GraphicWrapper height={graphBarData.length > 0 && '400px'}>
                <Bar height="100%" width="100%" allData={graphBarData} options={graphicOptions} />
            </GraphicWrapper>
            {graphBarData.length > 0 && (
                <>
                    <SectorsWrapper>
                        <SectorsHeader>
                            <div>Quantidade de Planos Criados / Área de atuação</div>
                            <div>
                                Total de Planos: <span>{totalActionPlansPerYear}</span>
                            </div>
                        </SectorsHeader>
                        {sectorsData.length > 0 && (
                            <SectorsContent>
                                <GraphicProgressBar
                                    total={totalActionPlansPerYear}
                                    className="progress-bars"
                                    data={sectorsData.slice(0, Number((sectorsData.length + 1) / 2))}
                                />
                                <SectorsDivider />
                                <GraphicProgressBar
                                    total={totalActionPlansPerYear}
                                    className="progress-bars"
                                    data={sectorsData.slice(Number((sectorsData.length + 1) / 2), sectorsData.length)}
                                />
                            </SectorsContent>
                        )}
                    </SectorsWrapper>

                    <ActionWrapper>
                        <SectorsHeader>
                            <div>Ações criadas nos Planos de Ação | {filterPer}</div>
                        </SectorsHeader>

                        <ActionsContainer>
                            <ActionCardWrapper>
                                <ActionCard>
                                    <span>Total de ações criadas</span>
                                    <span className="value">{totalEnterpriseActionPlansActionsCreated}</span>
                                </ActionCard>
                                <ActionStatus bgcolor="#10B7DC" />
                            </ActionCardWrapper>
                            <ActionCardWrapper>
                                <ActionCard>
                                    <span>Total de ações concluídas</span>
                                    <span className="value">{totalEnterpriseActionPlansActionsCompleted}</span>
                                </ActionCard>
                                <ActionStatus bgcolor="#62C054" />
                            </ActionCardWrapper>
                            <ActionCardWrapper>
                                <ActionCard>
                                    <span>Total de ações canceladas</span>
                                    <span className="value">{totalEnterpriseActionPlansActionsCanceled}</span>
                                </ActionCard>
                                <ActionStatus bgcolor="#DF2C0F" />
                            </ActionCardWrapper>
                        </ActionsContainer>
                    </ActionWrapper>
                </>
            )}
        </PaperContainer>
    );
};

ActionPlans.propTypes = {
    idEnterprise: PropTypes.number
};
