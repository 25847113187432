import React from 'react';

import Progress from 'components/atoms/inputs/Progress';
import Backdrop from 'components/molecules/structure/Backdrop';
import ClassesList from 'components/organisms/lists/ClassesList';
import PropTypes from 'prop-types';
import { ClassType } from 'types/data/course';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { Container, MyProgress } from './styles';

const ClassesMenu = ({ classes, handler, active, changeClass, currentClassNumber, maxClassNumber, progress, finished, courseUser }) => {
    return (
        <>
            <Container active={active}>
                <header>
                    <h2>Menu das aulas</h2>
                    <IconButton onClick={() => handler()}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </header>

                <main>
                    <MyProgress>
                        <p>Seu Progresso: </p>
                        <Progress progress={progress} />
                    </MyProgress>

                    <ClassesList
                        classes={classes}
                        changeClass={changeClass}
                        currentClassNumber={currentClassNumber}
                        maxClassNumber={maxClassNumber}
                        finished={finished}
                        finishedCourse={progress === 100}
                        courseUser={courseUser}
                    />
                </main>
            </Container>
            <Backdrop active={active} handler={handler} />
        </>
    );
};

ClassesMenu.propTypes = {
    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    handler: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired,
    currentClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    changeClass: PropTypes.func.isRequired,
    finished: PropTypes.bool.isRequired
};

export default ClassesMenu;
