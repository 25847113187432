import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';
const HeaderTotalGraphic = ({ title, description, small, marginBottom }) => {
    return (
        <Container small={small} marginBottom={marginBottom}>
            <h2>{title}</h2>
            <h1>{description}</h1>
        </Container>
    );
};

HeaderTotalGraphic.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    small: PropTypes.bool
};

export default HeaderTotalGraphic;
