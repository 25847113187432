import ButtonCallAction from '@/components/BI/buttonCallAction';

import { ConsultantsActivesAndInactivesList } from '../../lists/ConsultantsActivesAndInactivesList';
import BaseModal from '../BaseModal';
import { Container } from './styles';
const ConsultantActivesAndInactives = ({ title, isOpen, data, handleClose }) => {
    return (
        <BaseModal
            backgroundColor="rgba(0, 0, 0, 0.6)"
            title={title}
            open={isOpen}
            handleClose={handleClose}
            withActions={
                <>
                    <ButtonCallAction variant={'outlined'} onClick={handleClose}>
                        Cancelar
                    </ButtonCallAction>
                </>
            }
        >
            <Container>
                {data.length === 0 && <p className="textInformation">Nenhum consultor encontrado</p>}
                <ConsultantsActivesAndInactivesList data={data} />
            </Container>
        </BaseModal>
    );
};

export default ConsultantActivesAndInactives;

ConsultantActivesAndInactives.propTypes = {};
