import styled from 'styled-components';

import { AccordionSummary, Grid, Paper } from '@mui/material';

import { ReactComponent as Bin } from '@assets/icons/Bin.svg';

export const TabMenuWrapper = styled.div`
    margin-top: 1rem;
    padding: 0 1rem;
`;

export const Info = styled(Grid)`
    margin-top: 1rem;
    > p {
        font-family: 'Myriad Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #25272r;
    }

    > img {
        width: 163px;
        height: 126px;
        border: 1px solid #9cb2a3;
        border-radius: 8px;
    }
`;

export const GroupTitle = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    background: #fafbfa;

    border: 1px solid #dcdcdc;
    border-radius: 0.5rem;
    padding: 8px 24px;

    .type {
        display: flex;
        flex-direction: row;
        gap: 5px;

        > h5 {
            height: 26px;

            font-family: 'Myriad Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 130%;

            color: #294732;
        }
    }

    .seniority {
        display: flex;
        background: #eee7f0;
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;
        padding: 4px 8px;
    }
`;

export const InputGruop = styled(Grid)`
    width: 100%;
    border: 1px solid #dcdcdc;
    border-radius: 0.5rem;
    margin 1rem 0rem;
    padding: 24px;
    position: relative;
`;

export const Actions = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const ContainerActiveConsultants = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    width: 100%;
    height: 100%;

    background: #ebefed;

    > h3 {
        height: 22px;

        font-family: 'Myriad Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #25272e;
    }
`;

export const CardActiveConsultants = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 4px;
    // cursor: pointer;

    width: 95%;
    height: 64px;

    background: #ffffff;

    border: 1px solid #cfdcd3;
    border-radius: 6px;

    > div {
        padding: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2px;

        .chip {
            background: #eee7f0;
            border-radius: 8px;
            font-size: 10px;
            font-weight: 600;
            padding: 3px 8px;
            width: 45px;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;

        > span {
            background: #eee7f0;
            border-radius: 8px;
            font-size: 10px;
            font-weight: 600;
            padding: 4px 8px;
        }
    }

    .options {
        border-left: 1px solid #cfdcd3;
        gap: 7px;
        display: flex;
        align-items: center;
        width: 8%;
        height: 100%;
    }
`;

export const ContainerActiveConsultant = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 0px;
    gap: 16px;

    width: 100%;
    min-height: 768px;
    height: 100%;

    border-radius: 0px;
`;

export const ContentActiveConsultant = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0px;
`;

export const InputRole = styled.div`
    padding-left: 15px;
    top: 0;
    background-color: #fafbfa;
    border-bottom: 1px solid #cfdcd3;
    width: 100%;
    padding: 5px 20px 5px 20px;
    position: absolute;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .type {
        display: flex;
        flex-direction: row;
        gap: 5px;
        > h5 {
            height: 26px;

            font-family: 'Myriad Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 130%;

            display: flex;
            align-items: center;
        }

        > p {
            font-weight: 400px;
            margin-top: 0.2rem;
            color: #000;
        }
    }
    .supervisor {
        position: relative;
        left: 30%;
        display: flex;
        flex-direction: row;
        gap: 5px;
        > h5 {
            height: 26px;

            font-family: 'Myriad Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 130%;

            display: flex;
            align-items: center;
        }

        > p {
            font-weight: 400px;
            margin-top: 0.2rem;
            color: #000;
        }
    }
`;
export const Seniority = styled.div`
    display: flex;
    margin-left: 10px;
    background: ${(props) =>
        props.level !== ''
            ? props.level === 'Trainee' || props.level === 'Junior'
                ? '#e1eae1'
                : props.level === 'Pleno'
                ? '#efefdb'
                : props.level === 'Senior'
                ? '#eee7f0'
                : ''
            : ''};
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    padding: 4px 8px;

    .level {
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;

        color: #ffffff;

        &.Trainee {
            background-color: #e1eae1;
            color: #000;
        }

        &.Junior {
            background-color: #e1eae1;
            color: #000;
        }

        &.Pleno {
            background-color: #efefdb;
            color: #000;
        }

        &.Senior {
            background-color: #eee7f0;
            color: #000;
        }
    }
`;
export const ItemAvatar = styled.div`
    position: absolute;
    right: 10px;
    top: 60px;

    @media (max-width: 767px) {
        position: relative;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        top: 15px;
    }

    img {
        border-radius: 50%;
        width: 110px;
        height: 110px;
        object-fit: cover;
    }
`;

export const BinIcon = styled(Bin)`
    cursor: pointer;
`;

export const PropertySchedule = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 44px;
    border-left: 1px solid #edeef1;
    justify-content: center
    align-items: center;

    .divider {
        background-color: #edeef1;
        height: 1px;
        width: 80%;
    }

    .MuiIconButton-root {
        border-radius: 10px !important;
        padding: 0;

        &:hover {
            background-color: #edeef1;
        }
    }
`;

export const PropertiesList = styled.div`
    width: 100%;
`;

export const PropertyContainer = styled(Paper)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 96px;
    padding: 16px 0 16px 24px;
    filter: drop-shadow(0px 3px 12px rgba(81, 69, 159, 0.08));

    flex: none;
    order: 0;
    flex-grow: 1;
`;

export const PropertyAccordionHeader = styled(AccordionSummary)`
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiAccordionSummary-content {
        margin: 0 !important;
        align-items: center !important;
    }

    .reports-count {
        padding-right: 16px;
    }
`;

export const PropertyInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    /* cursor: pointer; */
    > div.logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: calc(100% / 3);

        > img {
            border-radius: 8px;
            width: 60px;
            height: 48px;
            object-fit: contain;
        }
    }

    > div.name {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Myriad Pro';
        font-style: normal;
        line-height: 22px;

        color: #25272e;

        > span {
            display: block;

            &.propertyName {
                font-size: 18px;
            }
        }
    }
`;

export const PropertyReportsList = styled.div`
    display: flex;
    flex-direction: column;

    /* gap: 24px; */
`;

export const PropertyReport = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 16px 85px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e8e9eb;
`;

export const PropertyReportItem = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    .visit-number {
        font-weight: 700;
    }
`;
