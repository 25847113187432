import {
    SET_ENTERPRISES,
    SET_ENTERPRISE,
    SET_EMPLOYEES,
    SET_ENTERPRISE_SELECTED_NEW_EMPLOYEE,
    SET_ENTERPRISE_SELECTED_EMPLOYEE,
    SET_ENTERPRISE_BLOCK_EMPLOYEE_MODAL,
    SET_ENTERPRISE_DETAILS_EMPLOYEE_MODAL,
    SET_ENTERPRISE_EMPLOYEES_ARCHIVED_MODAL
} from '@/redux/mutations';

export const initialState = {
    enterprises: [],
    enterprise: {},
    employees: [],
    newEmployee: {},
    newEmployeeApproveModalOpen: false,
    selectedEmployee: {},
    selectedEmployeeApproveModalOpen: false,
    employeeBlockModalOpen: false,
    employeeDetailsModalOpen: false,
    employeesArchivedModalOpen: false
};

const enterpriseReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_ENTERPRISES:
            return {
                ...state,
                enterprises: actions.payload
            };
        case SET_ENTERPRISE:
            return {
                ...state,
                enterprise: actions.payload
            };
        case SET_EMPLOYEES:
            return {
                ...state,
                employees: actions.payload
            };
        case SET_ENTERPRISE_SELECTED_NEW_EMPLOYEE:
            return {
                ...state,
                newEmployeeApproveModalOpen: actions.payload.open,
                newEmployee: actions.payload.newEmployee
            };
        case SET_ENTERPRISE_SELECTED_EMPLOYEE:
            return {
                ...state,
                selectedEmployeeApproveModalOpen: actions.payload.open,
                selectedEmployee: actions.payload.selectedEmployee
            };
        case SET_ENTERPRISE_BLOCK_EMPLOYEE_MODAL:
            return {
                ...state,
                employeeBlockModalOpen: actions.payload.open,
                selectedEmployee: actions.payload.selectedEmployee
            };
        case SET_ENTERPRISE_DETAILS_EMPLOYEE_MODAL:
            return {
                ...state,
                employeeDetailsModalOpen: actions.payload.open,
                selectedEmployee: actions.payload.selectedEmployee
            };
        case SET_ENTERPRISE_EMPLOYEES_ARCHIVED_MODAL:
            return {
                ...state,
                employeesArchivedModalOpen: actions.payload
            };
        default:
            return state;
    }
};

export default enterpriseReducer;
