import PropTypes from 'prop-types';
import { SpeakerType } from '../speaker';

export const EventType = {
    active: PropTypes.number,
    id: PropTypes.number,
    title: PropTypes.string,
    typeEvent: PropTypes.string,
    initialDate: PropTypes.string,
    finalDate: PropTypes.string,
    registers: PropTypes.number,
    registered: PropTypes.bool,
    like: PropTypes.bool,
    likes: PropTypes.number,
    image: PropTypes.string,
    description: PropTypes.string,
    competences: PropTypes.arrayOf(PropTypes.object),
    speakers: PropTypes.arrayOf(PropTypes.shape(SpeakerType))
};
