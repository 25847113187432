import useApi from '../api';

const useUserService = () => {
    const { api } = useApi();

    return {
        getResourceUser: () => api.get('user_resource').then((resp) => resp.data),
        createUser: (data) =>
            api
                .post('user', data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((resp) => resp.data),
        createUserFromInvite: (data) =>
            api
                .post('register', data, {
                    headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
                })
                .then((resp) => resp.data),
        getCityPerIdState: (id) => api.get(`city_per_state/${id}`).then((resp) => resp.data),
        update: (data) => {
            data.append('_method', 'PUT');
            return api
                .post(`user/${data.get('id')}`, data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((resp) => resp.data);
        },
        updateProfileInfo: (data) => {
            return api
                .post(`user-profile/${data.get('id')}`, data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((resp) => resp.data);
        },
        blockThrough: (through, data) => api.post(`user/toggle_block/${through}`, data).then((resp) => resp.data)
    };
};
export default useUserService;
