import styled from 'styled-components';

import auth from '@assets/bgs/auth.png';

export const Navbar = styled.nav`
    width: 100%;
    height: 80px;
    /* margin: 0; */
    background-color: #3a6647;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;

    :after {
        content: '';
        position: absolute;
        right: 0;
        width: 20px;
        height: 80px;
        background: #8fc366;
    }

    .content {
        max-width: 1440px;
        padding: 0 20px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            /* height: 35px; */
        }

        .logo-title {
            @media (max-width: 767px) {
                width: 50%;
                min-width: 270px;
            }
        }
    }
`;

export const Container = styled.div`
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: #f8f8fd;
    margin-top: unset !important;
    padding: unset !important;
`;

export const Content = styled.div`
    display: flex;
    height: calc(100vh - 80px);

    .side-image {
        height: 100%;
        object-fit: cover;
        max-width: 47%;
        position: sticky;

        @media (max-width: 840px) {
            display: none;
        }
    }

    > main {
        overflow-y: auto;
        flex: 1;
        right: 0;
        width: 100%;
    }
`;
