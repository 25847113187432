import styled from 'styled-components';

import { Paper } from '@mui/material';

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    justify-content: ${({ justify }) => justify ?? 'space-evenly'};
    padding: 24px;
    gap: 25px;
    width: 100%;
    height: ${({ height }) => height ?? '140px'};

    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(81, 69, 159, 0.08);
    border-radius: 15px;
`;

export const PaperContainerSmall = styled(PaperContainer)`
    padding: 12px;
    gap: 0px;
`;

export const Row = styled.div`
    display: flex;
    gap: 1rem;
`;
export const Col = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`;

export const PaperTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #25272e;
`;

export const PaperCount = styled.span`
    font-size: 2.25rem;
    font-weight: 700;
`;
