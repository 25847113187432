import React from 'react';

import PropTypes from 'prop-types';

import BaseModal from '@components/molecules/modals/BaseModal';

import { InfoText, Content } from './styles';

export const ReportZipReportsRequestModal = ({ handleClose, open }) => {
    return (
        <BaseModal handleClose={handleClose} open={open} title="Solicitação enviada com sucesso!" mdSize={'30%'}>
            <Content>
                <InfoText>Em breve, você receberá um e-mail com os relatórios mensais em anexo.</InfoText>
            </Content>
        </BaseModal>
    );
};

ReportZipReportsRequestModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};
