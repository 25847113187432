import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useSnackbar = () => {
    const dispatch = useDispatch();
    const toastProps = {};

    const showSnackbar = (message, type = 'success', autoClose = 6000) => {
        // dispatch({
        //     type: 'SET_SNACKBAR',
        //     payload: {
        //         text: message,
        //         type: type
        //     }
        // });
        switch (type) {
            case 'error':
                toast.error(message, { ...toastProps, autoClose });
                break;
            case 'warning':
                toast.warn(message, { ...toastProps, autoClose });
                break;
            case 'info':
                toast.info(message, { ...toastProps, autoClose });
                break;
            case 'success':
                toast.success(message, { ...toastProps, autoClose });
                break;
            default:
                toast(message, { ...toastProps, autoClose });
        }
    };

    return showSnackbar;
};

export default useSnackbar;
