import styled from 'styled-components';

import { Paper } from '@mui/material';

export const Section = styled.div`
    background: #ffffff;
    flex: 1;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #c6c6c6;
`;

export const PaperTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #25272e;
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    min-width: ${(props) => props.minWidth || null};
`;

export const Text = styled(PaperTitle)`
    font-weight: 400;
`;
