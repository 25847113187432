import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(205, 210, 217, 1);
    border-collapse: collapse;
`;
export const Item = styled.div`
    padding: 4px 8px;

    @media (max-width: 520px) {
        padding: 8px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid rgba(205, 210, 217, 1);
    }

    ${({ clickable }) =>
        clickable &&
        css`
            cursor: pointer;
            &:hover {
                background-color: rgba(205, 210, 217, 0.2);
            }
        `}
    display: flex;
    justify-content: space-between;
    border-collapse: collapse;

    @media (max-width: 520px) {
        flex-direction: column;
        gap: 5px;
    }
`;

export const ItemTitle = styled.span`
    color: rgba(37, 39, 46, 1);
    font-weight: 600;
    font-size: 14px;
`;
export const ItemValue = styled.span`
    display: flex;
    align-items: center;
    gap: 20px;
    color: rgba(58, 102, 71, 1);
    font-weight: 400;
    font-size: 14px;
    /* text-align: center; */

    @media (max-width: 520px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    .badge {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &.badge-success {
            background-color: #80ca37;
        }

        &.badge-warning {
            background-color: #ed9f4a;
        }
    }
`;
