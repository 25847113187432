import React, { useEffect, useState } from 'react';

import Button from 'components/molecules/buttons/Button';
import QuestionFeedback from 'components/molecules/cards/QuestionFeedback';
import PropTypes from 'prop-types';
import { QuizQuestionType } from 'types/components/quiz';

import { CancelRounded, CheckCircle } from '@mui/icons-material';
import { Radio, RadioGroup } from '@mui/material';

import { Container, Option, Form } from './styles';

const MultipleQuestion = ({ question, index, questionId, alternatives, askingUser, setResponseTest }) => {
    const handleOptionChange = (event, questionId) => {
        const selectedOption = event.target.value;
        setResponseTest(index, selectedOption, questionId); // Chame a função setResponseTest para atualizar a resposta no componente Quiz
    };

    return (
        <Container>
            <header>
                {/* <h2>Questão {questionNumber}</h2> */}
                <p>{question}</p>
            </header>

            {askingUser ? (
                <Form>
                    <RadioGroup>
                        {alternatives.map((option, key) => (
                            <>
                                <Option
                                    value={option.id}
                                    key={key}
                                    control={
                                        option.is_correct === 1 && askingUser.alternative_id === option.id ? (
                                            <Radio
                                                icon={<CheckCircle htmlColor="#87D04D" />}
                                                checkedIcon={<CheckCircle htmlColor="#87D04D" />}
                                            />
                                        ) : option.is_correct === 0 && askingUser.alternative_id === option.id ? (
                                            <Radio
                                                icon={<CancelRounded htmlColor="#DB5252" />}
                                                checkedIcon={<CancelRounded htmlColor="#DB5252" />}
                                            />
                                        ) : (
                                            <Radio />
                                        )
                                    }
                                    label={option.description}
                                    disabled
                                />
                            </>
                        ))}
                    </RadioGroup>
                </Form>
            ) : (
                <Form>
                    <RadioGroup>
                        {alternatives.map((option, key) => (
                            <Option
                                value={option.id}
                                key={key}
                                control={<Radio onChange={(e) => handleOptionChange(e, questionId)} />}
                                label={option.description}
                            />
                        ))}
                    </RadioGroup>
                </Form>
            )}
        </Container>
    );
};

MultipleQuestion.propTypes = {
    question: PropTypes.shape(QuizQuestionType).isRequired,
    onChoose: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    previousAnswer: PropTypes.array,
    alternatives: PropTypes.array.isRequired
};

export default MultipleQuestion;
