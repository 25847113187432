import { cpf } from 'cpf-cnpj-validator';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { formatPhoneNumber } from '@/utils/helpers/phoneNumber';
import noImage from '@assets/images/no_image.jpeg';

import { InputGruop, InputRole, ItemAvatar, Seniority } from './styles';

export const ProfileItem = ({ profile }) => {
    return (
        <>
            <InputGruop container spacing={2}>
                <InputRole>
                    <div className="type">
                        <h3>Tipo:</h3>
                        {profile.user?.roles?.map((res, index) => (
                            <p key={res.id}>{`${res.description} ${index + 1 == profile.user.roles.length ? '' : '/'}`}</p>
                        ))}
                        {/* <p>Consultor</p> */}
                    </div>
                    <Seniority level={profile.seniority}>
                        <span className={`level ${profile.seniority}`}>{profile.seniority}</span>
                    </Seniority>
                    {profile?.responsible && (
                        <div className="supervisor">
                            <h3>Supervisor: </h3>
                            <p>{profile.responsible?.name}</p>
                        </div>
                    )}
                </InputRole>
                <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    <ItemAvatar>
                        <img src={profile.photo != null ? profile.photo : noImage} alt="Imagem usuario" />
                    </ItemAvatar>
                    <Grid item xs={4} md={4}>
                        <h4>Nome:</h4>
                        <p>{profile.name}</p>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <h4>CPF:</h4>
                        <p>{cpf.format(profile.cpf)}</p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item xs={4} md={4}>
                        <h4>Chamado por:</h4>
                        <p>{profile.nickname}</p>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <h4>Data de nascimento:</h4>
                        <p>{moment(profile.birthDate).format('D/M/Y')}</p>
                    </Grid>
                </Grid>
            </InputGruop>
            <InputGruop container spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} xl={6}>
                        <h4>E-mail:</h4>
                        <p>{profile.email}</p>
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                        <h4>Celular:</h4>
                        <p>{formatPhoneNumber(profile.phone)}</p>
                    </Grid>
                </Grid>
            </InputGruop>
            <InputGruop container spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <h4>Endereço: </h4>
                        <p>{profile.address?.street}</p>
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                        <span>Número: </span>
                        <span>{profile.address?.number}</span>
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                        <span>Complemento: </span>
                        <span>{profile.address?.complement && profile.address?.complement}</span>
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                        <span>CEP: </span>
                        <span>{profile.address?.zipcode}</span>
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                        <span>Cidade: </span>
                        <span>{profile.address?.city + ' - ' + profile.address?.district}</span>
                    </Grid>
                </Grid>
            </InputGruop>
            <InputGruop container spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={4} md={4}>
                        <h4>Formação:</h4>
                        <p>{profile.graduation}</p>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <h4>Ano de formatura:</h4>
                        <p>{profile.graduationCompletedAt}</p>
                    </Grid>
                </Grid>
            </InputGruop>
            <InputGruop container spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <h4>Já fez algum curso do Rehagro?</h4>
                        <p>{profile.rehagroCourse == 0 ? 'Não' : 'Sim, ' + profile.whichCourse}</p>
                    </Grid>
                </Grid>
            </InputGruop>
        </>
    );
};

ProfileItem.propTypes = {
    profile: PropTypes.object
};
