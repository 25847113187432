import styled from 'styled-components';

import { Paper, TextField } from '@mui/material';

export const Container = styled.div`
    flex: 1;
    /* padding: 0 24px; */
`;

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    justify-content: ${({ justify }) => justify ?? 'space-evenly'};
    padding: 24px;
    gap: 32px;
    width: 100%;

    box-shadow: 0px 3px 12px rgba(81, 69, 159, 0.08);
    border-radius: 15px;
`;

export const GraphicWrapper = styled.div`
    height: ${({ height }) => height ?? '345px'};
    width: 100%;
`;

export const SectorsWrapper = styled.div`
    flex: 1;
    width: 100%;
    padding: 24px;
    border: 1px solid #c3c3c3;
    border-radius: 8px;
`;

export const SectorsHeader = styled.div`
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;

    span {
        font-weight: 400;
    }
`;

export const SectorsContent = styled.div`
    display: flex;
    gap: 64px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }

    .progress-bars {
        padding: unset !important;
    }
`;

export const SectorsDivider = styled.div`
    width: 1px;
    /* height: 100%; */
    background: #9ba1a9;
    /* border: 1px solid #9ba1a9; */

    @media (max-width: 768px) {
        display: none;
        /* width: 100%;
        height: 1px; */
    }
`;

export const ActionWrapper = styled.div`
    width: 100%;
`;

export const ActionsContainer = styled.div`
    /* display: flex;
    flex-direction: row;
    gap: 16px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem 0;
    width: 100% !important;

    @media (max-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
`;

export const ActionCardWrapper = styled.div`
    display: flex;
    flex: 1;
`;

export const ActionCard = styled.div`
    position: relative;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #cdd2d9;
    border-radius: 16px 0 0 16px;
    min-height: 125px;

    span {
        font-weight: 700;
        font-size: 1.25rem;

        &.value {
            font-size: 28px;
        }
    }
`;

export const ActionStatus = styled.div`
    width: 8px;
    /* position: absolute; */
    right: 0;
    top: 0;
    background-color: ${({ bgcolor }) => bgcolor};
    height: 100%;
    border-radius: 0 16px 16px 0;
`;

export const Row = styled.div`
    display: flex;
    gap: 1rem;
`;
export const Col = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`;

export const PaperTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #25272e;
`;

export const PaperCount = styled.span`
    font-size: 2.25rem;
    font-weight: 700;
`;

export const Div = styled.div`
    width: ${({ width }) => width ?? '6.5rem'};
    display: flex;
    align-items: center;
    gap: 1.25rem;

    justify-content: flex-end;

    > p {
        font-size: 20px;
    }

    @media (max-width: 550px) {
        width: auto;
    }
`;

export const ContainerYears = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 550px) {
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

export const TextFildStyled = styled(TextField)`
    width: 15rem;
    background-color: white;
`;
