import useApi from '../api';

const useCourseService = () => {
    const { api } = useApi();
    return {
        getCoursePerId: (id) => api.get(`course/${id}`).then((resp) => resp.data),
        completedClass: (id) => api.post(`class_user/is_completed/${id}`).then((resp) => resp.data),
        courseUserInitial: (id) => api.post(`course_user/initial/${id}`).then((resp) => resp.data),
        likeCourse: (id) => api.post(`course_user/like/${id}`).then((resp) => resp.data),
        stopClass: (id, data) => {
            const payload = {
                time_stop: data
            };

            return api.post(`class_user/time_stop/${id}`, payload).then((resp) => resp.data);
        },
        getAllCourses: (role, enterpriseId) => api.get(`course`, { params: { role, enterpriseId } }).then((resp) => resp.data),
        finishTest: (allData) => {
            const payload = {
                data: allData
            };

            return api.post('asking_user', payload).then((resp) => resp.data);
        },
        finishCourse: (userId, courseId) => {
            const payload = {
                userId: userId,
                courseId: courseId
            };
            return api.post(`course_user/finish/`, payload).then((resp) => resp.data);
        },
        generateCourseCertificate: (userId, courseId) => {
            return api
                .get(`course_user/certificate/`, {
                    params: {
                        userId,
                        courseId
                    }
                })
                .then((resp) => resp.data);
        }
    };
};

export default useCourseService;
