import { isMoment } from 'moment';

export const formikFormData = (object, formData = new FormData(), parentKey = '') => {
    if (object === null || typeof object === 'undefined') {
        return formData;
    }

    Object.keys(object).forEach((key) => {
        const value = object[key];
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (isMoment(value)) {
            formData.append(fullKey, value);
        } else if (typeof value === 'object' && !Array.isArray(value)) {
            formikFormData(value, formData, fullKey);
        } else if (Array.isArray(value) && value.every((item) => item instanceof Blob)) {
            value.forEach((item, index) => {
                formData.append(`${fullKey}[${index}]`, item, item.name);
            });
        } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
                const itemKey = `${fullKey}[${index}]`;
                formikFormData(item, formData, itemKey);
            });
        } else if (typeof value === 'boolean') {
            formData.append(fullKey, value ? 1 : 0);
        } else {
            formData.append(fullKey, value);
        }
    });

    return formData;
};
