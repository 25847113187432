import React from 'react';

import { Container, Item } from './styles';

const InfoList = ({ data }) => {
    return (
        <Container>
            {data.map((item, key) => (
                <Item key={`item-${key}`}>
                    <span>{item.title}</span>
                    <span>{item.value}</span>
                </Item>
            ))}
        </Container>
    );
};

export default InfoList;
