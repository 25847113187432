import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ResultLogo } from '@assets/icons/Rehsult_icon_white.svg';
import Cow from '@assets/images/cow.svg';
import imgLogo from '@assets/images/logo-white.png';
import plataformaTitle from '@assets/images/plataforma-titulo.png';
import imgSide from '@assets/images/signup-left.png';
import Spacer from '@components/atoms/structure/Spacer';

import { Container, Navbar, Content } from './styles';

const SignupLayout = ({ children }) => {
    return (
        <Container>
            <Navbar>
                <div className="content">
                    <ResultLogo style={{ width: '151px', height: '36px' }} />
                    {/* <img src={plataformaTitle} className="logo-title" alt="" /> */}
                </div>
            </Navbar>
            <Content>
                <img className="side-image" src={Cow} alt="" />
                <main>{children}</main>
            </Content>
        </Container>
    );
};

SignupLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default SignupLayout;
