import React from 'react';

import useApi from '@services/api';

const useOnboardService = () => {
    const { api } = useApi();
    return {
        register: (onboard) => api.post('store-onboard', { onboard }).then((resp) => resp.data)
    };
};

export default useOnboardService;
