export function redirectUser(id, path) {
    switch (id) {
        case 1:
            return `/admin/${path}`;
        case 2:
            return `/consultor/${path}`;
        case 3:
            return `/empresa/${path}`;
        case 4:
            return `/coordenador/${path}`;
        case 5:
            return `/supervisor/${path}`;

        default:
            return `/${id}`;
    }
}
