import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { cpf } from 'cpf-cnpj-validator';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

import useSnackbar from '@/hooks/useSnackbar';
import useEnterpriseService from '@/services/enterprise';
import useUserService from '@/services/user';
import LoadingComponent from '@components/templates/LoadingComponent';

import { EmployeesForm } from '../../EmployeesForm';
import { RegisteredAlert } from './RegisteredAlert';
import { Container, SubmitContent } from './styles';

const employeeValidation = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    email: Yup.string().required('O email é obrigatório').email('Insira um email válido'),
    // cpf: Yup.string().required('O CPF é obrigatório').min(11, 'CPF inválido'),
    cpf: Yup.string()
        .test('cpf-validation', 'O CPF é inválido', (value) => cpf.isValid(value))
        .required('O CPF é obrigatório'),
    cityName: Yup.string().required('O Cidade é obrigatório'),
    district: Yup.string().required('O Estado é obrigatório'),
    birthDate: Yup.string().required('O Data de nascimento é obrigatório'),
    graduation: Yup.string().required('A Formação é obrigatório'),
    graduationCompletedAt: Yup.string().required('Ano de formatura é obrigatório')
});

const FormSignUp = () => {
    const location = useLocation();
    const { createUserFromInvite } = useUserService();
    const { getEnterpriseInviteUser } = useEnterpriseService();

    const snackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingComponent, setLoadingComponent] = useState(true);
    const [wasRegistred, SetWasRegistred] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            nickname: '',
            email: '',
            cpf: '',
            zipcode: '',
            cityName: '',
            district: '',
            number: '',
            birthDate: '',
            graduation: '',
            graduationCompletedAt: '',
            toUpload: []
        },
        validationSchema: employeeValidation,
        onSubmit: () => {
            setIsLoading(true);
            const cpf = formik.values.cpf.replace(/[^\d]/g, '');
            const phone = formik.values.phone.replace(/[^\d]/g, '');
            let date = formik.values.birthDate.split('/');

            const birthDate = `${date[2]}-${date[1]}-${date[0]}`;

            const formData = new FormData();

            formData.append('email', formik.values.email);
            formData.append('password', cpf);
            formData.append('idRole', formik.values.idRole);
            formData.append('information[name]', formik.values.name);
            formData.append('information[nickname]', formik.values.nickname);
            formData.append('information[cpf]', cpf);
            formData.append('information[phone]', phone);
            formData.append('information[idEnterprise]', formik.values.idEnterprise);
            formData.append('information[birthDate]', birthDate);
            formData.append('information[rehagroCourse]', formik.values.rehagroCourse);
            formData.append('information[whichCourse]', formik.values.whichCourse);
            formData.append('information[graduation]', formik.values.graduation);
            formData.append('information[graduationCompletedAt]', formik.values.graduationCompletedAt);

            if (typeof formik.values.toUpload !== 'undefined') {
                formData.append('information[toUpload]', formik.values.toUpload);
            }

            formData.append('address[cityName]', formik.values.cityName);
            formData.append('address[district]', formik.values.district);
            formData.append('address[zipcode]', formik.values.zipcode);
            formData.append('address[street]', formik.values.street);
            formData.append('address[number]', formik.values.number);
            formData.append('address[complement]', formik.values.complement);

            createUserFromInvite(formData)
                .then((resp) => {
                    snackbar('Colaborador cadastrado com sucesso', 'success');
                    SetWasRegistred(true);
                })
                .catch((error) => {})
                .finally(() => {
                    setIsLoading(false);
                });
        }
    });

    const getUserData = async () => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        try {
            const { data } = await getEnterpriseInviteUser(token);
            if (!data.id) {
                snackbar('Convite não encontrado ou expirado', 'error');
                return;
            }

            formik.setValues({
                email: data.email,
                idRole: '',
                password: '',
                name: data.name || '',
                nickname: '',
                phone: data.phone,
                cpf: '',
                idEnterprise: data.idEnterprise,
                birthDate: '',
                seniority: '',
                photo: `https://eu.ui-avatars.com/api/?background=015641&color=ffffff&bold=true&rounded=true&font-size=0.6&size=128&length=1&name=${data.name}'`,
                enterpriseEmployeeId: '',
                graduation: '',
                graduationCompletedAt: '',
                rehagroCourse: 0,
                whichCourse: '',
                toUpload: [],
                district: '',
                cityName: '',
                idCity: '',
                street: '',
                number: '',
                complement: ''
            });

            setLoadingComponent(false);
        } catch (error) {
            snackbar('Convite não encontrado ou expirado', 'error');
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    if (loadingComponent) {
        return <LoadingComponent />;
    }

    if (wasRegistred) {
        return <RegisteredAlert />;
    }

    return (
        <Container>
            <header className="header">
                <h2>Cadastro de Usúario</h2>

                <hr />
                <div>
                    <p>Por favor, preencha o formulário abaixo.</p>
                    <p>Todos os campos são obrigatórios.</p>
                </div>
            </header>

            <div className="content">
                <EmployeesForm formik={formik} />
                <SubmitContent>
                    <LoadingButton
                        onClick={() => formik.handleSubmit()}
                        loading={isLoading}
                        loadingPosition="start"
                        variant="contained"
                        startIcon={<SaveOutlinedIcon />}
                    >
                        <span>Cadastrar</span>
                    </LoadingButton>
                </SubmitContent>
            </div>
        </Container>
    );
};

export default FormSignUp;
