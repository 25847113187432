import styled from 'styled-components';

export const Container = styled.div`
    border-radius: 0.5rem;
    background-color: #f0f1f3;
    width: 100%;
`;

export const Item = styled.div`
    padding: 1rem;
    border-top: 1px solid white;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
`;
