import styled from 'styled-components';

import Button from '@mui/material/Button';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1143px) {
        flex-direction: column;
        gap: 1rem;
        justify-content: flex-start;
        align-items: start;
    }
`;

export const HeaderStyled = styled.header`
    height: 21%;
    display: flex;
    align-items: end;
    justify-content: start;
    border-bottom: 1px solid #9ba1a9;
    gap: 0.5rem;
`;
export const ButtonMenu = styled(Button)`
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #000;
    height: 48px;
    margin-left: 4px;
    text-transform: none;

    &:hover {
        background-color: #ebefed;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    > h3 {
        padding: 0 0.7rem;
    }
`;
