import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 36.8125rem;
    padding: 0.75rem;
    justify-content: space-between;
    align-items: center;

    border-radius: 0rem 0rem 1rem 1rem;
    background: var(--escala-de-cinza-branco, #fff);

    /* Card */
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);

    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;

    .info {
        display: flex;
        gap: 1rem;

        .image {
            width: 3rem;
            height: 3rem;
            border-radius: 3rem;
            overflow: hidden;

            * {
                object-fit: cover;
            }
        }

        .data {
            display: flex;
            flex-direction: column;
            flex-shrink: 1;

            .level {
                display: inline;

                font-size: 12px;
                font-weight: 700;

                color: #ffffff;

                & span {
                    padding: 2px 8px;
                    border-radius: 8px;
                }

                &.Trainee span {
                    background-color: #e1eae1;
                    color: #000;
                }

                &.Junior span {
                    background-color: #e1eae1;
                    color: #000;
                }

                &.Pleno span {
                    background-color: #efefdb;
                    color: #000;
                }

                &.Senior span {
                    background-color: #eee7f0;
                    color: #000;
                }
            }
        }
    }
`;
