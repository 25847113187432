import React from 'react';

import Message from 'components/molecules/alerts/Message';
import NotificationCard from 'components/molecules/cards/NotificationCard';
import PropTypes from 'prop-types';
import { NotificationType } from 'types/data/notifications';

import { Container, Notifications } from './styles';

const NotificationsList = ({ notifications, removeNotification }) => {
    if (!notifications || !notifications.length) {
        return <Message text="Todas as notificações já foram vistas" />;
    }

    return (
        <Container>
            <Notifications>
                {notifications.map((notification, index) => (
                    <NotificationCard key={index} notification={notification} remove={removeNotification} />
                ))}
            </Notifications>
        </Container>
    );
};

NotificationsList.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape(NotificationType)),
    removeNotification: PropTypes.func.isRequired
};

export default NotificationsList;
