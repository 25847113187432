import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: flex-start;

    > h3 {
        font-size: 1.8rem;
        margin: 0;
        padding: 0;
        padding-right: 0.5rem;
        color: var(--color-primary);
    }

    > p {
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
        font-weight: 700;
        color: var(--color-black);
    }
`;
