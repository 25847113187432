import React from 'react';
import { Chart } from 'react-google-charts';

import PropTypes from 'prop-types';

import { NoData, NoDataContent } from './styles';

const Bar = (props) => {
    return (
        <>
            {props.allData.length > 0 ? (
                <Chart
                    chartType="BarChart"
                    width={props.width}
                    height={props.height || '400px'}
                    data={props.allData}
                    options={props.options}
                />
            ) : (
                <NoDataContent>
                    <NoData>Nenhum dado encontrado</NoData>
                </NoDataContent>
            )}
        </>
    );
};

Bar.propTypes = {
    title: PropTypes.string,
    width: PropTypes.string,
    allData: PropTypes.array.isRequired,
    options: PropTypes.object
};
export default Bar;
