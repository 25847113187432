export const initialState = {
    text: '',
    type: ''
};

const snackbarReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_SNACKBAR':
            return {
                ...state,
                text: actions.payload.text,
                type: actions.payload.type
            };

        default:
            return state;
    }
};

export default snackbarReducer;
