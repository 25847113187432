import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { VisibilityOutlined } from '@mui/icons-material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

import { Container } from './styles';

export const OutlinedInputPassword = ({
    id,
    error,
    value,
    handleBlur,
    onChange,
    label,
    errorText,
    startAdornment,
    size,
    autoComplete = 'off',
    inputProps = {}
}) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Container fullWidth error={error}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                type={showPassword ? 'text' : 'password'}
                value={value}
                name={id}
                size={size || 'small'}
                onBlur={handleBlur}
                onChange={onChange}
                label={label}
                autoComplete={autoComplete}
                inputProps={inputProps}
                startAdornment={startAdornment}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            size="large"
                        >
                            {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {error && (
                <FormHelperText error id={`${id}-error`}>
                    {errorText}
                </FormHelperText>
            )}
        </Container>
    );
};

OutlinedInputPassword.propTypes = {
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.any,
    size: PropTypes.string,
    onChange: PropTypes.func,
    handleBlur: PropTypes.func,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    startAdornment: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    autoComplete: PropTypes.string,
    inputProps: PropTypes.object
};
