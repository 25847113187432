import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import Bar from '@/components/BI/graphicBar';
import { Loading } from '@/pages/Common/EnterpriseDetails/EnterprisePage/tabs/Properties/components/GraphMilk/Loading';
import usePropertyService from '@/services/property';

import { PaperTitle } from '../../styles';

const categories = {
    200: 0,
    400: 0,
    600: 0,
    800: 0,
    1000: 0,
    '+1000': 0
};

const GraphMilk = ({ idEnterprise }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [graphBarData, setGraphBarData] = useState([]);

    const propertyService = usePropertyService();

    const options = {
        chartArea: { width: '80%', height: '80%' },
        explorer: { axis: 'horizontal', keepInBounds: false },
        orientation: 'horizontal',
        legend: { position: 'none' },
        fontSize: 12,
        tooltip: {
            ignoreBounds: false,
            textStyle: {
                bold: true
            }
        },
        annotations: {
            textStyle: {
                fontSize: 15,
                bold: true,
                color: '#000'
            }
        },
        vAxis: { textPosition: 'none' }
    };

    function somaDosValores(objeto) {
        return Object.values(objeto).reduce((acumulador, valorAtual) => acumulador + parseInt(valorAtual, 10), 0);
    }

    function handleGraphBarData(data) {
        const formattedData = [['Categoria', 'Quantidade', { role: 'style' }, { role: 'annotation' }]];
        const total = somaDosValores(data);

        Object.keys(categories).forEach((category) => {
            const quantity = data[category] ? parseInt(data[category], 10) : 0;
            const currentColor =
                category === '200' ? '#C2D9FF' : category === '400' ? '#A0C2FB' : category === '600' ? '#73A4F7' : '#407ADC';
            const annotation = parseInt((quantity / total) * 100);

            formattedData.push([`${category} L/dia`, quantity, currentColor, `${annotation}%`]);
        });
        setGraphBarData(formattedData);
    }

    const handleData = (data) => {
        setIsLoading(true);
        propertyService
            .getPropertyByMilk(idEnterprise, data)
            .then((resp) => {
                handleGraphBarData(resp);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleData(categories);
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaperTitle>Nível das Propriedades / produção diária</PaperTitle>
                </Grid>

                <Grid item xs={12} height={'20.5rem'}>
                    {graphBarData.length > 0 && <Bar height="100%" width="100%" allData={graphBarData} options={options} />}
                </Grid>
            </Grid>
        </>
    );
};

export default GraphMilk;
