import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    .MuiSvgIcon-root {
        color: ${(props) => (props.active ? '#c00' : props.color ?? '#333')};
        margin-top: -0.2rem;
        ${(props) =>
            props.noPaddingLeft &&
            css`
                margin-left: -0.5rem;
                margin-top: -0.25rem;
                margin-bottom: -0.25rem;
            `}
    }
`;
