import Cookies from 'js-cookie';

import { SET_LOGIN, SET_LOGOUT, SET_CURRENT_USER_ROLE } from '@/redux/mutations';

export const initialState = {
    loggedIn: false,
    user: {},
    currentUserRole: Cookies.get('currentUserRole') ? Number(Cookies.get('currentUserRole')) : null,
    viewAsConsultant: false
};

const authReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_AUTH':
            return {
                ...state,
                loggedIn: true,
                user: actions.payload
            };
        case SET_LOGIN:
            return {
                ...state,
                loggedIn: true,
                user: actions.payload
            };
        case SET_LOGOUT:
            return {
                ...state,
                loggedIn: false,
                user: {}
            };
        case SET_CURRENT_USER_ROLE:
            return {
                ...state,
                currentUserRole: actions.payload
            };
        case 'SET_VIEW_AS_CONSULTANT':
            return {
                ...state,
                viewAsConsultant: actions.payload
            };
        default:
            return state;
    }
};

export default authReducer;
