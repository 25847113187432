import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/theme/custom-toast.css';

import Hotjar from '@hotjar/browser';

import App from './App';
import { store } from './redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Import the service worker registration
import './clarity';
const root = ReactDOM.createRoot(document.getElementById('root'));
const { REACT_APP_HOTJARID, REACT_APP_HOTJARVERSION } = process.env;

const siteId = REACT_APP_HOTJARID ?? 5045247;

const hotjarVersion = REACT_APP_HOTJARVERSION ?? 6;
Hotjar.init(siteId, hotjarVersion);

root.render(
    <HelmetProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    draggable
                    pauseOnHover
                    theme="colored"
                    className="custom-toast-override"
                />
            </BrowserRouter>
        </Provider>
    </HelmetProvider>
);

// Register the service worker
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
