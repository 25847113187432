import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, MenuItem, TextField } from '@mui/material';

import Bar from '@/components/BI/graphicBar';
import HeaderTotalGraphic from '@/components/organisms/headers/HeaderTotalGraphic';
import LoadingComponent from '@/components/templates/LoadingComponent';
import { PropertiesPerConsultant } from '@/pages/Common/EnterpriseDetails/EnterprisePage/tabs/Properties/components/PropertiesPerConsultant';
import useManagerPanelService from '@/services/managerPanel';
import usePropertyService from '@/services/property';
import { handleDownloadData } from '@/utils/helpers/downloads';

import GraphMilk from './components/GraphMilk';
import { Div, PaperTitle, Section, Text } from './styles';

const monthTranslations = {
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro'
};

const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const Properties = ({ idEnterprise }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [property, setProperty] = useState('');
    const [filterPer, setFilterPer] = useState(null);
    const [filterPerAllOrBlocked, setFilterPerAllOrBlocked] = useState(0);
    const [filterPerYear, setFilterPerYear] = useState(0);
    const [constultantsFilter, setConsultantsFilter] = useState(null);
    const [graphBarData, setGraphBarData] = useState([]);
    const { getPropertiesPDFReport } = useManagerPanelService();

    const propertyService = usePropertyService();
    const dispatch = useDispatch();
    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);

    const options = {
        chartArea: { width: '80%', height: '80%' },
        colors: ['#73A4F7'],
        explorer: { axis: 'horizontal', keepInBounds: false },
        orientation: 'horizontal',
        legend: { position: 'none' },
        fontSize: 12,
        tooltip: {
            ignoreBounds: false,
            textStyle: {
                bold: true
            }
        },
        annotations: {
            textStyle: {
                fontSize: 15,
                bold: true,
                color: '#000'
            }
        },
        vAxis: {
            title: 'Quant. de propriedades',
            titleTextStyle: {
                italic: false,
                color: '#9BA1A9'
            }
        }
    };

    function handleGraphBarData(data) {
        const formattedData = [['Mês', 'Quantidade', { role: 'annotation' }]];
        allMonths.forEach((month) => {
            const translatedMonth = monthTranslations[month];
            const buscando = data.find((buscando) => buscando.month == month);
            const quantity = buscando ? buscando.quantity : 0;
            const annotation = quantity;
            formattedData.push([translatedMonth, quantity, annotation]);
        });

        setGraphBarData(formattedData);
    }

    const handleData = async (filter = '') => {
        setIsLoading(true);
        try {
            const resp = await propertyService.getCountPropertyPerMonth(
                idEnterprise,
                filter,
                filterPerAllOrBlocked === 0 ? '' : filterPerAllOrBlocked
            );
            setProperty(resp);
            // if (filterPer === null) {
            setFilterPer(resp.years[0]);
            // }

            if (resp?.countPerMonth?.countPerMonth.length) {
                handleGraphBarData(resp?.countPerMonth.countPerMonth);
            } else {
                setGraphBarData([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectOption = (option) => {
        setFilterPer(option);
        handleData(option);
    };

    const handleReport = async () => {
        try {
            dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
            const { data } = await getPropertiesPDFReport(idEnterprise, filterPer, constultantsFilter ?? '', filterPerAllOrBlocked);

            if (data) {
                handleDownloadData(data, `relatorio_de_propriedades_${filterPer}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({ type: 'MANAGER_PANEL_RESET' });
        }
    };

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    useEffect(() => {
        handleData();
    }, [filterPerAllOrBlocked]);

    return (
        <>
            <Section>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <HeaderTotalGraphic
                            title="Total Consolidado:"
                            description={(property?.countPerMonth?.totalProperties ?? 0) + ' Propriedades'}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PaperTitle>Propriedades cadastradas / ano</PaperTitle>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item>
                                        <Div minWidth={'100px'}>
                                            <TextField
                                                fullWidth
                                                size={'small'}
                                                select
                                                value={filterPer}
                                                onChange={(evt) => handleSelectOption(evt.target.value)}
                                            >
                                                {property.years
                                                    ? property.years.map((year) => (
                                                          <MenuItem key={year} value={year}>
                                                              {year}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </TextField>
                                            <TextField
                                                fullWidth
                                                size={'small'}
                                                select
                                                value={filterPerAllOrBlocked}
                                                onChange={(evt) => setFilterPerAllOrBlocked(evt.target.value)}
                                            >
                                                <MenuItem key={0} value={0}>
                                                    Todas
                                                </MenuItem>
                                                <MenuItem key={1} value={1}>
                                                    Bloqueadas
                                                </MenuItem>
                                            </TextField>
                                        </Div>
                                    </Grid>

                                    <Grid item>
                                        <PaperTitle style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                            {filterPer} <PaperTitle>|</PaperTitle>{' '}
                                            <Text>{property?.countPerMonth?.totalPropertiesYear} propriedades</Text>
                                        </PaperTitle>
                                    </Grid>
                                    {console.log('graphBarData', graphBarData)}
                                    <Grid item xs={12} height={graphBarData.length > 0 && '28.75rem'}>
                                        <Bar height="100%" width="100%" allData={graphBarData} options={options} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Section>

            {graphBarData.length > 0 && (
                <>
                    <Section style={{ marginTop: '2rem' }}>
                        <GraphMilk idEnterprise={idEnterprise} />
                    </Section>

                    <Section style={{ marginTop: '2rem' }}>
                        <PropertiesPerConsultant
                            filterPerAllOrBlocked={filterPerAllOrBlocked === 0 ? '' : filterPerAllOrBlocked}
                            years={property.years}
                            enterpriseId={idEnterprise}
                            changeFilter={(val) => setConsultantsFilter(val)}
                        />
                    </Section>
                </>
            )}
        </>
    );
};

export default Properties;
