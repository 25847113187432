import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
    margin-top: 10px;
    width: 20%;
    height: 5rem;
`;
