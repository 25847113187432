import React, { useEffect, useState } from 'react';

import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import DateHorizontal from 'components/atoms/texts/DateHorizontal';
import Button from 'components/molecules/buttons/Button';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import useSnackbar from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import useEventService from 'services/event';
import { EventType } from 'types/data/event';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { Container, Description, Title, BlankImage, Actions, Spacer, Cont } from './styles';

const EventPerId = ({ event }) => {
    const { id, title, description, image, initial_date, final_date, url, registered, likes } = event;

    const [qtdLikes, setQtdLikes] = useState(likes);
    const [isLiked, setLiked] = useState(event.event_user?.like ? true : false);
    const [isRegistered, setIsRegistered] = useState(registered);
    const [onLoad, setOnLoad] = useState(false);
    const { likeEvent, eventUserRegister, viewEvent } = useEventService();

    const snackbar = useSnackbar();

    const handleFavorite = () => {
        likeEvent(id)
            .then(() => {
                setLiked(!isLiked);
                !isLiked == true ? setQtdLikes(qtdLikes + 1) : setQtdLikes(qtdLikes - 1);
            })
            .catch((err) => {
                snackbar(err.error, 'error');
            });
    };

    const formatDate = (date) => {
        return format(new Date(date), 'dd/MM/yyyy HH:mm', { locale: ptBR });
    };

    const handleRegister = async (idEvent) => {
        setOnLoad(true);

        eventUserRegister(idEvent).then(() => {
            setIsRegistered(!isRegistered);
            setOnLoad(false);
        });
    };

    const redirectToEvent = async () => {
        try {
            if (event.event_user.view === 1) {
                window.open(url, '_blank');
                setOnLoad(false);
                return;
            }

            viewEvent(id)
                .then(() => {
                    window.open(url, '_blank');
                    setOnLoad(false);
                })
                .catch((err) => {
                    setOnLoad(false);
                });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        event.event_user !== null && setIsRegistered(event.event_user.registered);
    }, [event]);

    return (
        <Cont>
            <Container>
                <header>
                    <aside>
                        <DateHorizontal dateString={initial_date} />
                    </aside>
                    <main>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                <AccessTimeIcon fontSize="40px" />
                                <p style={{ fontWeight: 500, fontSize: '12px' }}>De: {formatDate(initial_date)}</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                <AccessTimeIcon fontSize="40px" />
                                <p style={{ fontWeight: 500, fontSize: '12px' }}>Ate: {formatDate(final_date)}</p>
                            </div>
                        </div>
                    </main>
                </header>

                <main>
                    <header>{image ? <img src={image} alt={title} /> : <BlankImage />}</header>

                    <Title>
                        <h1>{title}</h1>
                    </Title>

                    <Description>
                        <div>
                            <h3>Descrição</h3>
                            <p>{description}</p>
                        </div>
                    </Description>

                    <Actions>
                        <div className="footer-aside__item">
                            <FavoriteCheckbox active={isLiked} setActive={handleFavorite} noPaddingLeft />
                            <p>{qtdLikes}</p>
                        </div>
                        <Spacer />
                    </Actions>
                </main>
                <footer>
                    <>
                        {isRegistered > 0 && !!event.url && (
                            <Button disabled={onLoad} color="secondary" onClick={() => redirectToEvent()} type="button">
                                Ir para o evento
                            </Button>
                        )}
                        <Button
                            disabled={onLoad}
                            color="secondary"
                            onClick={() => {
                                handleRegister(id);
                            }}
                            type="button"
                        >
                            {isRegistered ? 'Cancelar Inscrição' : 'Participar!'}
                        </Button>
                    </>
                </footer>
            </Container>
        </Cont>
    );
};

EventPerId.propTypes = {
    event: PropTypes.shape(EventType).isRequired
};

export default EventPerId;
