import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 12px;
    padding: 5px;

    .actions {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
`;
