import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 16px;
`;

export const NotificationsContent = styled.div`
    position: relative;
`;

export const NotificationBadge = styled.div`
    position: absolute;
    background-color: red;
    top: 4px;
    right: 8px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 2px solid #ffffff;
`;
