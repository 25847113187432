import styled from 'styled-components';

export const Container = styled.a`
    height: 30px;
    width: 30px;
    background-color: #e7f5e6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${({ type }) => (type === 'right' ? 'margin-right: -0.5rem;' : 'margin-left: -0.5rem;')}
`;
