import styled from 'styled-components';
import { css } from 'styled-components';

import { Button } from '@mui/material';

export const Container = styled(Button)`
    width: ${(props) => (props.width ? `${props.width}` : '100%')};
    padding: ${(props) => (props.size === 'small' ? '2px 8px' : props.size === 'fat' ? '4px 16px' : props.size === 'thin' ? '5px' : '5px')};
    border-radius: var(--border-radius);
    text-transform: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    font-size: ${(props) => (props.size === 'small' || props.size === 'fat' ? '0.8rem' : '1rem')};
    background-color: ${({ bgColor }) => (bgColor ? bgColor : 'var(--color-primary)')};
    height: ${({ height }) => (height ? height : '44px')};

    :hover {
        background-color: ${({ bgColor }) => bgColor ?? 'var(--color-primary-dark)'};
    }

    ${(props) =>
        props.color === 'neutral' &&
        css`
            color: #9ba1a9 !important;
            box-shadow: none;
        `}

    ${(props) =>
        props.color === 'light' &&
        css`
            font-weight: 700;
            color: var(--color-primary);
        `}
`;
