import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100%;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #cfdcd3;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
export const ProgressContainer = styled.div`
    width: 100%;
    background-color: #f9f9fa;
    border-radius: 0.5rem;
`;

export const ActionsPlansProgressTitle = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }

    .created-actions {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
    }
`;
