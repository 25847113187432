import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { Grid, MenuItem, TextField } from '@mui/material';

import GraphicProgressBar from '@/components/BI/GraphicProgressBar';
import InfoList from '@/components/BI/InfoList';
import { ActionsPlansProgressTitle, ProgressContainer } from '@/components/molecules/modals/EmployeeProfileModal/EmployeePerformanceStyle';
import useEnterpriseEmployeesService from '@/services/enterprise-employees';

import { Div } from '../../styles';
import { P } from './styles';

const SectionPerformance = ({ selectedConsultant, changeDate }) => {
    const { getCollaboratorPerformance } = useEnterpriseEmployeesService();

    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);

    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const [perfomanceData, setPerformanceData] = useState({});
    const [progressData, setProgressData] = useState([]);
    const [infoListData, setInfoListData] = useState([
        [
            {
                title: 'Total de Propriedades:',
                value: '--'
            }
        ],
        [
            {
                title: 'Total geral de visitas agendadas:',
                value: '--'
            },
            {
                title: 'Relatórios entregues (aprovados):',
                value: '--'
            }
        ],
        [
            {
                title: 'Total de ações ativas:',
                value: '--'
            },
            {
                title: 'Total de ações arquivadas:',
                value: '--'
            }
        ]
    ]);

    const getPerformanceData = async () => {
        try {
            const date = moment(`${selectedYear}-${moment().month(selectedMonth).format('MM')}-01T00:00:00-03:00`).format();
            changeDate(date);

            const { data } = await getCollaboratorPerformance(selectedConsultant.id, date);

            setPerformanceData(data);

            const infoList = [
                [
                    {
                        title: 'Total de Propriedades:',
                        value: data.qtdCollaboratorProperty ?? 0
                    }
                ],
                [
                    {
                        title: 'Total geral de visitas agendadas:',
                        value: data.qtdVisits ?? 0
                    },
                    {
                        title: 'Relatórios entregues (aprovados):',
                        value: data.qtdReportsApproved ?? 0
                    }
                ],
                [
                    {
                        title: 'Total de ações ativas:',
                        value: data.actionPlansActions ?? 0
                    },
                    {
                        title: 'Total de ações arquivadas:',
                        value: data.actionPlansAndCountActionsArchived ?? 0
                    }
                ]
            ];

            setInfoListData(infoList);

            const progress = data.actionPlansAndCountActions.map((item) => ({
                title: item.area_name,
                subtitle: 'ações',
                progress: item.actions_count
            }));

            setProgressData(progress);
        } catch (error) {
            console.log(error);
        }
    };

    const generateYearsOptions = () => {
        if (selectedConsultant && selectedConsultant.created_at) {
            const consultantCreatedAt = new Date(selectedConsultant.created_at);
            const currentYear = new Date().getFullYear();

            const startYear = consultantCreatedAt.getFullYear();
            const yearsOptions = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);

            setYears(yearsOptions.reverse());
            setSelectedYear(yearsOptions[0]);
            changeDate(yearsOptions[0]);
        }
    };

    const generateMonthsOptions = () => {
        if (selectedYear && selectedConsultant && selectedConsultant.created_at) {
            const consultantCreatedAt = new Date(selectedConsultant.created_at);
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            let startMonth = 1;
            let endMonth = 12;

            if (selectedYear === currentYear) {
                endMonth = currentMonth;
            }

            if (selectedYear === consultantCreatedAt.getFullYear()) {
                startMonth = consultantCreatedAt.getMonth() + 1;
            }

            const monthOptions = Array.from({ length: endMonth - startMonth + 1 }, (_, index) => {
                const monthNumber = startMonth + index;
                const monthName = [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ][monthNumber - 1];

                return {
                    value: monthNumber,
                    label: monthName
                };
            });

            setMonths(monthOptions);
            setSelectedMonth(monthOptions[0].label); // Define o último mês como selecionado
        }
    };

    const handleSelectYear = (selectedYear) => {
        setSelectedYear(selectedYear);
    };

    const handleSelectMonth = (selectedMonth) => {
        setSelectedMonth(selectedMonth);
    };

    useEffect(() => {
        generateYearsOptions();
    }, [selectedConsultant]);

    useEffect(() => {
        generateMonthsOptions();
    }, [selectedYear, selectedConsultant]);

    useEffect(() => {
        getPerformanceData();
    }, [selectedYear, selectedMonth, selectedConsultant]);

    return (
        <>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <h3>Dados do período:</h3>
            </Grid>
            <Grid item>
                <Div width={'120px'}>
                    <TextField fullWidth size={'small'} select value={selectedYear} onChange={(evt) => handleSelectYear(evt.target.value)}>
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>
                </Div>
            </Grid>

            <Grid item>
                <Div width={'120px'}>
                    <TextField
                        fullWidth
                        size={'small'}
                        select
                        value={selectedMonth}
                        onChange={(evt) => handleSelectMonth(evt.target.value)}
                    >
                        {months.map((month) => (
                            <MenuItem key={month.label} value={month.label}>
                                {month.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Div>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={'4rem'}>
                    <Grid item xs={12} md={4} display={'flex'} flexDirection={'column'} gap={'2rem'}>
                        {infoListData.map((data, key) => (
                            <InfoList key={`infoList-${key}`} data={data} />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ProgressContainer>
                            <GraphicProgressBar
                                title={
                                    <ActionsPlansProgressTitle>
                                        <span>
                                            <P>
                                                <b>Plano de Ação: </b>Áreas de atuação
                                            </P>
                                        </span>
                                        <div className="created-actions">
                                            <P>
                                                <b>Total de ações ativas: </b>
                                                {perfomanceData.actionPlansActions}
                                            </P>
                                        </div>
                                    </ActionsPlansProgressTitle>
                                }
                                data={progressData}
                                total={perfomanceData.actionPlansActions}
                            />
                        </ProgressContainer>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SectionPerformance;
