import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { MenuItem } from '@mui/material';

import Bar from '@/components/BI/graphicBar';
import GraphicProgressBar from '@/components/BI/GraphicProgressBar';
import HeaderTotalGraphic from '@/components/organisms/headers/HeaderTotalGraphic';
import useManagerPanelService from '@/services/managerPanel';
import { handleDownloadData } from '@/utils/helpers/downloads';
import { getEvaluationSectorTypeName } from '@/utils/helpers/evaluationsSectors';

import { useCheckAgro } from './hooks';
import {
    ActionCard,
    ActionCardWrapper,
    ActionsContainer,
    ActionStatus,
    ActionWrapper,
    ContainerYears,
    Div,
    GraphicWrapper,
    PaperContainer,
    PaperTitle,
    SectorsContainer,
    SectorsContent,
    SectorsDivider,
    SectorsHeader,
    SectorsWrapper,
    TextFildStyled
} from './styles';

const graphicOptions = {
    chartArea: { width: '80%', height: '80%' },
    colors: ['#B15FA4'],
    explorer: { axis: 'horizontal', keepInBounds: false },
    orientation: 'horizontal',
    legend: { position: 'none' },
    fontSize: 14,
    tooltip: {
        ignoreBounds: false,
        textStyle: {
            bold: true
        }
    },
    annotations: {
        textStyle: {
            fontSize: 15,
            bold: true,
            color: '#000'
        },
        boxStyle: {
            rx: 10,
            ry: 10
        }
    },
    vAxis: {
        title: 'Quant. de  Setores',
        titleTextStyle: {
            italic: false,
            color: '#9BA1A9'
        }
    }
};

export const CheckAgro = ({ idEnterprise }) => {
    const {
        selectRef,
        filterPer,
        handleFilter,
        years,
        totalPerYear,
        totalConsolidated,
        graphBarData,
        sectorsAverage,
        goToCheckAgroSectorDetails
    } = useCheckAgro(idEnterprise);

    const dispatch = useDispatch();
    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);
    const { getCheckAgroPDFReport } = useManagerPanelService();

    const handleReport = async () => {
        try {
            dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
            const { data } = await getCheckAgroPDFReport(idEnterprise, filterPer);

            if (data) {
                handleDownloadData(data, `relatorio_do_check_agro_${filterPer}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({ type: 'MANAGER_PANEL_RESET' });
        }
    };

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    return (
        <PaperContainer>
            <HeaderTotalGraphic title="Total Consolidado:" description={`${totalConsolidated} Setores avaliados`} />
            {/* <PaperContainer> */}
            <PaperTitle>Check Agro: Setores respondidos / ano</PaperTitle>
            <ContainerYears>
                <Div>
                    <TextFildStyled
                        ref={selectRef}
                        fullWidth
                        size={'small'}
                        select
                        value={filterPer}
                        onChange={(evt) => handleFilter(evt.target.value)}
                    >
                        {years?.map((value, key) => (
                            <MenuItem key={key} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextFildStyled>
                </Div>
                <Div width="18rem">
                    <p>
                        <b>{filterPer} |</b> {totalPerYear} Setores respondidos
                    </p>
                </Div>
            </ContainerYears>

            <GraphicWrapper height={graphBarData.length > 0 && '400px'}>
                <Bar height="100%" width="100%" allData={graphBarData} options={graphicOptions} />
            </GraphicWrapper>
            {graphBarData.length > 0 && (
                <SectorsContainer>
                    <SectorsHeader>
                        <div>Média Geral das propriedades</div>
                        <span>
                            Base {filterPer}: {totalPerYear} Setores respondidos
                        </span>
                    </SectorsHeader>

                    <ActionsContainer>
                        {sectorsAverage.map((sector) => (
                            <ActionCardWrapper key={sector.id}>
                                <ActionCard>
                                    <div className="sector">
                                        <span className="title">{sector.description}</span>
                                        <span className="type">{getEvaluationSectorTypeName(sector.type)}</span>
                                    </div>
                                    <span className="average">Média Geral: {Number(sector.average ?? 0).toFixed(0)}/100</span>
                                </ActionCard>
                                <ActionStatus className={sector.average < 50 ? 'bad' : sector.average < 60 ? 'average' : 'great'} />
                            </ActionCardWrapper>
                        ))}
                    </ActionsContainer>
                </SectorsContainer>
            )}
        </PaperContainer>
    );
};

CheckAgro.propTypes = {
    idEnterprise: PropTypes.number
};
