import React from 'react';

import { Grid, Skeleton } from '@mui/material';

export const Loading = () => {
    const minHeight = 300;
    const maxHeight = 350;

    const columns = new Array(8).fill(<Skeleton height={Math.floor(Math.random() * (maxHeight - minHeight + 1)) + minHeight} width={50} />);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Skeleton height={30} width={300} />
            </Grid>

            <Grid item xs={12} height={'20.5rem'}>
                <Grid container spacing={2} justifyContent={'center'}>
                    {columns.map((item, key) => (
                        <Grid key={`loading-col-${key}`} item xs={1}>
                            {item}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};
