import styled from 'styled-components';

export const Container = styled.div`
    padding: 70px;

    @media (max-width: 840px) {
        width: 100%;
        padding: 20px;
    }

    .header {
        display: flex;
        flex-direction: column;
        gap: 20px;

        > h2 {
            color: #3a6647;
            font-size: 1.75rem;
        }
    }

    .content {
        margin-top: 1.5rem;
    }
`;

export const InputGroup = styled.div`
    padding: 1.5rem;
    border: 1px solid #cfdcd3;
    border-radius: 0.5rem;
    /* grid-gap: 1.5rem; */
    position: relative;

    .content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        /* min-width: 400px; */

        &__item {
            @media (max-width: 580px) {
                max-width: 200px;
            }

            label {
                font-size: 18px;
            }
        }
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 1.5rem;
`;

export const SubmitContent = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-self: center;

    .MuiLoadingButton-root {
        > span {
            height: 80% !important;
            font-weight: 700;
        }
    }
`;

export const AlertContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .content-wrapper {
        /* border: 1px solid; */
        border-radius: 10px;
        background-color: #ffffff;
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: -2px 3px 6px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: -2px 3px 6px 0px rgba(0, 0, 0, 0.25);
        box-shadow: -2px 3px 6px 0px rgba(0, 0, 0, 0.25);
    }
`;

export const AlertHeader = styled.div`
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cdd2d9;

    h3 {
        font-size: 28px;
        color: #3a6647;
    }
`;

export const AlertContent = styled.div`
    padding: 1.5rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    max-width: 370px;
    gap: 1.5rem;
    justify-content: space-around;

    .title {
        font-size: 18px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
`;
