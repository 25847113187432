import styled from 'styled-components';

export const Container = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 16px;
    gap: 16px;

    width: 60%;
    height: 420px;

    background: #ffffff;

    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    > div.image {
        width: 224px;
        min-height: 120px;
        max-height: 120px;
        position: relative;

        background: #d9d9d9;
        border-radius: 4px;
        overflow: hidden;

        > img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    > div.title {
        width: 100%;
        display: flex;
        justify-content: space-between !important;
        align-items: center;

        > h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            color: #373848;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .icon {
            color: #a1a1a7;
            font-size: 14pt;
        }
    }

    > h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #373848;
    }

    > div.description {
        height: 64px;

        > p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 130%;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    > div.progress {
        width: 100%;
    }

    > button {
        display: flex;
        align-self: end;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 20px;
        gap: 8px;

        width: 224px;
        height: 32px;

        border: none;
        background: #6b9d4a;
        border-radius: 8px;

        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #ffffff;

        &:hover {
            background: #5d8643;
        }

        &:active {
            background: #415b2e;
        }
    }
`;

export const Card = styled.div`
    display: flex;
    margin-bottom: 5%;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 460px;
    border-radius: 8px;
    padding: 5%;
    gap: 1%;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.12);
`;
