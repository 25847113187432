import React from 'react';

import Events from '@/pages/Common/Events';
import ViewEvent from '@/pages/Common/Events/ViewEvent';
import Glossary from '@/pages/Common/Glossary';
import Watch from '@/pages/Common/Glossary/Watch';
import Notifications from '@/pages/Common/Notifications';
import DashboardLayout from '@components/templates/layouts/Outlet/DashboardLayout';

const Home = React.lazy(() => import('pages/Consultant/Home'));

const Error404 = React.lazy(() => import('pages/Errors/404'));
const Properties = React.lazy(() => import('pages/Common/Properties'));
const Schedule = React.lazy(() => import('@/pages/Common/Schedule'));
const NewProperty = React.lazy(() => import('pages/Common/Properties/NewProperty'));
const Property = React.lazy(() => import('@/pages/Common/Property'));
const DemonstrationRoutes = {
    path: '/demonstracao',
    element: <DashboardLayout />,
    children: [
        {
            path: '/demonstracao',
            element: <Home />
        },
        {
            path: `/demonstracao/propriedades/:propId/:tab?/:entityId?/:entityAction?`,
            element: <Property />
        },
        {
            path: '/demonstracao/propriedades',
            element: <Properties />
        },
        {
            path: '/demonstracao/agenda',
            element: <Schedule />
        },
        {
            path: '/demonstracao/propriedades/nova-propriedade',
            element: <NewProperty />
        },
        {
            path: `/demonstracao/propriedades/editar-propriedade/:propId`,
            element: <NewProperty />
        },
        {
            path: '/demonstracao/'
        },
        {
            path: '/demonstracao/glossario',
            element: <Glossary />
        },
        {
            path: '/demonstracao/glossario/assistir/:id',
            element: <Watch />
        },
        {
            path: '/demonstracao/notificacoes',
            element: <Notifications />
        },
        {
            path: '/demonstracao/encontros-ao-vivo',
            element: <Events />
        },
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/demonstracao/encontros-ao-vivo/:id',
            element: <ViewEvent />
        }
    ]
};
export default DemonstrationRoutes;
