import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: ${(props) => (props.large ? '8rem' : '5rem')};
    justify-content: ${(props) => (props.flexEnd ? 'flex-end' : 'flex-start')};

    > aside {
        margin-right: 0.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    > p {
        font-size: 0.9rem;
        color: #666;
    }
`;
