import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import useDashboard from '@/hooks/useDashboard';
import Dialog from '@components/molecules/alerts/Dialog';
import Snackbar from '@components/molecules/alerts/Snackbar';
import { AlertModal } from '@components/molecules/modals/AlertModal';
import SettingsModal from '@components/molecules/modals/SettingsModal';
import DashboardNavbar from '@components/organisms/layout/navbars/DashboardNavbar';
import DashboardSidebar from '@components/organisms/layout/navbars/DashboardSidebar';
import ConsultantViewDrawer from '@components/templates/layouts/Components/ConsultantViewDrawer';
import LoadingComponent from '@components/templates/LoadingComponent';

import { Container, Content, ContentMain } from './styles';
// import NavbarIcons from '@components/molecules/buttons/NavbarIcons';

const DashboardLayout = () => {
    const navigate = useNavigate();
    const dashboard = useDashboard();
    const auth = useSelector((state) => state.auth);
    const [checkingLogin, setCheckingLogin] = useState(true);

    const checkAuth = () => {
        if (auth?.loggedIn) {
            setCheckingLogin(false);
            return;
        }

        // localStorage.removeItem('currentUserRole');
        sessionStorage.removeItem('currentUserRole');
        navigate('/login');
        setCheckingLogin(false);
    };

    useEffect(() => {
        checkAuth();
    }, []);

    if (checkingLogin) {
        return (
            <Container>
                <LoadingComponent />
            </Container>
        );
    }
    return (
        <>
            <Container rightSidebar={dashboard.rightSidebar}>
                <DashboardSidebar />
                <Content>
                    <DashboardNavbar rightSidebar={dashboard.rightSidebar} />
                    <ContentMain>
                        <ConsultantViewDrawer />
                        <React.Suspense fallback={<LoadingComponent />}>
                            <Outlet />
                        </React.Suspense>
                        <Snackbar />
                        <AlertModal useHook={true} />
                    </ContentMain>
                    <Dialog useReducer={true} />
                </Content>
            </Container>
            <SettingsModal />
        </>
    );
};

DashboardLayout.propTypes = {
    hiddenBottomNavbar: PropTypes.bool
};

export default DashboardLayout;
