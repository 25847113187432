import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.containerWidth || '100vw'};
    background-color: ${(props) => props.background || 'transparent'};

    ${(props) =>
        props.marginTop
            ? css`
                  margin-top: ${props.marginTop};
              `
            : css`
                  margin-top: 0;
              `}

    ${(props) =>
        props.padding
            ? css`
                  padding: ${props.padding};
              `
            : css`
                  padding: 0;
              `}

    ${(props) =>
        props.sticky &&
        css`
            position: sticky;
            top: var(--navbar-height);
            z-index: 5;
        `}
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    max-width: ${(props) => props.contentMaxWidth}px;
    maxwidth: ${(props) => props.containerMaxWidth || '600px'};
    flex-direction: column;
`;
