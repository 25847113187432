import styled from 'styled-components';

import LoadingButton from '@mui/lab/LoadingButton';

export const ButtonStyled = styled(LoadingButton)`
    width: auto;
    height: ${(props) => (props.height ? props.height : 'auto')};
    min-width: ${(props) => props.width};
    padding: 0.5rem 1rem !important;
    font-weight: 500;
    /* font-size: 0.875rem; */
    font-size: ${(props) => props.fontSize};

    ${({ buttonColor }) => buttonColor && `background-color: ${buttonColor} !important;`};
    text-transform: none;

    &:hover {
        ${({ buttonColor }) => buttonColor && `background-color: ${buttonColor};`};
    }

    .MuiButton-startIcon {
        margin-right: ${(props) => props.marginRightIcon};
    }
`;
