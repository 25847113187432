import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

// import useAuthService from '@services/auth';

import { ROUTE_ROLES } from '@/constants/roles';

import sidebarData from './data';
import { Container, MenuExpandedIcon, MenuItem } from './styles';

const DashboardSidebar = () => {
    // const role_id = useSelector((state) => state.auth.user.role_id);
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading] = useState(false);
    const [sideBarContent, setSideBarContent] = useState([]);

    const { user } = useSelector((state) => state.auth);
    const consultantId = sessionStorage.getItem('consultant_id') ?? null;
    const [isSidebarExpanded, setSidebarExpanded] = useState(false);
    const viewAsConsultant = useSelector((state) => state.auth.viewAsConsultant);

    const handleClickMenu = (location) => {
        // if (location.includes('painel')) {
        //     const profile = {
        //         1: 'admin',
        //         2: 'consultor',
        //         3: 'empresa',
        //         4: 'coordenador',
        //         5: 'supervisor'
        //     };
        //     const redirect = profile[role_id];
        //     switch (role_id) {
        //         case 1:
        //         case 4:
        //         case 5:
        //             navigate(`/${redirect}/painel`);
        //             break;
        //     }
        // } else {
        // }
        navigate(location);
    };

    const fillSideBarContent = () => {
        let role = null;
        consultantId === null ? (role = ROUTE_ROLES[Number(sessionStorage.getItem('currentUserRole'))]) : (role = ROUTE_ROLES[2]);

        const existConsultantId = sessionStorage.getItem('consultant_id') ? [1, 4, 5] : [];

        let dataMenu = sidebarData(role, existConsultantId, user?.enterprise?.id || null);

        if (consultantId !== null) {
            if (Number(sessionStorage.getItem('currentUserRole')) === 1) {
                dataMenu.splice(3, 1);
            } else {
                dataMenu.splice(4, 1);
            }
        }

        setSideBarContent(dataMenu);
    };

    const handleLeftSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);
    };

    useEffect(() => {
        fillSideBarContent();
    }, []);

    useEffect(() => {
        fillSideBarContent();
    }, [viewAsConsultant]);

    return (
        <Container expanded={isSidebarExpanded} data-tour="2">
            {!isLoading &&
                sideBarContent.length > 0 &&
                sideBarContent.map(
                    (menuItem, index) =>
                        (menuItem.allowedRoles?.includes(currentUserRole) || typeof menuItem.allowedRoles === 'undefined') &&
                        !menuItem?.hideForEnterprise?.includes(user?.enterprise?.id) && (
                            <MenuItem
                                key={`menuItem-${index}`}
                                className={location.pathname === menuItem.path && 'active'}
                                menuExpanded={isSidebarExpanded}
                                onClick={() => handleClickMenu(menuItem.path)}
                            >
                                <>
                                    <span className={`icon ${menuItem.iconType}`} data-tour={menuItem.dataTour}>
                                        {React.isValidElement(menuItem.icon) ? (
                                            <>{menuItem.icon}</>
                                        ) : (
                                            <img src={menuItem.icon} alt={menuItem.label} />
                                        )}
                                    </span>
                                    <span className={'title'}>{menuItem.label}</span>
                                    <div className={`expanded-title ${!isSidebarExpanded && 'hidden-title'}`}>{menuItem.labelFull}</div>
                                </>
                            </MenuItem>
                        )
                )}
            <MenuExpandedIcon onClick={handleLeftSidebar}>
                {!isSidebarExpanded ? <ChevronRightRoundedIcon /> : <NavigateBeforeRoundedIcon />}
            </MenuExpandedIcon>
        </Container>
    );
};
export default DashboardSidebar;
