import React from 'react';

import Events from '@/pages/Common/Events';
import ViewEvent from '@/pages/Common/Events/ViewEvent';
import Glossary from '@/pages/Common/Glossary';
import Watch from '@/pages/Common/Glossary/Watch';
import Notifications from '@/pages/Common/Notifications';
import DashboardLayout from '@components/templates/layouts/Outlet/DashboardLayout';
// const Painel = React.lazy(() => import('pages/Admin/Painel'));
const EnterpriseDetails = React.lazy(() => import('pages/Common/EnterpriseDetails'));
const Error404 = React.lazy(() => import('pages/Errors/404'));

const CoordinatorRoutes = {
    path: '/coordenador',
    element: <DashboardLayout />,
    children: [
        // {
        //     path: '/coordenador/painel/:mainTab?',
        //     element: <Painel />
        // },
        {
            path: '/coordenador/empresa/:tabValue?/:id?/:tab?',
            element: <EnterpriseDetails />
        },
        {
            path: '/coordenador/glossario',
            element: <Glossary />
        },
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/coordenador/glossario/assistir/:id',
            element: <Watch />
        },
        {
            path: '/coordenador/encontros-ao-vivo',
            element: <Events />
        },
        {
            path: '/coordenador/encontros-ao-vivo/:id',
            element: <ViewEvent />
        },
        {
            path: '/coordenador/notificacoes',
            element: <Notifications />
        }
    ]
};
export default CoordinatorRoutes;
