import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import useAuthService from '@/services/auth';

import AssistentRehagroRoutes from './paths/assistent_rehagro';
import AuthenticationRoutes from './paths/auth';
import ConsultantRoutes from './paths/consultant';
import CoordinatorRoutes from './paths/coordinator';
import DemonstrationRoutes from './paths/demonstration';
import MainRoutes from './paths/main';
import RehagroRoutes from './paths/rehagro';
import SupervisorRoutes from './paths/supervisor';

const useThemeRoutes = () => {
    const { logged, setLogin, setCurrentUserRole } = useAuthService();
    const [isLoading, setIsLoading] = useState(true);
    const [routes, setRoutes] = useState([MainRoutes, AuthenticationRoutes]);
    const consultantId = sessionStorage.getItem('consultant_id') ?? null;
    const savedCurrentUserRole = sessionStorage.getItem('currentUserRole');
    const { loggedIn, currentUserRole } = useSelector((state) => state.auth);

    const checkAuth = async () => {
        setIsLoading(true);

        try {
            consultantId != null && dispatch({ type: 'SET_VIEW_AS_CONSULTANT', payload: true });

            const loggedData = await logged(currentUserRole);
            let currentRole = sessionStorage.getItem('currentUserRole');

            if (loggedData) {
                const hasCurrentRole = loggedData?.roles?.includes(Number(currentRole)) || false;

                if (!hasCurrentRole) {
                    setCurrentUserRole(loggedData.roles[0]);
                    currentRole = loggedData.roles[0];
                    sessionStorage.setItem('currentUserRole', Number(loggedData.roles[0]));
                }

                setLogin(loggedData);

                const routesArray = await handleRoutes(currentRole);

                setRoutes(routesArray);

                // handleRoutes(currentUserRole).then((routes) => {
                //     setRoutes(routes);
                // });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getRoutesByRole = (role) => {
        const commonRoutes = [MainRoutes, AuthenticationRoutes];

        return new Promise((resolve) => {
            switch (Number(role)) {
                case 2:
                    resolve([...commonRoutes, ConsultantRoutes]); // consultor
                    break;
                case 7:
                    resolve([...commonRoutes, DemonstrationRoutes]); // demonstracao
                    break;
                case 1:
                    resolve([...commonRoutes, RehagroRoutes, ConsultantRoutes]); // rehagro
                    break;
                case 6:
                    resolve([...commonRoutes, AssistentRehagroRoutes, ConsultantRoutes]); // assistente
                    break;
                case 4:
                    resolve([...commonRoutes, CoordinatorRoutes, ConsultantRoutes]); // coordenador
                    break;
                case 5:
                    resolve([...commonRoutes, SupervisorRoutes, ConsultantRoutes]); // supervisor
                    break;

                default:
                    resolve(commonRoutes);
                    break;
            }
        });
    };

    const handleRoutes = async (role) => {
        const routesArray = await getRoutesByRole(role);

        return routesArray;
    };

    useEffect(() => {
        setTimeout(() => {
            checkAuth();
        });
    }, []);

    useEffect(() => {
        handleRoutes(currentUserRole)
            .then((routesArray) => {
                setRoutes(routesArray);
            })
            .finally(() => {
                // setIsLoading(false);
            });
    }, [currentUserRole]);

    return {
        isLoading,
        routes: useRoutes(routes)
    };
};

export default useThemeRoutes;
