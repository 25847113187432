import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-br');
import { padNumber } from 'utils/general';

import { months } from '../../constants/date';

const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
};

const addHours = (date, hours) => {
    const dateHour = new Date(date);
    dateHour.setHours(dateHour.getHours() + hours);

    return dateHour;
};

const addMinutes = (date, minutes) => {
    const dateMinutes = new Date(date);
    dateMinutes.setMinutes(dateMinutes.getMinutes() + minutes);

    return dateMinutes;
};

const getNextMonths = (yearIndex) => {
    const nextMonths = [...months.slice(yearIndex), ...months.slice(0, yearIndex)];
    return nextMonths;
};

const dateJsToISO = (date) => {
    return moment(date).toISOString();
};

const getMinutesDiff = (startDate, endDate) => {
    const msInMinute = 1000 * 60;

    return Math.round(Math.abs(endDate - startDate) / msInMinute);
};

const convertTimezone = (date) => {
    const d = new Date(date);
    d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);

    return addHours(d, +3);
};

const getDateDDMMYYYY = (date) => {
    const d = new Date(date);
    const day = d.getUTCDate();
    const month = d.getUTCMonth() + 1;
    const year = d.getUTCFullYear();

    return `${day}/${padNumber(month)}/${padNumber(year)}`;
};

const getUTCDate = (dateString) => {
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    };

    const date = new Date(dateString);

    return (
        [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join('-') +
        ' ' +
        [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(':')
    );
};

const getMonthAndShortYear = (date) => {
    return moment(date).format('MMMM [de] YYYY');
};

const getDateHour = (date) => {
    return moment(date).format('HH:mm');
};

const getFormatBRL = (date) => moment(date).format('DD/MM/Y');

const getDinamicFormat = (date, format) => moment(date).format(format);

export {
    addDays,
    addHours,
    getNextMonths,
    dateJsToISO,
    addMinutes,
    getMinutesDiff,
    convertTimezone,
    getDateDDMMYYYY,
    getUTCDate,
    getMonthAndShortYear,
    getDateHour,
    getFormatBRL,
    getDinamicFormat
};
